(function () {
	'use strict';

	angular.module('app').controller('PrintPreview3Ctrl', PrintPreview3Ctrl);

	PrintPreview3Ctrl.$inject = ['preview', 'previewerService', 'ngClipboard', 'validationFactory'];

	function PrintPreview3Ctrl(preview, previewerService, ngClipboard, validationFactory) {
		var vm = this;
		vm.$onInit = activate;

		function activate() {
			vm.toClipboard = ngClipboard.toClipboard;
			vm.copyUrl = copyUrl;
			vm.setPreviewPassword = setPreviewPassword;
			vm.canSetPreviewPassword = false;
			vm.status = '';
			vm.preview = preview;
			vm.validatePreviewPassword = validatePreviewPassword;
			setPreviewUrl(preview.PreviewerId, preview.OverviewUrl);
		}

		function setPreviewUrl(guid, overviewUrl) {
			if (guid !== '' && guid !== '00000000-0000-0000-0000-000000000000') {
				preview.Url = window.location.origin + '/reports/' + guid;
			}
			if (overviewUrl != null && overviewUrl.indexOf('http') === -1) {
				vm.preview.OverviewUrl =
					(overviewUrl == '00000000-0000-0000-0000-000000000000' || overviewUrl == '')
						? ''
						: window.location.origin + '/reports/budget/' + overviewUrl;
			}
		}

		function copyUrl(budget) {
			/* Get the text field */
			vm.toClipboard(budget ? vm.preview.Url : vm.preview.OverviewUrl);
			vm.status = budget ? 'Copied Report URL Successfully!' : 'Copied Overview Report URL Successfully!';
		}

		function validatePreviewPassword(preview) {
			validationFactory.setGlyph($('#password'), typeof preview.Password === 'string' && preview.Password.length > 0);
		}

		function setPreviewPassword() {
			previewerService.setPreviewerPassword(vm.preview).then(function (rsp) {
				vm.status = 'Set Successfully!';
				setPreviewUrl(rsp.PreviewId, rsp.OverviewUrl);
			});
		}
	}
})();
