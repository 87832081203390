(function () {
	'use strict';

	angular
		.module('app')
		.controller('GoalsActionsPage4Ctrl', GoalsActionsPage4Ctrl);

	/* @ngInject */
	function GoalsActionsPage4Ctrl(
		$rootScope,
		$timeout,
		$scope,
		$stateParams,
		eTemplate,
		validationFactory,
		mainService,
		$uibModal,
		lcapYears,
		authService,
		user
	) {
		var vm = this;

		vm.$onInit = activate;
		vm.eTemplate = eTemplate;
		vm.openGoalsActionsModal = openGoalsActionsModal;
		vm.deleteGasGoal = deleteGasGoal;
		vm.validity = $rootScope.validity;
		vm.goal = { enabled: true, details: '' };
		vm.inReview = $stateParams.review;
		vm.user = user;
		vm.isAdminUser = user.userType == 'Admin';

		$scope.$watch(function () { // watch validity for goal values
			return $rootScope.validity;
		}, function () {
			vm.validity = $rootScope.validity;
		}, true);


		function activate() {
			$timeout(function () {
				vm.form = $scope.goalsActions; // Form Object
				vm.form.customValidator = validate; // Custom validator for this form
				vm.form.continueValidation = true; // return to continue default factory validation

				validationFactory.calculateBadges(vm.eTemplate); // Calc on activate
			});
			if ($rootScope.gasGoals) {
				vm.eTemplate.goalsActions.goals = $rootScope.gasGoals;
			}
		}

		function openGoalsActionsModal(form, goalToEdit, index) {
			$uibModal
				.open({
					controller: 'GoalsActions4Ctrl',
					controllerAs: 'vm',
					size: 'lg',
					templateUrl:
						'app/LCAP4/goalsActions/goalsActionsModal/goalsActionsModal.html',
					backdrop: 'static',
					keyboard: false,
					resolve: {
						editableGoal: function () {
							return goalToEdit;
						},
						goalIndex: function () {
							return index;
						},
						lcapYears: function () {
							return lcapYears;
						},
						eTemplate: function () {
							return eTemplate;
						},
						user: function () {
							return user;
						},
					},
				})
				.result
				.finally(function () {
					validationFactory.calculateBadges(vm.eTemplate); // Calc Badges
					authService.resetAutoLogout({ cancelPrevTimeout: true }); // Reset auto logout
					$rootScope.form = form; // Set rootscope form on dismiss
				});
		}

		function deleteGasGoal(index, _form, goal) {
			if (confirm(
				'Are you sure you want to delete Goal #' +
				(index + 1) +
				' from the Goals and Actions list of goals?'
			)) {
				mainService
					.deleteGASGoal(goal.goalId)
					.then(function () {
						vm.eTemplate.goalsActions.goals.splice(index, 1);
						validationFactory.calculateBadges(vm.eTemplate);
					});
			}
		}

		function validate() {
			var calcs = vm.validity.goals.goalsActions;

			if (calcs.goalsDraft >= 1 || calcs.goalsComplete === 0)
				{vm.form.$setValidity('required', false);} // Invalid
			else
				{vm.form.$setValidity('required', true);} // Valid
		}
	}
})();
