(function () {
	'use strict';

	angular
		.module('app')
		.controller('AnnualUpdateAttendancePage3Ctrl', AnnualUpdateAttendancePage3Ctrl);

	/* @ngInject */
	function AnnualUpdateAttendancePage3Ctrl(
		$rootScope,
		$scope,
		$stateParams,
		$timeout,
		eTemplate,
		mainService,
		validationFactory,
		setupDragAndDropForDetails,
		lcapYears
	) {
		var vm = this;

		vm.$onInit = activate;
		vm.eTemplate = eTemplate;
		vm.validity = $rootScope.validity;
		vm.inReview = $stateParams.review;
		vm.lcapYears = lcapYears;
		vm.showFeedback = true;
		vm.loading = false;
		vm.addAction = addAction;
		vm.deleteAction = deleteAction;

		$scope.$watch(
			function () {
				// watch validity for goal values
				return $rootScope.validity;
			},
			function () {
				vm.validity = $rootScope.validity;
			},
			true
		);


		function activate() {
			$timeout(function () {
				vm.form = $scope.annualUpdateAttendance;
				vm.form.customValidator = validate;
				vm.form.continueValidation = true; // return to continue default factory validation
				vm.form.reviewStatusId = vm.eTemplate.annualUpdateAttendance.reviewStatusId;
				checkFeedback();
				setUpActions();

				validationFactory.calculateBadges(vm.eTemplate); // Calc on activate
				vm.wasRolledOver =
					vm.eTemplate.lcapUser.schoolYearId === 3 &&
						vm.eTemplate.lcapUser.yearTwoStatus === 4
						? true
						: false;
			});
		}

		function setupDragAndDrop() {
			var template = vm.eTemplate.annualUpdateAttendance;
			setupDragAndDropForDetails('in-person-instructional-offerings', template.inPerson, $timeout);
			setupDragAndDropForDetails('distance-learning-program', template.distanceLearning, $timeout);
			setupDragAndDropForDetails('pupil-learning-loss', template.pupilLearningLoss, $timeout);
			setupDragAndDropForDetails('additional-actions', template.additionalActions, $timeout);
		}

		function setUpActions() {
			var template = vm.eTemplate.annualUpdateAttendance;
			if (Array.isArray(template.inPerson) === false || template.inPerson.length === 0) {
				template.inPerson = [{}];
			}
			if (Array.isArray(template.distanceLearning) === false || template.distanceLearning.length === 0) {
				template.distanceLearning = [{}];
			}
			if (Array.isArray(template.pupilLearningLoss) === false || template.pupilLearningLoss.length === 0) {
				template.pupilLearningLoss = [{}];
			}
			if (Array.isArray(template.additionalActions) === false || template.additionalActions.length === 0) {
				template.additionalActions = [{}];
			}
			$timeout(setupDragAndDrop);
		}

		function checkFeedback() {
			vm.showFeedback = (vm.inReview && vm.eTemplate.lcapUser.currentYearStatus.statusId === 3) || vm.eTemplate.annualUpdateAttendance.reviewStatusId != 0;
			$rootScope.$broadcast('showFeedback', vm.showFeedback);
		}

		function addAction(type) {
			vm.eTemplate.annualUpdateAttendance[type].push({});
			$timeout(setupDragAndDrop);
		}

		function deleteAction(type, index, actionId) {
			$rootScope.modalOpen = true;
			if (confirm('Are you sure you want to delete Action #' + (index + 1) + '?')) {
				if (actionId != null) {mainService.deleteAnnualUpdateAttendanceAction(actionId);}
				vm.eTemplate.annualUpdateAttendance[type].splice(index, 1);
				$rootScope.modalOpen = false;
			} else {
				$rootScope.modalOpen = false;
			}
		}

		function validate() {
			var template = vm.eTemplate.annualUpdateAttendance;
			var invalid = template.inPerson.length === 0 || template.distanceLearning.length === 0 ||
				template.additionalActions.length === 0 || template.pupilLearningLoss.length === 0;

			if (invalid)
				{vm.form.$setValidity('required', false);}
			// Invalid
			else {vm.form.$setValidity('required', true);} // Valid */
		}
	}
})();
