(function() {
  'use strict';

  angular.module('app').component('lcapSidebar', {
    templateUrl: 'app/LCAP/shared/lcapSidebar/lcapSidebar.html',
    controller: LCAPSidebarController,
    bindings: {
      sidebarToggle: '<',
      etemplate: '<',
      validity: '<',
      lcapYears: '<',
      user: '<',
    },
  });

  /* @ngInject */
  function LCAPSidebarController(
    $rootScope,
    $state,
    $timeout,
    $stateParams,
    $scope,
    lookup,
    validationFactory
  ) {
    var $ctrl = this;

    $ctrl.$onInit = activate;
    $ctrl.lookup = lookup;
    $ctrl.navigate = navigate;
    $ctrl.eTemplate = $ctrl.etemplate;
    $ctrl.isReview = $stateParams.review;
    $ctrl.currentYearStatus =
      $ctrl.eTemplate.lcapUser.currentYearStatus.statusId;
    $ctrl.schoolYearId = $stateParams.schoolYearId;

    $rootScope.$on('$stateChangeSuccess', function() {
      setStateName();

      $('#sidebar-affix').removeClass('affix');
      setSubmitLogic();
    });

    $scope.$on('showFeedback', function(event, args) {
      $ctrl.showCommentBtn = args;
    }); // Watch whether or not to show feedback button

    ///////////////////

    function activate() {
      setStateName();
      setPlanSummaryYears();
      $timeout(setSubmitLogic);
    }

    function setSubmitLogic() {
      var totalForms = $ctrl.schoolYearId <= 2 ? 6 : 7;

      $timeout(function() {
        $ctrl.showSubmit = {
          ifReview:
            $ctrl.validity.reviewApproved + $ctrl.validity.reviewUnapproved ===
              totalForms &&
            $ctrl.isReview === true &&
            $ctrl.user.userType === 'Reviewer' &&
            $ctrl.currentYearStatus === 3,
          ifDraft:
            $ctrl.validity.sidebarTotals.valid === totalForms &&
            $ctrl.isReview != true &&
            $ctrl.currentYearStatus === 2,
        };
      });
    }

    function setStateName() {
      $ctrl.stateName = $state.current.name.split('.').slice(1)[0];
      $ctrl.isGoalPage =
        $ctrl.stateName === 'annualUpdate' ||
        $ctrl.stateName === 'goalsActionsServices';
      $ctrl.stateName === 'goalsActions';
    }

    function setPlanSummaryYears() {
      var summaryIndex = _.findIndex($ctrl.lookup.LCAP_SIDEBAR, {
        formName: 'planSummary',
      });
      var planSummaryTitle = $ctrl.lookup.LCAP_SIDEBAR[summaryIndex].title;

      planSummaryTitle = planSummaryTitle.replace(
        '%years%',
        $ctrl.lcapYears[0].yearCycle
      );
      $ctrl.lookup.LCAP_SIDEBAR[summaryIndex].title = planSummaryTitle;
    }

    function navigate(route) {
      // Prevent navigation to the current active page plus special submit rules
      if ($ctrl.stateName != route && $ctrl.stateName != 'submit')
        {validationFactory.validateForm($rootScope.form, route);}
      else if ($ctrl.stateName === 'submit') {$state.go('LCAP.' + route);}
    }
  }
})();
