(function () {
	'use strict';

	angular
		.module('app')
		.controller('ReportBudget202223Ctrl', ReportBudget202223Ctrl);

	/* @ngInject */
	function ReportBudget202223Ctrl(printDetails, lcapYears, mainService, $timeout, $rootScope) {
		// previewerService, appSettings
		var vm = this;
		vm.activate = activate;
		vm.viewReport = viewReport;
		vm.lcapYears = lcapYears;
		vm.eTemplate = {};
		vm.password = '';
		vm.loading = true;
		activate();

		function activate() {
			vm.isAccessible = printDetails.IsAccessible;
			if (!printDetails.IsAccessible) {
				$timeout(viewReport());
			}
			vm.printDetails = printDetails;

		}
		function viewReport() {
			$rootScope.loading = true;
			printDetails.password = vm.password;

			mainService.getTemplateDetails(printDetails).then(function (rsp) {

				if (rsp.data !== false) {
					vm.isAccessible = false;
					vm.eTemplate = rsp;

					$timeout(function () {
						$('footer').hide();
						$('header').hide();
					});

				}
				else {
					alert('Invalid password');
				}
				$rootScope.loading = false;
				vm.loading = false;
			});
		}
	}
})();
