(function () {
	'use strict';

	angular
		.module('dashboard')
		.component('lcapUsers', {
			templateUrl: 'app/dashboard/lcapUsers/lcapUsers.template.html',
			controller: LCAPUserCtrl,
			bindings: {
				users: '=',
				user: '<',
			},
		});

	/* @ngInject */
	function LCAPUserCtrl($uibModal, dashboardService, lookup) {
		var $ctrl = this;

		$ctrl.$onInit = activate;

		/////////////////////////////

		function activate() {
			$ctrl.addApplicant = addApplicant;
			$ctrl.getUsers = getUsers;
			$ctrl.resetPassword = resetPassword;
			$ctrl.deleteUser = deleteUser;
			$ctrl.filterSearch = filterSearch;
			$ctrl.currentPage = 1;
			$ctrl.limit = 50;
			$ctrl.usersCopy = angular.copy($ctrl.users);
			$ctrl.users = $ctrl.usersCopy.slice(0, $ctrl.limit);
			$ctrl.total = $ctrl.usersCopy.length;
			$ctrl.pageChanged = pageChanged;
			$ctrl.editUser = editUser;
			$ctrl.sort = sort;
			$ctrl.lookup = lookup;

			adjustUserData();
		}

		function pageChanged() {
			var start = ($ctrl.currentPage - 1) * $ctrl.limit;
			var end = (start + $ctrl.limit > $ctrl.total) ? $ctrl.total : start + $ctrl.limit;
			$ctrl.users = $ctrl.usersCopy.slice(start, end);
		}

		function addApplicant() {
			$ctrl.modalInstance = $uibModal.open({
				backdrop: 'static',
				keyboard: false,
				animation: true,
				templateUrl: 'app/dashboard/lcapUsers/applicantForm/applicantForm.template.html',
				controller: 'applicantFormController',
				controllerAs: 'vm',
				size: 'lg',
			}).result.then(function () {
				getUsers();
			});
		}

		function editUser(selectedUser) {
			$ctrl.modalInstance = $uibModal.open({
				animation: true,
				templateUrl: 'app/dashboard/lcapUsers/userModal/userModal.template.html',
				controller: 'UserModalController',
				controllerAs: 'vm',
				size: 'lg',
				resolve: {
					user: function () {
						return selectedUser;
					},
				},
			}).result.then(function () {
				getUsers();
			});
		}

		function getUsers() {
			if ($ctrl.user.userType != 'Admin') {
				dashboardService.getLCAPUsers($ctrl.user.cdsCode)
					.then(function (rsp) {
						$ctrl.usersCopy = angular.copy(rsp);
						$ctrl.users = $ctrl.usersCopy.slice(0, $ctrl.limit);
						pageChanged();
						adjustUserData();
					});
			} else {
				dashboardService.getAdminUsers()
					.then(function (rsp) {
						$ctrl.usersCopy = angular.copy(rsp);
						$ctrl.users = $ctrl.usersCopy.slice(0, $ctrl.limit);
						pageChanged();
						adjustUserData();
					});
			}
		}

		function resetPassword(user) {
			if (confirm('Are you sure you want to reset the password for ' + user.fName + ' ' + user.lName + '?')) {
				dashboardService.resetApplicantPassword(user)
					.then(function () {
						// $uibModalInstance.close();
					}).catch(function (e) {
						toastr.error(e.data);
					});
			}
		}
		function deleteUser(user) {
			if($ctrl.user.userType == 'Admin'){
				if (confirm('Are you sure you want to delete ' + user.fName + ' ' + user.lName + ' from accessing this LCAP?')) {
					dashboardService.deleteApplicant(user.userId, user.cdsCode, $ctrl.user.userId, $ctrl.user.userType)
						.then(function () {
							getUsers();
						});
				}
			}
		}

		function filterSearch() {
			var search = $ctrl.filter.toLowerCase();
			$ctrl.users = $ctrl.usersCopy.filter(function (obj) {
				if (!obj.adjustUserData) {obj.adjustUserData = obj.fName + ' ' + obj.lName;} // create full name string

				return search ? ~obj.adjustUserData.toLowerCase().indexOf(search) || ~obj.email.toLowerCase().indexOf(search) || ~obj.school.toLowerCase().indexOf(search) || ~obj.phone.toLowerCase().indexOf(search) : true;
			});

			if ($ctrl.users.length > $ctrl.limit) {pageChanged();}

		}

		function sort(prop) {
			if ($ctrl.sortedBy != prop && prop != null) {
				$ctrl.usersCopy = _.sortBy($ctrl.usersCopy, prop);
				$ctrl.users = $ctrl.usersCopy.slice(0, $ctrl.limit);
				$ctrl.sortedBy = prop;
				$ctrl.reverseSort = false;
				pageChanged();
			} else if ($ctrl.sortedBy === prop && prop != null) {
				$ctrl.usersCopy.reverse();
				$ctrl.users = $ctrl.usersCopy.slice(0, $ctrl.limit);
				$ctrl.reverseSort = !$ctrl.reverseSort;
				pageChanged();
			}

			if ($ctrl.filter) {filterSearch();}
		}

		function adjustUserData() {
			$ctrl.usersCopy.forEach(function (item) {
				item.fullName = item.fName.charAt(0).toUpperCase() + item.fName.slice(1) + ' ' + item.lName.charAt(0).toUpperCase() + item.lName.slice(1);
				item.email = item.email.toLowerCase();
				item.school = item.school.charAt(0).toUpperCase() + item.school.slice(1);
			});
		}
	}
})();
