(function () {
	'use strict';

	angular
		.module('helpers')
		.factory('dataservice', dataservice);

	/* @ngInject */
	function dataservice($q, $http, appSettings) {
		var serviceBaseUrl = appSettings.serverPath + 'api/';

		var service = {
			postData: postData,
			getData: getData,
			postPDFData: postPDFData,
		};
		return service;

		function getData(getUrl) {
			return $http.get(serviceBaseUrl + getUrl + '?buster=' + Date.now())
				.then(onSuccess)
				.catch(onError);
		}

		function postPDFData(postUrl, postData) {
			return $http.post(serviceBaseUrl + postUrl, postData, { responseType: 'arraybuffer', headers: { 'accept': 'application/pdf' } })
				.then(onPDFSuccess)
				.catch(onError);
		}

		function postData(postUrl, postData) {
			return $http.post(serviceBaseUrl + postUrl, postData)
				.then(onSuccess)
				.catch(onError);
		}

		function onPDFSuccess(rsp) {
			return new Blob([rsp.data], { type: 'application/pdf' });
		}

		function onSuccess(rsp) {
			if (rsp.data.data) {
				return rsp.data.data;

			} else {
				return rsp.data;
			}
		}

		function onError(rsp) {
			return $q.reject(rsp);
		}
	}
}());
