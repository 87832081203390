(function () {
	'use strict';

	angular.module('app').controller('PrintPreview5Ctrl', PrintPreview5Ctrl);

	PrintPreview5Ctrl.$inject = ['preview', 'previewerService', 'ngClipboard', 'validationFactory', 'lcapYears', 'eTemplate', '$stateParams'];

	function PrintPreview5Ctrl(preview, previewerService, ngClipboard, validationFactory, lcapYears, eTemplate, $stateParams) {
		var vm = this;
		vm.$onInit = activate;
		vm.copyUrl = copyUrl;
		vm.preview = preview;
		vm.setPreviewPassword = setPreviewPassword;
		vm.lcapYears = lcapYears;
		vm.year = '2022-23';
		vm.eTemplate = eTemplate;

		function activate() {
			vm.year = getYear().year;
			vm.preview.formId = $stateParams.formId;
			vm.preview.schoolYearId = $stateParams.schoolYearId;
			vm.toClipboard = ngClipboard.toClipboard;
			vm.canSetPreviewPassword = false;
			vm.status = '';
			vm.validatePreviewPassword = validatePreviewPassword;
			setPreviewUrl(preview.PreviewerId, preview.OverviewUrl);
		}

		function getYear() {
			return _.find(vm.lcapYears, function(lcapYear) {
				return lcapYear.schoolYearId == vm.eTemplate.lcapUser.schoolYearId;
			});
		}

		function setPreviewUrl(guid, overviewUrl) {
			if (guid !== '' && guid !== '00000000-0000-0000-0000-000000000000') {
				preview.Url = window.location.origin + '/reports/' + vm.year + '/' + guid;
			}
			if (guid !== '' && guid !== '00000000-0000-0000-0000-000000000000') {
				vm.preview.SupplementUrl = window.location.origin + '/reports/supplement/' + guid;
			}
			if (overviewUrl != null && overviewUrl.indexOf('http') === -1) {
				vm.preview.OverviewUrl =
					(overviewUrl == '00000000-0000-0000-0000-000000000000' || overviewUrl == '')
						? ''
						: window.location.origin + '/reports/budget/' + vm.year + '/' + overviewUrl;
			}
		}

		function copyUrl(type) {
			/* Get the text field */
			switch (type) {
				case 'report':
					vm.toClipboard(vm.preview.Url);
					vm.status = 'Copied Report URL Successfully!';
					break;
			
				case 'supplement':
					vm.toClipboard(vm.preview.SupplementUrl);
					vm.status = 'Copied Supplement Report URL Successfully!';
					break;

				case 'overview':
					vm.toClipboard(vm.preview.OverviewUrl);
					vm.status = 'Copied Overview Report URL Successfully!';
					break;
			}
		}

		function validatePreviewPassword(preview) {
			validationFactory.setGlyph($('#password'), typeof preview.Password === 'string' && preview.Password.length > 0);
		}

		function setPreviewPassword() {
			previewerService.setPreviewerPassword(vm.preview).then(function (rsp) {
				vm.status = 'Set Successfully!';
				setPreviewUrl(rsp.PreviewId, rsp.OverviewUrl);
			});
		}
	}
})();
