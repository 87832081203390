(function () {
	'use strict';

	angular
		.module('app.lcap')
		.component('printLcapFormInfo', {
			templateUrl: 'app/LCAP/lcapFormInfo/print/printLcapFormInfo.template.html',
			controller: PrintLcapFormInfo,
			bindings: {
				eTemplate: '<',
			},
		});

	/* @ngInject */
	function PrintLcapFormInfo() {
		var $ctrl = this;
		$ctrl.lcap = $ctrl.eTemplate.lcapUser;
	}
})();
