(function () {
	'use strict';

	angular
		.module('app')
		.controller('PrintCtrl', PrintCtrl);

	/* @ngInject */
	function PrintCtrl(lookup, eTemplate, lcapYears, $stateParams) {
		var vm = this;

		vm.$onInit = activate;
		vm.lookup = lookup;
		vm.eTemplate = eTemplate;
		vm.lcapYears = lcapYears;

		////////////////////////

		function activate() {
			vm.section = $stateParams.section;
			vm.landscape = vm.section === 'all' || vm.section === 'landscape';
			vm.portrait = vm.section === 'all' || vm.section === 'portrait';
		}
	}
})();
