(function () {
	'use strict';

	angular.module('services', [])
		.constant('appSettings', {
			// set server path, option to forceProd for testing
			// should be false when deploying
			serverPath: getApiUrl({ forceProd: false }),
		});

	function getApiUrl(options) {
		var url = window.location.href;
		var local = 'http://localhost:3000';
		var dev = 'https://etemplate-dev-www.azurewebsites.net';
		var prod = 'https://lcaptemplate.org';

		if (options.forceProd || url.indexOf(prod) != -1)
			{return 'https://api.lcaptemplate.org/';}
		if (url.indexOf(dev) != -1)
			{return 'https://etemplate-dev-api.azurewebsites.net/';}
		if (url.indexOf(local) != -1)
			{return 'https://localhost:44342/';}
		throw new Error('Cannot retrieve API url for unknown client url: ' + url);
	}
})();
