(function () {
	'use strict';

	angular
		.module('app.lcap')
		.component('printPlanSummaryLatest', {
			templateUrl: 'app/LCAP2/planSummary/print/printPlanSummary.template.html',
			controller: PrintPlanSummaryCtrl,
			bindings: {
				eTemplate: '<',
				years: '<',
			},
		});

	/* @ngInject */
	function PrintPlanSummaryCtrl() {
		var $ctrl = this;
		$ctrl.plan = $ctrl.eTemplate.planSummary;

	}
})();
