(function() {
  'use strict';

  angular.module('app').controller('AnnualUpdateSupplementPage5Ctrl', AnnualUpdateSupplementPage5Ctrl);

  /* @ngInject */
  function AnnualUpdateSupplementPage5Ctrl(
    $rootScope,
		$scope,
    $timeout,
    eTemplate,
    $stateParams,
    user,
		validationFactory
  ) {
    var vm = this;

    vm.$onInit = activate;
    vm.eTemplate = eTemplate;
    vm.inReview = $stateParams.review;
    vm.user = user;

    ////////////////////////////////

		$scope.$watch(
			function () {
				// watch validity for goal values
				return $rootScope.validity;
			},
			function () {
				vm.validity = $rootScope.validity;
			},
			true
		);

    function activate() {
			$timeout(function () {
				vm.form = $scope.annualUpdateSupplement;
				vm.form.customValidator = validate;
				vm.form.continueValidation = true; // return to continue default factory validation
				vm.form.reviewStatusId = vm.eTemplate.annualUpdateSupplement.reviewStatusId;

				validationFactory.calculateBadges(vm.eTemplate); // Calc on activate
			});
    }

		function validate() {
			var template = vm.eTemplate.annualUpdateSupplement;
			var invalid = !template;

			if (invalid)
				{vm.form.$setValidity('required', false);}
			// Invalid
			else {vm.form.$setValidity('required', true);} // Valid */
		}
  }
})();
