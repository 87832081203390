(function () {
	'use strict';

	angular
		.module('app.validation')
		.factory('validationFactory', validationFactory);

	/* @ngInject */
	function validationFactory(
		$timeout,
		$rootScope,
		$ngBootbox,
		$stateParams,
		lookup,
		latestLookup,
		lcapLatestLookup
	) {
		var validation = {
			validateElement: validateElement,
			validateAllFields: validateAllFields,
			charCount: charCount,
			validateForm: validateForm,
			calculateBadges: calculateBadges,
			setGlyph: setGlyph,
			validatePriorities: validatePriorities,
			disableForm: disableForm,
		};

		var vm = this;
		vm.lookup = angular.copy(window.location.href.indexOf('LCAP2/') > -1 ? latestLookup : window.location.href.indexOf('LCAP/') > -1 ? lookup : lcapLatestLookup);

		_.forEach(vm.lookup.LCAP_SIDEBAR, function(item) {
			if(item && item.skipSideBar){
				vm.lookup.LCAP_SIDEBAR.splice(_.indexOf(vm.lookup.LCAP_SIDEBAR, item), 1);
			}
		});

		var formElements =
			'input:not([type=checkbox]):not([type=radio]), textarea, select';

		return validation;

		/////////////////////////

		function validateForm(form, route) {
			form.$name = form.$name
			.replace('goalsActionsServices', 'goalsActions')
			.replace('goalsActionsGoal', 'goalsActions')
			.replace('unduplicatedPupils', 'increasedServices');
			
			form.$setSubmitted();
			if ($stateParams.review != true)
				{validateAllFields(form, '#' + form.$name);}

			if (!route) {
				var currentItem = _.findWhere(vm.lookup.LCAP_SIDEBAR, {
					formName: form.$name,
				});
				
				var currentIndex = vm.lookup.LCAP_SIDEBAR.indexOf(currentItem);

				// Set the next route
				route = vm.lookup.LCAP_SIDEBAR[currentIndex + 1].formName;

				route = route.replace('expenditures', 'submit');
			}

			var details = {
				form: form,
				route: route,
			};

			if (form.customValidator != null) {form.customValidator(form);} // using custom validator in form controller

			if (
				(form.customValidator === undefined ||
					form.continueValidation === true) &&
				$stateParams.review != true
			) {
				if (form.$valid) {
					if (!form.change) {
						if (form.reviewStatusId === 2) {
							$ngBootbox.customDialog(getReviewEditModal(form, details));
						} else {wrapUp(3, details);}
					} else if (form.change) {
						if (form.reviewStatusId === 2) {
							$ngBootbox.customDialog(getReviewEditModal(form, details));
						} else if (form.reviewStatusId === 3) {
							if (confirm(vm.lookup.ERROR_MESSAGES.APPROVED_REVIEW_CHANGE)) {
								form.reviewStatusId = 1; // Reset for re-review
								wrapUp(3, details);
							}
						} else if (confirm(vm.lookup.ERROR_MESSAGES.CHANGE)) {
							wrapUp(3, details);
						}
					}
				} else if (form.$invalid) {
					if (confirm(vm.lookup.ERROR_MESSAGES.INCOMPLETE)) {
						wrapUp(2, details);
					}
				}
			} else if ($stateParams.review === true) {
				details.form.skipStatus = true;
				wrapUp(null, details);
			}
		}

		function getReviewEditModal(form, details) {
			var modal = {
				message: vm.lookup.ERROR_MESSAGES.REQUESTED_REVIEW_CHANGES,
				buttons: {
					danger: {
						label: 'Still Working',
						className: 'btn-warning',
						callback: function () {
							form.reviewStatusId = 2;
							wrapUp(3, details);
						},
					},
					success: {
						label: 'Ready for Review',
						className: 'btn-success',
						callback: function () {
							form.reviewStatusId = 1;
							wrapUp(3, details);
						},
					},
				},
			};

			return modal;
		}

		function wrapUp(statusId, details) {
			if (details.form.skipStatus != true) {details.form.statusId = statusId;} // set statusId if skip is not true
			$rootScope.$broadcast('formSave', details); // Broadcast form save to parent controller for api save
		}

		function validateElement(form, e, item) {
			// validates elements individually on keyup or change
			var input;
			var $el;

			if (e) {
				// event only occurs on change or keyup, not onload
				input = form[e.target.name];
				$el = $(e.target);
			} else {
				// used onload to validate elements, event is not yet available
				input = form[item.name];
				$el = $(item);
			}

			// This means there is an ng-form within a regular <form> use return to stop validation
			// Validation for ng-form will occur as separate form using form-validation directive
			if (input === undefined && $el.parents('ng-form').length >= 1) {return;}

			if ($el.attr('disabled') && $el.data('yearly-disabled')) {
				// if fields are disabled and they're the yearly disabled fields
				input.$setValidity('required', true);
				if (!input.$viewValue) {
					// if input is blank, populate and render
					input.$setViewValue('N/A');
					input.$render();
				}
			}

			if (
				$el.attr('type') != 'checkbox' &&
				$el.attr('type') != 'radio' &&
				$el.data('skip') != true &&
				$el.is('button') != true &&
				$el.is('a') != true
			) {
				var required = $el.prop('required');

				if (input.$valid && input.$viewValue) {setGlyph($el, true);}
				else if (
					(input.$invalid && input.$dirty && required) ||
					(form.$submitted && input.$invalid && required)
				)
					{setGlyph($el, false);}
				else {setGlyph($el);}
			}
		}

		function validateAllFields(form, element, timeout) {
			// Validates all fields on specified form
			$timeout(function () {
				// set timeout to avoid incorrect results
				_.each($(element).find(formElements), function (item) {
					validateElement(form, null, item);
				});
			}, timeout);
		}

		function setGlyph($el, validity) {
			// responsible for green or red field validation
			var $elParent = $el.parent();
			if ($elParent.hasClass('input-group')) {
				$elParent = $elParent.parent();
			}
			var hasGlyph = $elParent.find('.glyphicon').length >= 1;
			var glyphMarkup =
				'<span class="glyphicon form-control-feedback" aria-hidden="true"></span>';

			// Might be a cleaner way to do this, used when setting glyphs below
			if (validity != null) {
				var glyphStatus =
					validity === true ? 'glyphicon-ok' : 'glyphicon-remove';
				var glyphOpposite =
					validity === false ? 'glyphicon-ok' : 'glyphicon-remove';
				var parentStatus = validity === true ? 'has-success' : 'has-error';
				var parentOpposite = validity === false ? 'has-success' : 'has-error';

				// Set the parent status for border colors
				$elParent.addClass(parentStatus).removeClass(parentOpposite);

				if (!$el.is('select')) {
					// Add or remove proper glyphicon accordingly
					if (hasGlyph)
						{$elParent
							.find($('.glyphicon'))
							.removeClass(glyphOpposite)
							.addClass(glyphStatus);}
					else {$elParent.append($(glyphMarkup).addClass(glyphStatus));}
				}

				// Add special class for textarea glyphicons if applicable
				if ($el.is('textarea'))
					{$elParent.find($('.glyphicon')).addClass('textarea-glyph');}
			} else {
				$elParent.removeClass('has-error'); // remove separately to avoid jquery conflict if item doesnt have class
				$elParent.removeClass('has-success'); // remove separately to avoid jquery conflict if item doesnt have class
				$elParent.find('.glyphicon').remove(); // remove glyphicon entirely
			}
		}

		function charCount(e) {
			var $el = $(e.target); // input target
			var $elParent = $el.parent(); // input parent container
			var inFocus = $el.is(':focus'); // input in focus either true or false
			var maxLength = $el.attr('maxlength'); // maxlength set on input
			var trackCount = $el.data('charCount'); // used to turn off charCount if set to true on input
			var $charCount = $elParent.find('.charCount'); // charCount specific to this input
			var count = $el.val().length; // Actual character count number
			var countMarkup =
				'<span class="charCount">Characters: <span class="count"></span> of ' +
				maxLength +
				'</span>';

			if (maxLength && trackCount != false && inFocus) {
				if ($charCount.length != 0)
					{$charCount
						.removeClass('hide')
						.find('.count')
						.text(count);}
				else
					{$elParent
						.append(countMarkup)
						.find('.count')
						.text(count);}
			} else {$charCount.addClass('hide');}
		}

		function validatePriorities() {
			return true;
		}
		// function validatePriorities(object, form) {
		// 	var items = [];

		// 	var $local =
		// 		form.hasOwnProperty("local") && form.local.hasOwnProperty("$name")
		// 			? $("#" + form.local.$name)
		// 			: null;



		// 	if ($local) {
		// 		for (var item in object) {
		// 			var validPriority =
		// 				item.indexOf("Priority") != -1 && object[item] === true;
		// 			var validLocal =
		// 				item === "local" &&
		// 				object[item] != null &&
		// 				object[item].length;
		// 			var approved = validLocal || validPriority;
		// 			if (approved) items.push(item);
		// 		}

		// 		var isLocal = items.indexOf("local") != -1;
		// 		if (items.length) {
		// 			// Set proper validation styles
		// 			if (isLocal) setGlyph($local, true);
		// 			else setGlyph($local);

		// 			return true;
		// 		} else if (items.length === 0) {
		// 			if (!isLocal && form.$submitted === true) setGlyph($local, false);
		// 			else setGlyph($local);

		// 			return false;
		// 		}
		// 	}
		// 	return true;
		// }

		function disableForm() {
			$timeout(function () {
				// only allow feedback textbox
				$('textarea:not(#feedback), input, select').attr('disabled', true);
			}, 100);
		}

		//Might be able to refactor this entire function. Cleaned/commented for now.




		function calculateBadges(eTemplate) {
			var keyNames = Object.keys(eTemplate);
			var validity = {
				// $rootScope object for badges
				validForms: 0,
				invalidForms: 0,
				goals: {
					annualUpdate: {
						goalsDraft: 0,
						goalsComplete: 0,
						reviewApproved: 0,
						reviewUnapproved: 0,
					},
					goalsActions: {
						goalsDraft: 0,
						goalsComplete: 0,
						reviewApproved: 0,
						reviewUnapproved: 0,
					},
				},
				reviewApproved: 0,
				reviewUnapproved: 0,
				sidebarTotals: { valid: 0, invalid: 0, warning: 0 },
				gasTotals: { valid: 0, invalid: 0, warning: 0 },
				auTotals: { valid: 0, invalid: 0, warning: 0 },
			};

			keyNames.forEach(function (item) {

				var section = eTemplate[item];
				section.keyName = item;
				var isAnnualUpdate = item === 'annualUpdate'; //Annual Update goals are handle slightly different
				if (isAnnualUpdate){
					section.goals = eTemplate['annualUpdateGoals'];
				}
				if (section.goals != null) {
					_.each(section.goals, function (item) {
						if (item.details.status === false || (isAnnualUpdate && (section.estimatedActualLCFFBaseGrant == undefined || section.estimatedActualLCFFSupplementalConcentrationGrants == undefined )))
							{validity.goals[section.keyName].goalsDraft += 1;}
						else if (item.details.status === true)
							{validity.goals[section.keyName].goalsComplete += 1;}

						if (item.details.reviewStatusId === 3)
							{validity.goals[section.keyName].reviewApproved += 1;}
						else if (item.details.reviewStatusId === 2)
							{validity.goals[section.keyName].reviewUnapproved += 1;}
					});

					var goals = validity.goals[section.keyName];

					if (goals) {
						if (goals.goalsDraft === 0 && goals.goalsComplete === 0) {
							//section.statusId = 1; // mark form as invalid but not touched
						} else if (goals.goalsDraft > 0) {
							validity.invalidForms += 1; // form is invalid, add to badges
							section.statusId = 2; // mark form as invalid
						} else if (goals.goalsDraft === 0 && goals.goalsComplete >= 1) {
							validity.validForms += 1; // form is valid, add to badges
							section.statusId = 3; // mark form as valid
						}

						if (section.goals.length > 0 && goals.reviewApproved === section.goals.length) {
							section.reviewStatusId = 3;
							validity.reviewApproved += 1;
						} else if (goals.reviewUnapproved >= 1) {
							section.reviewStatusId = 2;
							validity.reviewUnapproved += 1;
						} else if (
							goals.reviewApproved !== 0 ||
							goals.reviewUnapproved !== 0
						) {
							section.reviewStatusId = 1;
						} else {
							section.reviewStatusId = 0;
						}
					}
				} else {

					if (section.statusId === 3) {validity.validForms += 1;}
					// statusId of 3 is a valid form
					else if (section.statusId === 2) {validity.invalidForms += 1;} // statusId of 2 is invalid form

					if (section.reviewStatusId === 3) {validity.reviewApproved += 1;}
					else if (section.reviewStatusId === 2)
						{validity.reviewUnapproved += 1;}
				}
			});

			$timeout(getSidebarTotals);
			$timeout(getGasTotals);
			$timeout(getAuTotals);

			function getSidebarTotals() {
				$('#sidebar')
					.find('li.form-sections')
					.each(function (i, item) {
						var $item = $(item);
						var validItem = $item.find('.glyphicon-ok').length;
						var invalidItem = $item.find('.glyphicon-remove').length;
						var warningItem = $item.find('.glyphicon-warning').length;

						if (validItem) {validity.sidebarTotals.valid += 1;}
						else if (invalidItem) {validity.sidebarTotals.invalid += 1;}
						else if (warningItem) {validity.sidebarTotals.warning += 1;}
					});
			}

			function getGasTotals() {
				$('#gas-goals-list')
					.find('li')
					.each(function (i, item) {
						var $item = $(item);
						var validItem = $item.find('.glyphicon-ok, .label-success').length;
						var invalidItem = $item.find('.glyphicon-remove, .label-danger')
							.length;
						var warningItem = $item.find('.glyphicon-warning, .label-warning')
							.length;

						if (validItem) {validity.gasTotals.valid += 1;}
						else if (invalidItem) {validity.gasTotals.invalid += 1;}
						else if (warningItem) {validity.gasTotals.warning += 1;}
					});
			}

			function getAuTotals() {
				$('#au-goals-list')
					.find('li')
					.each(function (i, item) {
						var $item = $(item);
						var validItem = $item.find('.glyphicon-ok, .label-success').length;
						var invalidItem = $item.find('.glyphicon-remove, .label-danger')
							.length;
						var warningItem = $item.find('.glyphicon-warning, .label-warning')
							.length;

						if (validItem) {validity.auTotals.valid += 1;}
						else if (invalidItem) {validity.auTotals.invalid += 1;}
						else if (warningItem) {validity.auTotals.warning += 1;}
					});
			}

			$rootScope.validity = validity; // Set validity for sidebar to have valid/invalid values
		}
	}
})();

