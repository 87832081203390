(function () {
	'use strict';

	angular.module('app.lcap').component('scrollComment', {
		templateUrl: 'app/LCAP/shared/scrollComment/scrollComment.template.html',
		controller: scrollComment,
		bindings: {
			goal: '<',
		},
	});

	/* @ngInject */
	function scrollComment($rootScope, $scope, $state) {
		var $ctrl = this;

		$ctrl.$onInit = activate;
		$ctrl.scrollToComment = scrollToComment;
		$ctrl.scrollBack = scrollBack;
		$ctrl.showScrollBack = false;

		$scope.$on('saveComment', scrollBack);

		$rootScope.$on('$stateChangeSuccess', function () {
			$ctrl.showScrollBack = false;
			setStateName();
		});

		/////////////////////////////

		function activate() {
			setStateName();

			var $modal = $('.modal');
			if ($modal.length) {
				$modal.on('scroll', function () {
					var scrollTop = $(this).scrollTop();
					if (scrollTop >= 89) {$('.goal-btn').css('top', scrollTop - 31);}
					else {$('.goal-btn').css('top', 60);}
				});
			}
		}

		function setStateName() {
			$ctrl.stateName = $state.current.name.split('.').slice(1)[0];
			$ctrl.isGoalPage =
				$ctrl.stateName === 'annualUpdate' ||
				$ctrl.stateName === 'goalsActionsServices' ||
				$ctrl.stateName === 'goalsActions';
		}

		function scrollToComment() {
			var section =
				$ctrl.goal === true
					? $('.modal-lg').outerHeight()
					: $('#feedback-section');
			$ctrl.currentPos =
				$ctrl.goal != true ? $(window).scrollTop() : $('.modal').scrollTop();
			$ctrl.showScrollBack = true;
			scrollTo(section);
		}

		function scrollBack() {
			if ($ctrl.showScrollBack) {
				$ctrl.showScrollBack = false;

				scrollTo($ctrl.currentPos);
			}
		}

		function scrollTo(el, offset) {
			var pos;
			if (typeof el != 'number')
				{pos = el && el.size() > 0 ? el.offset().top : 0;}
			else {pos = el;}

			$('html, body, .modal').animate(
				{
					scrollTop: pos + (offset || 0),
				},
				'slow'
			);
		}
	}
})();
