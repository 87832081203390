(function () {
	'use strict';

	angular
		.module('app.lcap')
		.component('printGoalsActionsServices', {
			templateUrl: 'app/LCAP/goalsActionsServices/print/printGoalsActionsServices.template.html',
			controller: PrintGoalsActionsServices,
			bindings: {
				eTemplate: '<',
				years: '<',
			},
		});

	/* @ngInject */
	function PrintGoalsActionsServices() {
		var $ctrl = this;

		$ctrl.gas = $ctrl.eTemplate.goalsActionsServices.goals;
		$ctrl.generateActionAmount = generateActionAmount;
		$ctrl.generateActionSource = generateActionSource;
		$ctrl.generateActionBudget = generateActionBudget;

		/////////////////////

		function generateActionAmount(action, year) {
			if (year === 1) {
				return action.yearOneAmount || 'N/A';
			} else if (year === 2) {
				return action.yearTwoStatus != 'Unchanged' ? action.yearTwoAmount : action.yearOneAmount || 'N/A';
			} else if (year === 3) {
				return action.yearThreeStatus != 'Unchanged' ? action.yearThreeAmount : action.yearTwoStatus != 'Unchanged' ? action.yearTwoAmount : action.yearOneAmount;
			}
		}

		function generateActionSource(action, year) {
			if (year === 1) {
				return action.yearOneSource || 'N/A';
			} else if (year === 2) {
				return action.yearTwoStatus != 'Unchanged' ? action.yearTwoSource : action.yearOneSource || 'N/A';
			} else if (year === 3) {
				return action.yearThreeStatus != 'Unchanged' ? action.yearThreeSource : action.yearTwoStatus != 'Unchanged' ? action.yearTwoSource : action.yearOneSource;
			}
		}

		function generateActionBudget(action, year) {
			if (year === 1) {
				return action.yearOneBudgetReference || 'N/A';
			} else if (year === 2) {
				return action.yearTwoStatus != 'Unchanged' ? action.yearTwoBudgetReference : action.yearOneBudgetReference || 'N/A';
			} else if (year === 3) {
				return action.yearThreeStatus != 'Unchanged' ? action.yearThreeBudgetReference : action.yearTwoStatus != 'Unchanged' ? action.yearTwoBudgetReference : action.yearOneBudgetReference;
			}
		}
	}
})();
