(function () {
	'use strict';

	angular
		.module('dashboard')
		.controller('UserModalController', userModalController);

	/* @ngInject */
	function userModalController(user, $uibModalInstance, $scope) {
		var $ctrl = this;

		$ctrl.$onInit = activate;

		/////////////////////

		function activate() {
			$ctrl.user = user;
			$ctrl.dismissModal = dismissModal;
			$ctrl.updateUser = updateUser;
			$ctrl.closeModal = closeModal;
		}

		function dismissModal() {
			$uibModalInstance.dismiss();
		}

		function updateUser() {
			$scope.$$childTail.myAccountInfo.customValidator(); // use editAccount.component components
		}

		function closeModal() {
			$uibModalInstance.close();
		}
	}
})();
