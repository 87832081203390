(function () {
	'use strict';

	angular
		.module('app.lcap')
		.component('printLcapFormInfoLatest', {
			templateUrl: 'app/LCAP2/lcapFormInfo/print/printLcapFormInfo.template.html',
			controller: PrintLcapFormInfoCtrl,
			bindings: {
				eTemplate: '<',
			},
		});

	/* @ngInject */
	function PrintLcapFormInfoCtrl() {
		var $ctrl = this;
		$ctrl.lcap = $ctrl.eTemplate.user;
	}
})();
