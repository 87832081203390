(function () {
	'use strict';

	angular.module('app').controller('LCAP3Ctrl', LCAP3Ctrl);

	/* @ngInject */
	function LCAP3Ctrl(
		$rootScope,
		$scope,
		$stateParams,
		$timeout,
		$state,
		mainService,
		validationFactory,
		user,
		eTemplate,
		lcapYears,
		lcapLatestLookup
	) {
		var vm = this;

		vm.$onInit = activate;
		vm.lookup = lcapLatestLookup;
		vm.eTemplate = eTemplate;
		vm.user = user;
		vm.sidebarToggle = false;
		vm.activateSidebar = activateSidebar;
		vm.lcapYears = lcapYears;
		vm.validity = {};
		vm.schoolYearId = $stateParams.schoolYearId;

		$scope.$on('formSave', formSave); // Watch for formSave broadcast

		$scope.$watch(
			function () {
				// Watch when badges are updated
				return $rootScope.validity;
			},
			function () {
				vm.validity = $rootScope.validity;
			},
			true
		);

		$rootScope.$on('$stateChangeSuccess', function () {
			$timeout(function () {
				// Resize textareas on form state change
				$rootScope.$broadcast('elastic:adjust');
			});
		});


		function activate() {
			vm.currentYear = _.findWhere(vm.lcapYears, { isCurrentYear: true });

			if (
				window.location.href.indexOf('localhost:3000') === -1 &&
				vm.currentYear.schoolYearId !== $stateParams.schoolYearId
			) {
				$state.go('dashboard');
			}

			validationFactory.calculateBadges(vm.eTemplate);
			setCurrentYearStatus();

			var currentStatusId = eTemplate.lcapUser.currentYearStatus.statusId;

			if (!$stateParams.review && (currentStatusId === 3 || user.userType === 'Reviewer' || user.userType === 'Admin'))
				{$state.go('.', { review: true });} // add review param manually if user didn't come from forms list

			$timeout(function () {
				// Resize textareas on inital activate
				$rootScope.$broadcast('elastic:adjust');
			});
		}

		function setCurrentYearStatus() {
			// Sets a new field on the form for easy access to current year status
			var item = vm.eTemplate.lcapUser;
			var currentYearStatus;
			item.currentYear = vm.currentYear;

			if (item.currentYear.yearNumber === 1)
				{currentYearStatus = item.yearOneStatus;}
			// if its year 1
			else if (item.currentYear.yearNumber === 2)
				{currentYearStatus = item.yearTwoStatus;}
			// if its year 2
			else if (item.currentYear.yearNumber === 3)
				{currentYearStatus = item.yearThreeStatus;} // if its year 3

			item.currentYearStatus = _.findWhere(vm.lookup.LCAP_STATUS, {
				statusId: currentYearStatus,
			}); // Set current Status
		}

		function activateSidebar() {
			vm.sidebarToggle = !vm.sidebarToggle;
		}

		function formSave(_e, details) {
			details.form.$name = details.form.$name
				.replace('goalsActionsServices', 'goalsActions')
				.replace('goalsActionsGoal', 'goalsActions');
			var formLookup = _.findWhere(vm.lookup.FORMS_LIST, {
				formName: details.form.$name,
			});

			var formObj = vm.eTemplate[formLookup.formObj.split('.').slice(2)[0]];

			if (details.form.statusId) {formObj.statusId = details.form.statusId;}

			formObj.formId = $stateParams.formId;
			formObj.schoolYearId = $stateParams.schoolYearId;

			if (
				details.form.reviewStatusId != null &&
				details.form.reviewStatusId != formObj.reviewStatusId
			) {
				formObj.reviewStatusId = details.form.reviewStatusId;
			}

			if (formLookup.api && !$stateParams.review) {
				$rootScope.loading = true;
				mainService[formLookup.api](formObj)
					.then(function (rsp) {
						// if form section is not the first section && there is a response - update etemplate
						if (formLookup.formName != 'lcapFormInfo' && rsp)
							{eTemplate[formLookup.formName] = rsp;}
						else if (!rsp) {throw new Error('Invalid Response when saving form');}

						toastr.success('Successfully saved!', formLookup.formalName);
						$rootScope.loading = false;
						finishUp(details);
					})
					.catch(function () {
						toastr.error(
							'Error saving form, please try again.',
							formLookup.formalName
						);
						$rootScope.loading = false;
					});
			} else {finishUp(details);}
		}

		function finishUp(details) {
			var totalForms = vm.schoolYearId <= 2 || vm.schoolYearId == 5 ? 9 : 7;

			validationFactory.calculateBadges(vm.eTemplate);

			$timeout(function () {
				var submitterReview =
					details.route === 'submit' &&
					vm.validity.sidebarTotals.valid === totalForms &&
					$stateParams.review != true;
				var reviewedSubmit =
					details.route === 'submit' &&
					vm.validity.reviewApproved + vm.validity.reviewUnapproved ===
					totalForms &&
					$stateParams.review === true;

				// only move if next form is not submit or if all forms are valid and next step is submit
				if (
					details.route != 'submit' ||
					submitterReview === true ||
					reviewedSubmit === true
				)
					{$state.go('LCAP3.' + details.route.replace('goalsActionsServices', 'goalsActions'));}
			});
		}
	}
})();
