(function () {
	'use strict';

	angular
		.module('app')
		.constant('lookup', {
			FORMS_LIST: [
				{
					formalName:
						'Local Control Accountability Plan and Annual Update (LCAP) Template',
					formName: 'lcapFormInfo',
					formObj: 'vm.eTemplate.lcapUser',
					api: 'addForm',
				},
				{
					formalName: 'Budget Overview',
					formName: 'budgetOverview',
					formObj: 'vm.eTemplate.budgetOverview',
					api: 'addBudgetOverview',
				},
				{
					formalName: '2017-2020 Plan Summary',
					formName: 'planSummary',
					formObj: 'vm.eTemplate.planSummary',
					api: 'addPlanSummary',
				},
				{
					formalName: 'Annual Update',
					formName: 'annualUpdate',
					formObj: 'vm.eTemplate.annualUpdate',
					api: 'addAnnualUpdate',
				},
				{
					formalName: 'Annual Update Goals',
					formName: 'annualUpdateGoals',
					formObj: 'vm.eTemplate.annualUpdateGoals',
					api: false,
					skipForm: true,
				},
				{
					formalName: 'Engaging Educational Partners',
					formName: 'stakeholderEngagement',
					formObj: 'vm.eTemplate.stakeholderEngagement',
					api: 'addStakeholder',
				},
				{
					formalName: 'Goals, Actions & Services',
					formName: 'goalsActionsServices',
					formObj: 'vm.eTemplate.goalsActionsServices',
					api: false,
				},
				{
					formalName:
						'Demonstration of Increased or Improved Services for Unduplicated Pupils',
					formName: 'unduplicatedPupils',
					formObj: 'vm.eTemplate.unduplicatedPupils',
					api: 'addPupil',
				},
				{
					formalName: 'Submit LCAP',
					formName: 'submit',
					formObj: 'vm.eTemplate',
					api: 'submit',
				},
			],
			LCAP_SIDEBAR: [
				{
					formName: 'lcapFormInfo',
					route: '',
					title: 'LCAP Template Info',
					formObj: 'lcapUser',
					sectionId: 1,
					print: 'portrait',
				},
				{
					formName: 'budgetOverview',
					route: '',
					title: 'Budget Overview',
					formObj: 'budgetOverview',
					sectionId: 7,
					print: 'portrait',
				},
				{
					formName: 'planSummary',
					route: '',
					title: '%years% Plan Summary',
					formObj: 'planSummary',
					sectionId: 2,
					print: 'portrait',
				},
				{
					formName: 'annualUpdate',
					route: '',
					title: 'Annual Update',
					formObj: 'annualUpdate',
					sectionId: 3,
					print: 'landscape',
				},
				{
					formName: 'annualUpdateGoals',
					route: '',
					title: 'Annual Update',
					formObj: 'annualUpdateGoals',
					sectionId: 3,
					print: 'landscape',
				},
				{
					formName: 'stakeholderEngagement',
					route: '',
					title: 'Engaging Educational Partners',
					formObj: 'stakeholderEngagement',
					sectionId: 4,
					print: 'landscape',
				},
				{
					formName: 'goalsActionsServices',
					route: '',
					title: 'Goals, Actions & Services',
					formObj: 'goalsActionsServices',
					sectionId: 5,
					print: 'landscape',
				},
				{
					formName: 'unduplicatedPupils',
					route: '',
					title: 'Unduplicated Pupils',
					formObj: 'unduplicatedPupils',
					sectionId: 6,
					print: 'landscape',
				},
				{
					formName: 'submit',
					route: '',
					title: 'Submit LCAP',
					formObj: 'eTemplate',
					sectionId: 7,
					print: 'landscape',
				},
			],
			ERROR_MESSAGES: {
				INCOMPLETE:
					'This section is incomplete. Would you like to save and continue?',
				CHANGE:
					'There have been updates to this section since the last save. Would you like to save and continue anyways?',
				INCOMPLETE_GOAL:
					'This goal is incomplete. Click OK to save as a draft or Cancel to finish completing this goal.',
				LOST_CHANGES: 'Any changes will be lost, do you want to continue?',
				UNSAVED_ACTIONS: 'You have unsaved Actions. Save to continue.',
				GOAL_DESCRIPTION: 'The goal description is required to proceed.',
				APPROVED_REVIEW_CHANGE:
					'This section has been approved by the reviewer, submitting additional changes will require anther review of this section. Would you like to proceed saving your changes?',
				REQUESTED_REVIEW_CHANGES:
					'Changes have been requested for this section. Is this section ready for review or are you still working on changes?',
			},
			LCAP_STATUS: [
				{ statusId: 0, status: 'Not Started' },
				{ statusId: 1, status: 'N/A' },
				{ statusId: 2, status: 'Draft' },
				{ statusId: 3, status: 'Review' },
				{ statusId: 4, status: 'Complete' },
			],
			LCAP_REVIEW_STATUS: [
				{ statusId: 0, status: 'Not Started' },
				{ statusId: 1, status: 'Started' },
				{ statusId: 2, status: 'Changes Needed' },
				{ statusId: 3, status: 'Approved' },
			],
			CHARTER_YEARS: [
				{ text: '1 Year', value: 1 },
				{ text: '2 Years', value: 2 },
				{ text: '3 Years', value: 3 },
			],
			VIDEOS: [
				{
					id: 'involvementProcess',
					src: 'https://www.youtube.com/embed/MHq6KF4gbm4',
					num: 15.1,
				},
				{
					id: 'budgetSummary',
					src: 'https://www.youtube.com/embed/dfWm4DlkNaM',
					num: 9,
				},
				{
					id: 'increasedImprovedServices',
					src: 'https://www.youtube.com/embed/PRkY_Fh9xHA',
					num: 8,
				},
				{
					id: 'planSummaryIntro',
					src: 'https://www.youtube.com/embed/WyNQ6jOGb-g',
					num: 2,
				},
				{
					id: 'auAnalysis',
					src: 'https://www.youtube.com/embed/VQSQBv7mf2M',
					num: 13,
				},
				{
					id: 'identifiedNeed',
					src: 'https://www.youtube.com/embed/W4P-ZVBbwms',
					num: 20,
				},
				{
					id: 'stakeholderEngagementIntro',
					src: 'https://www.youtube.com/embed/fEOrW1BhaEo',
					num: 15,
				},
				{
					id: 'performanceGaps',
					src: 'https://www.youtube.com/embed/V8oCftF4Sq8',
					num: 7,
				},
				{
					id: 'gasExpectedAnnualMeasurableOutcomes',
					src: 'https://www.youtube.com/embed/ltyOMVIdy3g',
					num: 21,
				},
				{
					id: 'auIntro',
					src: 'https://www.youtube.com/embed/tLW9Eg50wA4',
					num: 10,
				},
				{
					id: 'impactForInvolvement',
					src: 'https://www.youtube.com/embed/xIjumfIVLWs',
					num: 15.2,
				},
				{
					id: 'writingGoalStatement',
					src: 'https://www.youtube.com/embed/U67ZxMyCYcc',
					num: 18,
				},
				{
					id: 'addressingPriorities',
					src: 'https://www.youtube.com/embed/0WmXKTdF66c',
					num: 19,
				},
				{
					id: 'contOrNotCont',
					src: 'https://www.youtube.com/embed/C7iEi0U6Y5U',
					num: 22,
				},
				{
					id: 'budgetedExpGAS',
					src: 'https://www.youtube.com/embed/keU1zbZZKFo',
					num: 24,
				},
				{
					id: 'principallyDirected',
					src: 'https://www.youtube.com/embed/0G7_Soz7Xcc',
					num: 25,
				},
				{
					id: 'seIntroSpanish',
					src: 'https://www.youtube.com/embed/m6QjO6rqPX0',
					num: '15s',
				},
				{
					id: 'planSummarySpanish',
					src: 'https://www.youtube.com/embed/51V3UVIVAxk',
					num: '2s',
				},
			],
			FORMS_LIST_HEADERS: [
				{ title: 'LEA', sortProp: 'leaName' },
				{ title: 'CDS', sortProp: 'cdsCode' },
				{ title: 'Total Years', sortProp: null },
				{ title: 'LCAP Years', sortProp: null },
				{ title: 'Status', sortProp: 'currentYearStatusId' },
				{ title: '', sortProp: null },
			],
			USERS_LIST_HEADERS: [
				{ title: 'Name', sortProp: 'fullName' },
				{ title: 'Email', sortProp: 'email' },
				{ title: 'Phone', sortProp: null },
				{ title: 'LEA', sortProp: 'school' },
				{ title: 'Role', sortProp: 'userType' },
				{ title: '', sortProp: null },
			],
			SUBMIT_LCAP_MESSAGES: {
				toReview:
					'Are you sure you\'d like to submit this LCAP for review? The LCAP will be locked until the review is complete.',
				changesNeeded:
					'Are you sure you\'d like to send this LCAP and feedback to submitters for changes?',
				approved:
					'Are you sure you\'d like to submit this LCAP as approved and completed? Once submitted, changes to this LCAP will be locked.',
				success: 'Your LCAP for %leaName% has been successfully submitted.',
			},
		});
})();
