(function () {
	'use strict';

	angular
		.module('services')
		.factory('authService', authService);

	/* @ngInject */
	function authService($http, $q, simpleStorageService, appSettings, $rootScope, $timeout, $state) {

		var _authentication = {
			isAuth: false,
			userName: '',
			token: null,
		};
		var serviceBase = appSettings.serverPath;

		var authServiceFactory = {
			login: login,
			logOut: logOut,
			fillAuthData: fillAuthData,
			authentication: getAuthentication,
			helpDeskLogin: helpDeskLogin,
			isLoggedIn: isLoggedIn,
			resetAutoLogout: resetAutoLogout,
		};

		return authServiceFactory;

		function isLoggedIn() {
			return (getAuthentication() != null && _authentication.isAuth);
		}

		function getAuthentication() {
			return simpleStorageService.getItem('authorizationData');
		}

		function resetAutoLogout(c) {
			if (c && c.cancelPrevTimeout) {$timeout.cancel($rootScope.autoLogout);}
			$rootScope.autoLogout = $timeout(autoLogout, 10800000); //set to show alert after 3 hours
		}

		function autoLogout() {
			$timeout(logOut);
			alert('Your session has expired. You will now be routed to the login page');
			$state.go('login');
		}


		function login(loginData) {
			var data = 'grant_type=password&username=' + loginData.username + '&password=' + loginData.password;
			var deferred = $q.defer();

			$http.post(serviceBase + 'token?clientID=eTemplate', data, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
				.then(function (response) {
					_authentication.isAuth = true;
					_authentication.userName = loginData.username;
					_authentication.token = response.data.access_token;

					simpleStorageService.setItem('authorizationData', _authentication);
					simpleStorageService.removeItem('user');
					fillAuthData();

					deferred.resolve(true);
				})
				.catch(function (err) {
					logOut();
					deferred.reject(err);
				});

			resetAutoLogout({ cancelPrevTimeout: true });

			return deferred.promise;
		}

		function logOut() {
			simpleStorageService.removeItem('authorizationData');
			simpleStorageService.removeItem('user');
			$rootScope.$broadcast('loginChanged', false);
		}

		function helpDeskLogin(item) {
			_authentication = item;
			simpleStorageService.setItem('authorizationData', item);
		}

		function fillAuthData() {
			$timeout(function () {
				var authData = simpleStorageService.getItem('authorizationData');
				if (authData) {
					_authentication.isAuth = true;
					_authentication.userName = authData.userName;
					_authentication.token = authData.token;
				}
			});
		}
	}
}());
