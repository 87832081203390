(function () {
	'use strict';

	angular
		.module('app')
		.controller('IncreasedServices4Ctrl', IncreasedServices4Ctrl);

	/* @ngInject */
	function IncreasedServices4Ctrl(
		$rootScope,
		$scope,
		$timeout,
		eTemplate,
		$stateParams,
		lcapYears,
		user
	) {
		var vm = this;
		vm.validity = $rootScope.validity;
		vm.$onInit = activate;
		vm.inReview = $stateParams.review;

		$scope.$watch(
			function () {
				// Watch when badges are updated
				return $rootScope.validity;
			},
			function () {
				vm.validity = $rootScope.validity;
			},
			true
		);

		function activate() {
			$timeout(checkFeedback);
			$timeout(disableYears);
			
			vm.eTemplate = eTemplate;
			vm.lcapYears = lcapYears;
			vm.disableYears = disableYears;
			vm.currentYear = _.findWhere(lcapYears, { isCurrentYear: true });
			vm.user = user;
		}


		function checkFeedback() {
			vm.showFeedback =
				(vm.inReview &&
					vm.eTemplate.lcapUser.currentYearStatus.statusId === 3) ||
				vm.eTemplate.unduplicatedPupils.reviewStatusId != 0;
			$rootScope.$broadcast('showFeedback', vm.showFeedback);
		}

		function disableYears() {
			vm.disableYearOne =
				vm.eTemplate.lcapUser.yearOneStatus != 2 &&
				vm.eTemplate.lcapUser.yearOneStatus != 0;
			vm.disableYearTwo =
				vm.eTemplate.lcapUser.yearTwoStatus != 2 &&
				vm.eTemplate.lcapUser.yearTwoStatus != 0;
			vm.disableYearThree =
				vm.eTemplate.lcapUser.yearThreeStatus != 2 &&
				vm.eTemplate.lcapUser.yearThreeStatus != 0;
		}
	}
})();
