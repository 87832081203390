(function () {
	'use strict';

	angular.module('app').component('lcapSidebarLatest4', {
		templateUrl: 'app/LCAP4/shared/lcapSidebar/lcapSidebar.html',
		controller: LCAPSidebar4Controller,
		bindings: {
			sidebarToggle: '<',
			etemplate: '<',
			validity: '<',
			lcapYears: '<',
			user: '<',
		},
	});

	/* @ngInject */
	function LCAPSidebar4Controller(
		$rootScope,
		$state,
		$timeout,
		$stateParams,
		$scope,
		lcapLatestLookup,
		validationFactory
	) {
		var $ctrl = this;

		$ctrl.$onInit = activate;

		$rootScope.$on('$stateChangeSuccess', function () {
			setStateName();

			$('#sidebar-affix').removeClass('affix');
			setSubmitLogic();
		});

		$scope.$on('showFeedback', function (event, args) {
			$ctrl.showCommentBtn = args;
		}); // Watch whether or not to show feedback button

		///////////////////

		function activate() {
			$ctrl.lookup = angular.copy(lcapLatestLookup);
			_.forEach($ctrl.lookup.LCAP_SIDEBAR, function(item) {
				if(item && item.skipSideBar){
					$ctrl.lookup.LCAP_SIDEBAR.splice(_.indexOf($ctrl.lookup.LCAP_SIDEBAR, item), 1);
				}
			});
			$ctrl.navigate = navigate;
			$ctrl.eTemplate = $ctrl.etemplate;
			$ctrl.isReview = $stateParams.review;
			$ctrl.currentYearStatus =
				$ctrl.eTemplate.lcapUser.currentYearStatus.statusId;
			$ctrl.schoolYearId = $stateParams.schoolYearId;

			setStateName();
			setPlanSummaryYears();
			$timeout(setSubmitLogic);
		}

		function setSubmitLogic() {
			var totalForms = $ctrl.schoolYearId <= 2 || $ctrl.schoolYearId == 5 ? 9 : 7;

			if ($ctrl.validity) {
				//Added
				$timeout(function () {
					$ctrl.showSubmit = {
						ifReview:
							$ctrl.validity.reviewApproved +
							$ctrl.validity.reviewUnapproved ===
							totalForms &&
							$ctrl.isReview === true &&
							$ctrl.user.userType === 'Reviewer' &&
							$ctrl.currentYearStatus === 3,
						ifDraft:
							$ctrl.validity.sidebarTotals.valid === totalForms &&
							$ctrl.isReview != true &&
							$ctrl.currentYearStatus === 2,
					};

				});
			}
		}

		function setStateName() {
			$ctrl.stateName = $state.current.name.split('.').slice(1)[0];
			$ctrl.isGoalPage =
				$ctrl.stateName === 'annualUpdate' ||
				$ctrl.stateName === 'goalsActions';
		}

		function setPlanSummaryYears() {
			var summaryIndex = _.findIndex($ctrl.lookup.LCAP_SIDEBAR, {
				formName: 'planSummary',
			});
			var planSummaryTitle = $ctrl.lookup.LCAP_SIDEBAR[summaryIndex].title;

			planSummaryTitle = planSummaryTitle.replace(
				'%years%',
				$ctrl.lcapYears[0].yearCycle
			);
			$ctrl.lookup.LCAP_SIDEBAR[summaryIndex].title = planSummaryTitle;
		}

		function navigate(route) {
			// Prevent navigation to the current active page plus special submit rules
			if ($ctrl.stateName != route && $ctrl.stateName != 'submit')
				{validationFactory.validateForm($rootScope.form, route);}
			else if ($ctrl.stateName === 'submit') {$state.go('LCAP4.' + route);}
		}
	}
})();
