(function () {
	'use strict';

	angular
		.module('services')
		.factory('dashboardService', dashboardService);

	/* @ngInject */
	function dashboardService($q, dataservice, simpleStorageService) {
		var service = {
			getAllForms: getAllForms,
			getUserFromClaims: getUserFromClaims,
			addApplicant: addApplicant,
			getSchools: getSchools,
			getForms: getForms,
			getAssignedLCAPs: getAssignedLCAPs,
			getLCAPUsers: getLCAPUsers,
			deleteApplicant: deleteApplicant,
			getSchoolYears: getSchoolYears,
			updatePassword: updatePassword,
			getCountyDistricts: getCountyDistricts,
			getCOEs: getCOEs,
			getCOEReviewers: getCOEReviewers,
			getLeaAccess: getLeaAccess,
			reactivateApplicant: reactivateApplicant,
			resetApplicantPassword: resetApplicantPassword,
			getAllCoordinators: getAllCoordinators,
			updateUser: updateUser,
			getAdminUsers: getAdminUsers,
		};

		return service;

		function getAllForms() {
			return dataservice.getData('User/GetAllForms');
		}

		function getUserFromClaims() {
			var user = simpleStorageService.getItem('user');
			if (user != null) {return $q.resolve(user);}

			return dataservice.getData('User/GetUser').then(function (rsp) {
				simpleStorageService.setItem('user', rsp);
				return rsp;
			});
		}

		function addApplicant(applicant, modifyerUserId, modifyerTitle) {
			return dataservice.postData('User/AddApplicant'+'/'+modifyerUserId+'/'+modifyerTitle, applicant);
		}

		function getSchools(user) {
			return dataservice.getData('User/GetSchools/' + user.cdsCode);
		}

		function getForms(cdsCode) {
			return dataservice.getData('User/GetForms/' + cdsCode);
		}

		function getAssignedLCAPs(userId) {
			return dataservice.getData('User/GetAssignedLCAPs/' + userId);
		}

		function getLCAPUsers(cdsCode) {
			return dataservice.getData('User/GetLCAPUsers/' + cdsCode);
		}

		function deleteApplicant(userId, cdsCode, modifyerUserId, modifyerTitle) {
			return dataservice.postData('User/DeleteApplicant/' + userId + '/' + cdsCode+'/'+modifyerUserId +'/'+modifyerTitle);
		}

		function getSchoolYears(cycleId) {
			return dataservice.getData('User/GetSchoolYears/' + cycleId);
		}

		function updatePassword(user) {
			return dataservice.postData('User/UpdatePassword', user);
		}

		function getCountyDistricts(cdsCode) {
			return dataservice.getData('User/GetCountyDistricts/' + cdsCode);
		}

		function getCOEs() {
			return dataservice.getData('User/GetCOEs');
		}

		function getCOEReviewers() {
			return dataservice.getData('User/GetCOEReviewers');
		}

		function getLeaAccess(userId) {
			return dataservice.getData('User/GetLeaAccess/' + userId);
		}

		function reactivateApplicant(userId, modifyerUserId, modifyerTitle) {
			return dataservice.postData('User/ReactivateApplicant/' + userId+'/'+modifyerUserId +'/'+modifyerTitle);
		}

		function resetApplicantPassword(applicant) {
			return dataservice.postData('User/UpdateApplicantPassword', applicant);
		}

		function getAllCoordinators() {
			return dataservice.getData('User/GetAllCoordinators');
		}

		function updateUser(user) {
			return dataservice.postData('User/UpdateUser', user);
		}

		function getAdminUsers() {
			return dataservice.getData('User/GetAdminUsers');
		}
	}
})();
