(function () {
	'use strict';

	angular
		.module('dashboard')
		.run(appRun);

	/* @ngInject */
	function appRun(routerHelper) {
		routerHelper.configureStates(getStates());
	}

	function getStates() {
		return [{
			state: 'coordinatorForm',
			config: {
				url: '/coordinatorForm',
				templateUrl: 'app/dashboard/coordinatorForm/coordinatorForm.html',
				title: 'Admin Form',
				controller: 'CoordinatorFormCtrl',
				allowAnonymous: false,
				resolve: {
					/* @ngInject */
					user: function (dashboardService) {
						return dashboardService.getUserFromClaims();
					},
					/* @ngInject */
					schools: function (dashboardService, user) {
						return dashboardService.getSchools(user);
					},
				},
			},
		},
		{
			state: 'dashboard',
			config: {
				url: '/dashboard?{view:string}',
				templateUrl: 'app/dashboard/dashboard.html',
				title: 'Dashboard',
				controller: 'DashboardCtrl',
				allowAnonymous: false,
				resolve: {
					/* @ngInject */
					user: function (dashboardService) {
						return dashboardService.getUserFromClaims();
					},
					/* @ngInject */
					forms: function (dashboardService, user) {
						if (user != null) {
							if (user.userType != 'Admin') {
								return dashboardService.getAssignedLCAPs(user.userId);
							} else if (user.userType === 'Admin') {
								return dashboardService.getAllForms();
							}
						} else {
							return [];
						}
					},
					/* @ngInject */
					users: function (dashboardService, user) {
						if (user.userType != 'Admin') {
							return dashboardService.getLCAPUsers(user.cdsCode);
						} else {
							return dashboardService.getAdminUsers();
						}
					},
					/* @ngInject */
					lcapYears: function (dashboardService, user) {
						return dashboardService.getSchoolYears(user.lcapCycleId);
					},
					/* @ngInject */
					dashboardYear: function (mainService) {
						return mainService.getDashboardYear()
							.then(function (rsp) {
								if (rsp === 3) { return 2017; }
								else if (rsp === 4) { return 2018; }
								else if (rsp === 5) { return 2019; }
								else if (rsp === 6) { return 2020; }
								else if (rsp === 7) { return 2021; }
								else if (rsp === 8) { return 2022; }
								else { return rsp; }
							});
					},
					/* @ngInject */
					leaAccess: function (dashboardService, user) {
						return dashboardService.getLeaAccess(user.userId);
					},
				},
			},
		},
		];
	}
}());
