/**
 * Created by mthomasson on 1/26/2015.
 */
(function () {
	'use strict';

	angular
		.module('services')
		.factory('authInterceptorService', authInterceptorService);

	/* @ngInject */
	function authInterceptorService($q, $location, simpleStorageService) {
		return {
			request: function (config) {
				config.headers = config.headers || {};
				var authData = simpleStorageService.getItem('authorizationData');
				if (authData) {
					config.headers.Authorization = 'Bearer ' + authData.token;
				}
				return config;
			},
			responseError: function (rejection) {
				if (rejection.status === 401 && rejection.config.url.indexOf('User/GetUser') === -1) {
					$location.path('/login');
				}
				return $q.reject(rejection);
			},
		};
	}
})();
