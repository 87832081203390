(function () {
  'use strict';

  angular
    .module('app')
    .controller('ExpenditureTableCtrl', ExpenditureTableCtrl);

  /* @ngInject */
  function ExpenditureTableCtrl($http, $stateParams, appSettings, miscService) {
    var vm = this;
    vm.exportContributingExpenditures = exportContributingExpenditures;
    vm.exportDataTable = exportDataTable;
    vm.exportTotalExpenditures = exportTotalExpenditures;

    function exportContributingExpenditures() {
      $http({
        url:
          appSettings.serverPath +
          'api/Report/GetContributingExpendituresTableToCsv/' +
          $stateParams.formId,
        method: 'GET',
        responseType: 'arraybuffer',
        headers: {
          'Content-type': 'application/json',
          Accept: 'text/csv',
        },
      }).then(function (rsp) {
        saveAs(
          new Blob([rsp.data], { type: 'text/csv' }),
          miscService.getGuid() + '.csv'
        );
      });
    }

    function exportDataTable() {
      $http({
        url:
          appSettings.serverPath +
          'api/Report/GetDataEntryTableToCsv/' +
          $stateParams.formId,
        method: 'GET',
        responseType: 'arraybuffer',
        headers: {
          'Content-type': 'application/json',
          Accept: 'text/csv',
        },
      }).then(function (rsp) {
        saveAs(
          new Blob([rsp.data], { type: 'text/csv' }),
          miscService.getGuid() + '.csv'
        );
      });
    }
    function exportTotalExpenditures() {
      $http({
        url:
          appSettings.serverPath +
          'api/Report/GetTotalExpendituresToCsv/' +
          $stateParams.formId,
        method: 'GET',
        responseType: 'arraybuffer',
        headers: {
          'Content-type': 'application/json',
          Accept: 'text/csv',
        },
      }).then(function (rsp) {
        saveAs(
          new Blob([rsp.data], { type: 'text/csv' }),
          miscService.getGuid() + '.csv'
        );
      });
    }
  }
})();
