(function () {
	'use strict';

	angular
		.module('app')
		.controller('BudgetOverviewCtrl', BudgetOverviewCtrl);

	/* @ngInject */
	function BudgetOverviewCtrl($rootScope, $timeout, eTemplate, lcapYears, $stateParams, user) {
		var vm = this;

		vm.$onInit = activate;
		vm.eTemplate = eTemplate;
		vm.inReview = $stateParams.review;
		vm.lcapYears = lcapYears;
		vm.yearCycle = lcapYears[0].yearCycle;
		vm.user = user;
		vm.add = add;


		function activate() {
			$timeout(checkFeedback);

			if (!vm.eTemplate.budgetOverview.comingYear) {
				vm.eTemplate.budgetOverview.comingYear = '2019-20';
			}

			if (!vm.eTemplate.budgetOverview.currentYear) {
				vm.eTemplate.budgetOverview.currentYear = '2018-19';
			}
		}

		function checkFeedback() {
			vm.showFeedback = (vm.inReview && vm.eTemplate.lcapUser.currentYearStatus.statusId === 3) || vm.eTemplate.budgetOverview.reviewStatusId != 0;
			$rootScope.$broadcast('showFeedback', vm.showFeedback);
		}

		function add(set) {
			if (set === 'funds') {
				vm.eTemplate.budgetOverview.totalProjectedRevenue =
					vm.eTemplate.budgetOverview.totalFunds +
					vm.eTemplate.budgetOverview.otherStateFunds +
					vm.eTemplate.budgetOverview.localFunds +
					vm.eTemplate.budgetOverview.federalFunds;
			} else if (set === 'futureExpenditures') {
				vm.eTemplate.budgetOverview.futureExpendituresNotInLCAP =
					vm.eTemplate.budgetOverview.futureGeneralFunds - vm.eTemplate.budgetOverview.futureExpendituresInLCAP;
			}
		}
	}
})();
