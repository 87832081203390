(function () {
	'use strict';

	angular
		.module('app.lcap')
		.component('printStakeholderEngagementLatest5', {
			templateUrl: 'app/LCAP5/stakeholderEngagement/print/printStakeholderEngagement.template.html',
			controller: PrintStakeholderEngagement5Ctrl,
			bindings: {
				eTemplate: '<',
			},
		});

	/* @ngInject */
	function PrintStakeholderEngagement5Ctrl() {
		var $ctrl = this;

		$ctrl.$onInit = activate;

		/////////////////////

		function activate() {
			$ctrl.stake = $ctrl.eTemplate.stakeHolder;
		}
	}
})();
