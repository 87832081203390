(function () {
    'use strict';

    angular
        .module('app.lcap')
        .component('printStakeholderEngagementLatest', {
            templateUrl: 'app/LCAP2/stakeholderEngagement/print/printStakeholderEngagement.template.html',
            controller: PrintStakeholderEngagementCtrl,
            bindings: {
                eTemplate: '<',
            },
        });

    /* @ngInject */
    function PrintStakeholderEngagementCtrl() {
        var $ctrl = this;
        $ctrl.stake = $ctrl.eTemplate.stakeHolder;
    }
})();