(function () {
	'use strict';

	angular
		.module('app')
		.controller('AnnualUpdateCtrl', annualUpdateCtrl);

	/* @ngInject */
	function annualUpdateCtrl($scope, $rootScope, $uibModalInstance, $ngBootbox, $timeout, $stateParams, eTemplate, editableGoal, goalIndex, mainService, lookup, validationFactory, user) {
		var vm = this;

		vm.$onInit = activate;

		$scope.$on('loginChanged', function () { // Closes Modal on autologout
			$uibModalInstance.dismiss('cancel');
		});

		function activate() {
			vm.lookup = lookup;
			vm.editableGoal = editableGoal;
			vm.dismissModal = dismissModal;
			vm.newGoal = {};
			vm.newAction = {};
			vm.saveGoal = saveGoal;
			vm.addAction = addAction;
			vm.deleteAction = deleteAction;
			vm.checkPriorities = checkPriorities;
			vm.goalIndex = goalIndex;
			vm.addOutcome = addOutcome;
			vm.deleteOutcome = deleteOutcome;
			vm.inReview = $stateParams.review;
			vm.eTemplate = eTemplate;
			vm.closeModal = closeModal;
			vm.user = user;
			vm.loading = true;
			vm.formId = $stateParams.formId || 0;

			$timeout(function () {
				vm.form = $scope.annualUpdateGoal;
				vm.form.customValidator = saveGoal;

				$rootScope.$broadcast('elastic:adjust'); // Adjust textarea heights
			});

			setupGoals();
		}

		function addAction() {
			vm.newGoal.actionsServices.push(vm.newAction); // push existing action
			vm.newAction = {}; // reset
		}

		function setupGoals() {
			if (editableGoal) {
				mainService.getAnnualUpdateGoals(editableGoal.formId, editableGoal.schoolYearId, editableGoal.goalId)
					.then(function (rsp) {
						vm.newGoal = angular.copy(rsp);
						vm.newGoal.reviewStatusId = vm.newGoal.details.reviewStatusId;
						vm.newGoal.sortOrder = vm.newGoal.details.sortOrder;

						checkFeedback();
						setWasRolledOver();

						if (!vm.newGoal.actionsServices.length) {vm.newGoal.actionsServices = [{}];}
						if (!vm.newGoal.outcomes.length) {vm.newGoal.outcomes = [{}];}

						// I know we import goalIndex already, I want to make sure I'm replacing the correct goal
						var goalIndex = _.findIndex(vm.eTemplate.annualUpdate.goals, { goalId: vm.newGoal.goalId });
						vm.eTemplate.annualUpdate.goals[goalIndex] = vm.newGoal;
						vm.loading = false;
					})
					.catch(function () {
						toastr.error('Error opening goal, refresh and try again');
						$uibModalInstance.dismiss('cancel');
						vm.loading = false;
					});
			} else {
				vm.newGoal = {
					actionsServices: [{}],
					outcomes: [{}],
					details: {
						reviewStatusId: vm.eTemplate.annualUpdate.reviewStatusId != 0 ? 1 : 0,
					},
				};

				checkFeedback();
				setWasRolledOver();
				vm.loading = false;
			}
		}

		function checkFeedback() {
			vm.showFeedback = (vm.inReview && vm.eTemplate.lcapUser.currentYearStatus.statusId === 3) || (vm.newGoal.details.reviewStatusId != 0 && vm.newGoal.details.goalId);
		}

		function setWasRolledOver() {
			vm.wasRolledOver = vm.newGoal.schoolYearId === 3 && vm.eTemplate.lcapUser.yearTwoStatus === 4 ? true : false;
		}

		function addOutcome() {
			vm.newGoal.outcomes.push({});
		}

		function deleteOutcome(index, outcome) {
			if (confirm('Are you sure you want to delete Outcome #' + (index + 1) + '?')) {
				if (outcome.id != null) {mainService.deleteAUOutcome(outcome.id);}
				vm.newGoal.outcomes.splice(index, 1);
			}
		}

		function deleteAction(index, actionId) {
			if (confirm('Are you sure you want to delete Action #' + (index + 1) + '?')) {
				if (actionId != null) {mainService.deleteAUAction(actionId);}
				vm.newGoal.actionsServices.splice(index, 1);
			}
		}

		function checkPriorities(form) {
			return validationFactory.validatePriorities(vm.newGoal.details, form);
		}

		function saveGoal(form, status) {
			var saveDraft = status === false && vm.newGoal.details != null && vm.newGoal.details.goal;
			var formValid = form.$valid && checkPriorities(form) === true && vm.newGoal.actionsServices.length > 0;
			var formInvalid = form.$invalid || checkPriorities(form) === false || vm.newGoal.actionsServices.length === 0;
			var goalDetailsRequired = !vm.newGoal.details || !vm.newGoal.details.goal;

			if (saveDraft) {closeModal(false);} // Save as draft
			else if (formValid) {
				if (vm.newGoal.details.reviewStatusId === 2) {
					$ngBootbox.customDialog(getReviewEditModal());
				} else if (vm.newGoal.details.reviewStatusId === 3) {
					if (confirm(lookup.ERROR_MESSAGES.APPROVED_REVIEW_CHANGE)) {
						vm.newGoal.details.reviewStatusId = 1; // Reset for re-review
						closeModal(true); // Save Valid Form
					}
				} else {
					closeModal(true); // Save Valid Form
				}
			}
			else if (formInvalid) { // Invalid Form
				if (goalDetailsRequired) { // Goal details are required
					alert(vm.lookup.ERROR_MESSAGES.GOAL_DESCRIPTION);
				} else if (confirm(vm.lookup.ERROR_MESSAGES.INCOMPLETE_GOAL)) {
					closeModal(false);
				}
			}
		}

		function closeModal(status) {
			vm.newGoal.details.status = status;
			$uibModalInstance.close(vm.newGoal, vm.form);
			$rootScope.modalOpen = false;
		}

		function getReviewEditModal() {
			var modal = {
				message: lookup.ERROR_MESSAGES.REQUESTED_REVIEW_CHANGES,
				buttons: {
					danger: {
						label: 'Still Working',
						className: 'btn-warning',
						callback: function () {
							vm.newGoal.details.reviewStatusId = 2;
							closeModal(true);
						},
					},
					success: {
						label: 'Ready for Review',
						className: 'btn-success',
						callback: function () {
							vm.newGoal.details.reviewStatusId = 1;
							closeModal(true);
						},
					},
				},
			};

			return modal;
		}

		function dismissModal() {
			if (!vm.inReview) {
				if (confirm(vm.lookup.ERROR_MESSAGES.LOST_CHANGES)) {
					$uibModalInstance.dismiss('cancel');
				}
			} else {$uibModalInstance.dismiss('cancel');}
		}
	}
})();
