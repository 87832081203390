(function () {
	'use strict';

	angular
		.module('app')
		.controller('AnnualUpdatePageCtrl', AnnualUpdatePageCtrl);

	/* @ngInject */
	function AnnualUpdatePageCtrl($rootScope, $scope, $stateParams, $timeout, eTemplate, mainService, $uibModal, validationFactory, lcapYears, authService, dashboardService, user) {
		var vm = this;

		vm.$onInit = activate;
		vm.eTemplate = eTemplate;
		vm.deleteAUGoal = deleteAUGoal;
		vm.openAnnualUpdateModal = openAnnualUpdateModal;
		vm.validity = $rootScope.validity;
		vm.inReview = $stateParams.review;
		vm.lcapYears = lcapYears;

		$scope.$watch(function () { // watch validity for goal values
			return $rootScope.validity;
		}, function () {
			vm.validity = $rootScope.validity;
		}, true);


		function activate() {
			$timeout(function () {
				vm.form = $scope.annualUpdate;
				vm.form.customValidator = validate;
				vm.form.continueValidation = true; // return to continue default factory validation

				_.each(vm.eTemplate.annualUpdate.goals, function (item) {
					item.sortOrder = item.details.sortOrder; // set sort order correctly
				});

				sortGoals();

				// $('#au-goals-list').sortable({
				//     items: "li:not(.unsortable)",
				//     update: updateSortOrder
				// });

				// $(".sortable").disableSelection(); // Disable the add new goal button from being sortable

				setPreviousYear();
				validationFactory.calculateBadges(vm.eTemplate); // Calc on activate
				vm.wasRolledOver = vm.eTemplate.lcapUser.schoolYearId === 3 && vm.eTemplate.lcapUser.yearTwoStatus === 4 ? true : false;
			});
		}

		function setPreviousYear() {
			if (vm.eTemplate.lcapUser.yearId === 1) { // Get previous cycle if its the first year of this new cycle
				dashboardService.getSchoolYears(vm.eTemplate.lcapUser.lcapCycleId - 1)
					.then(function (rsp) {
						vm.previousYear = _.findWhere(rsp, { yearNumber: 3 });
					});
			} else {
				vm.previousYear = _.findWhere(vm.lcapYears, { yearNumber: (vm.eTemplate.lcapUser.yearId - 1) }); // If new goal, set to previous year Reviewed
			}
		}

		function sortGoals() {
			vm.eTemplate.annualUpdate.goals = _.sortBy(vm.eTemplate.annualUpdate.goals, 'sortOrder');
		}

		// function updateSortOrder(event, ui) {
		//     var $li = $('#au-goals-list').find('li:not(\'.unsortable\')');

		//     _.each($li, function(item, i) {
		//         var goal = $(item).data().$scope.goal;

		//         goal.sortOrder = i;
		//         goal.details.sortOrder = i;

		//         $timeout(function() {
		//             mainService.addAnnualUpdateGoals(goal);
		//         });
		//     });

		//     $timeout(sortGoals);
		// }

		function openAnnualUpdateModal(form, goalToEdit, index) {
			$uibModal.open({
				controller: 'AnnualUpdateCtrl',
				controllerAs: 'vm',
				size: 'lg',
				templateUrl: 'app/LCAP/annualUpdate/annualUpdateModal/annualUpdateModal.html',
				backdrop: 'static',
				keyboard: false,
				resolve: {
					editableGoal: function () { return goalToEdit; },
					goalIndex: function () { return index; },
					eTemplate: function () { return eTemplate; },
					user: function () { return user; },
				},
			}).result.then(function (goal) {
				// if this is the first goal
				if (!vm.eTemplate.annualUpdate.goals) {vm.eTemplate.annualUpdate.goals = [];}

				// Get existing goal index then update existing goal
				var goalIndex = _.findIndex(vm.eTemplate.annualUpdate.goals, { goalId: goal.goalId }); // exising goal index

				if (goalIndex != -1) { // Updating existing goal
					vm.eTemplate.annualUpdate.goals[goalIndex] = goal;
				} else { // Brand New Goal
					goal.details.yearReviewed = vm.previousYear.year;
					goal.formId = $stateParams.formId; // Set formId
					goal.details.formId = $stateParams.formId; // both formId's need to be set
					goal.details.sortOrder = vm.eTemplate.annualUpdate.goals.length; // set both sortOrders
					goal.sortOrder = vm.eTemplate.annualUpdate.goals.length; // set both sortOrders
					vm.eTemplate.annualUpdate.goals.push(goal);
				}

				sortGoals();
				goal.schoolYearId = $stateParams.schoolYearId; // needs to be dynamic

				mainService.addAnnualUpdateGoals(goal)
					.then(function (rsp) {
						goal.goalId = rsp.goalId; // pull and set goalId
						if (!goal.details.goalId) {goal.details.goalId = rsp.goalId;}
						goal.details.id = rsp.id; // pull and set detailsId

						_.each(goal.actionsServices, function (item, i) { // pull and set actionIds
							item.actionId = rsp.serviceIds[i];
						});

						_.each(goal.outcomes, function (item, i) { // pull and set actionIds
							item.id = rsp.outcomeIds[i];
						});

						toastr.success('Annual Update successfully saved!');
					})
					.catch(function () {
						toastr.error('Error saving annual update, please try again.');
						openAnnualUpdateModal(vm.form, goal, goalIndex);
						$rootScope.modalOpen = true;
					});

				validationFactory.calculateBadges(vm.eTemplate); // Calc Badges
				authService.resetAutoLogout({ cancelPrevTimeout: true }); // Reset auto logout
				$rootScope.form = form; // Set rootscope form on close
			}, function () {
				validationFactory.calculateBadges(vm.eTemplate); // Calc Badges
				authService.resetAutoLogout({ cancelPrevTimeout: true }); // Reset auto logout
				$rootScope.form = form; // Set rootscope form on dismiss
			});
		}

		function deleteAUGoal(index, _form, goal) {
			if (confirm('Are you sure you want to delete Goal #' + (index + 1) + ' from the Annual Update\'s list of goals?')) {
				mainService.deleteAUGoal(goal.goalId)
					.then(function () {
						vm.eTemplate.annualUpdate.goals.splice(index, 1);
						validationFactory.calculateBadges(vm.eTemplate);
					}).finally(function () {
						_.each(vm.eTemplate.annualUpdate.goals, function (item, i) {
							item.sortOrder = i,
								item.details.sortOrder = i;

							$timeout(function () {
								mainService.addAnnualUpdateGoals(item);
							});
						});
					});
			}
		}

		function validate() {
			var calcs = vm.validity.goals.annualUpdate;

			if (calcs.goalsDraft >= 1 || calcs.goalsComplete === 0) {vm.form.$setValidity('required', false);} // Invalid
			else {vm.form.$setValidity('required', true);} // Valid
		}
	}
})();
