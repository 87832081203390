(function () {
	'use strict';

	angular
		.module('app')
		.controller('GoalsActionsServicesPageCtrl', GoalsActionsServicesPageCtrl);

	/* @ngInject */
	function GoalsActionsServicesPageCtrl($rootScope, $timeout, $scope, $stateParams, eTemplate, validationFactory, mainService, $uibModal, lcapYears, authService, user) {
		var vm = this;

		vm.$onInit = activate;
		vm.eTemplate = eTemplate;
		vm.openGoalsActionsServicesModal = openGoalsActionsServicesModal;
		vm.deleteGasGoal = deleteGasGoal;
		vm.validity = $rootScope.validity;
		vm.inReview = $stateParams.review;

		$scope.$watch(function () { // watch validity for goal values
			return $rootScope.validity;
		}, function () {
			vm.validity = $rootScope.validity;
		}, true);


		function activate() {
			$timeout(function () {
				vm.form = $scope.goalsActionsServices; // Form Object
				vm.form.customValidator = validate; // Custom validator for this form
				vm.form.continueValidation = true; // return to continue default factory validation

				_.each(vm.eTemplate.goalsActionsServices.goals, function (item) {
					item.sortOrder = item.details.sortOrder; // set sort order correctly
				});

				sortGoals();

				// $('#gas-goals-list').sortable({ // jQuery UI sortable
				//     items: "li:not(.unsortable)",
				//     update: updateSortOrder
				// });

				// $(".sortable").disableSelection(); // Disable the add new goal button from being sortable

				validationFactory.calculateBadges(vm.eTemplate); // Calc on activate
			});
		}

		function sortGoals() {
			vm.eTemplate.goalsActionsServices.goals = _.sortBy(vm.eTemplate.goalsActionsServices.goals, 'sortOrder');
		}

		// function updateSortOrder(event, ui) {
		//     var $li = $(this).find('li:not(\'.unsortable\')');

		//     _.each($li, function(item, i) {
		//         var goal = $(item).data().$scope.goal;

		//         goal.sortOrder = i;
		//         goal.details.sortOrder = i;

		//         $timeout(function() {
		//             mainService.addGASGoal(goal);
		//         });
		//     });

		//     $timeout(sortGoals);
		// }

		function openGoalsActionsServicesModal(form, goalToEdit, index) {
			$uibModal.open({
				controller: 'GoalsActionsServicesCtrl',
				controllerAs: 'vm',
				size: 'lg',
				templateUrl: 'app/LCAP/goalsActionsServices/goalsActionsServicesModal/goalsActionsServicesModal.html',
				backdrop: 'static',
				keyboard: false,
				resolve: {
					editableGoal: function () { return goalToEdit; },
					goalIndex: function () { return index; },
					lcapYears: function () { return lcapYears; },
					eTemplate: function () { return eTemplate; },
					user: function () { return user; },
				},
			}).result.then(function (goal) {
				// if this is the first goal
				if (!vm.eTemplate.goalsActionsServices.goals) {vm.eTemplate.goalsActionsServices.goals = [];}

				// Get existing goal index then update existing goal
				var goalIndex = _.findIndex(vm.eTemplate.goalsActionsServices.goals, { goalId: goal.goalId });

				if (goalIndex != -1) { // update existing goal
					vm.eTemplate.goalsActionsServices.goals[goalIndex] = goal;
				} else { // New goal
					goal.details.yearReviewed = vm.eTemplate.lcapUser.currentYear.year;
					goal.details.sortOrder = vm.eTemplate.goalsActionsServices.goals.length; // set both sortOrders
					goal.sortOrder = vm.eTemplate.goalsActionsServices.goals.length; // set both sortOrders
					goal.formId = $stateParams.formId; // both formId's need to be set
					goal.details.formId = $stateParams.formId; // both formId's need to be set
					vm.eTemplate.goalsActionsServices.goals.push(goal); // push new goal
				}

				sortGoals();
				goal.details.schoolYearId = $stateParams.schoolYearId; // Needs to be dynamic

				mainService.addGASGoal(goal)
					.then(function (rsp) {
						if (rsp.errors === true) {throw new Error('Error from server');}

						goal.goalId = rsp.goalId; // Set goalId twice
						goal.details.goalId = rsp.goalId; // Set goalId twice

						_.each(goal.expectedOutcomes, function (item, i) { // pull and set outcomeIds
							item.outcomeId = rsp.outcomeIds[i];
						});

						_.each(goal.actions, function (item, i) { // pull and set actionIds
							item.actionId = rsp.actionIds[i];
						});

						toastr.success('Goal successfully saved!');
					})
					.catch(function () {
						toastr.error('Error saving goal, please try again.');
						openGoalsActionsServicesModal(vm.form, goal, goalIndex);
						$rootScope.modalOpen = true;
					});

				validationFactory.calculateBadges(vm.eTemplate); // Calc Badges
				authService.resetAutoLogout({ cancelPrevTimeout: true }); // Reset auto logout
				$rootScope.form = form; // Set rootscope form on close
			}, function () {
				validationFactory.calculateBadges(vm.eTemplate); // Calc Badges
				authService.resetAutoLogout({ cancelPrevTimeout: true }); // Reset auto logout
				$rootScope.form = form; // Set rootscope form on dismiss
			});
		}

		function deleteGasGoal(index, _form, goal) {
			if (confirm('Are you sure you want to delete Goal #' + (index + 1) + ' from the Goals, Actions and Services list of goals?')) {
				mainService.deleteGASGoal(goal.goalId)
					.then(function () {
						vm.eTemplate.goalsActionsServices.goals.splice(index, 1);
						validationFactory.calculateBadges(vm.eTemplate);
					}).finally(function () {
						_.each(vm.eTemplate.goalsActionsServices.goals, function (item, i) {
							item.sortOrder = i;
							item.details.sortOrder = i;

							$timeout(function () {
								mainService.addGASGoal(item);
							});
						});
					});
			}
		}

		function validate() {
			var calcs = vm.validity.goals.goalsActionsServices;

			if (calcs.goalsDraft >= 1 || calcs.goalsComplete === 0) {vm.form.$setValidity('required', false);} // Invalid
			else {vm.form.$setValidity('required', true);} // Valid
		}
	}
})();
