(function () {
	'use strict';

	angular.module('app.lcap').run(appRun);

	appRun.$inject = ['routerHelper', '$rootScope'];

	function appRun(routerHelper, $rootScope) {
		routerHelper.configureStates(getStates());

		$rootScope.$on('$stateChangeSuccess', function () {
			document.body.scrollTop = document.documentElement.scrollTop = 0;
		});
	}

	function getStates() {
		return [
			{
				state: 'LCAP3',
				config: {
					url: '/LCAP3/{formId:int}/{schoolYearId:int}', // maybe use state param for review or just check by usertype
					params: { review: null },
					templateUrl: 'app/LCAP3/lcap.index.html',
					title: 'LCAP eTemplate',
					controller: 'LCAP3Ctrl',
					allowAnonymous: false,
					resolve: {
						/* @ngInject */
						user: function (dashboardService) {
							return dashboardService.getUserFromClaims();
						},
						/* @ngInject */
						eTemplate: function (mainService, $stateParams, $state) {
							return mainService
								.getTemplate($stateParams.formId, $stateParams.schoolYearId)
								.then(function (rsp) {
									if (rsp === 'Access denied') {
										$state.go('unauthorized');
									}

									return {
										overallAnalysis: rsp.overallAnalysis,
										annualUpdateAttendance: rsp.annualUpdateAttendance,
										lcapUser: rsp.user,
										planSummary: rsp.planSummary,
										budgetOverview: rsp.budgetOverview,
										stakeholderEngagement: rsp.stakeHolder,
										unduplicatedPupils: rsp.unduplicatedPupil,
										annualUpdate: {
											goals: rsp.annualUpdate,
										},
										goalsActions: {
											goals: rsp.goals,
										},
										feedback: rsp.feedback,
									};
								});
						},
						/* @ngInject */
						lcapYears: function (dashboardService, user) {
							return dashboardService.getSchoolYears(user.lcapCycleId);
						},
					},
				},
			},
			{
				state: 'LCAP3.annualUpdate',
				config: {
					url: '/annualUpdate',
					templateUrl: 'app/LCAP3/annualUpdate/annualUpdate.index.html',
					title: 'LCAP eTemplate Annual Update',
					controller: 'AnnualUpdatePage3Ctrl', // Remove page from controller name and rename AnnualUpdatesModal controller to avoid conflictd
					allowAnonymous: false,
					resolve: {},
				},
			},
			{
				state: 'LCAP3.annualUpdateAttendance',
				config: {
					url: '/annualUpdateAttendance',
					templateUrl: 'app/LCAP3/annualUpdateAttendance/annualUpdateAttendance.index.html',
					title: 'Attendance eTemplate Annual Update',
					controller: 'AnnualUpdateAttendancePage3Ctrl',
					allowAnonymous: false,
					resolve: {},
				},
			},
			{
				state: 'LCAP3.overallAnalysis',
				config: {
					url: '/overallAnalysis',
					templateUrl: 'app/LCAP3/overallAnalysis/overallAnalysis.index.html',
					title: 'LCAP eTemplate Annual Update',
					controller: 'OverallAnalysisPage3Ctrl',
					allowAnonymous: false,
					resolve: {},
				},
			},
			{
				state: 'LCAP3.expenditures',
				config: {
					url: '/expenditures',
					templateUrl: 'app/LCAP3/expenditures/expenditures.html',
					title: 'LCAP eTemplate Expenditure Tables',
					controller: 'ExpenditureTable3Ctrl',
					allowAnonymous: false,
					resolve: {},
				},
			},
			{
				state: 'LCAP3.budgetOverview',
				config: {
					url: '/budgetOverview',
					templateUrl: 'app/LCAP3/budgetOverview/budgetOverview.index.html',
					title: 'LCAP eTemplate Budget Overview for Parents',
					controller: 'BudgetOverview3Ctrl',
					allowAnonymous: false,
					resolve: {},
				},
			},
			{
				state: 'LCAP3.goalsActions',
				config: {
					url: '/goalsActions',
					templateUrl: 'app/LCAP3/goalsActions/goalsActions.index.html',
					title: 'LCAP eTemplate Goals, Actions & Services',
					controller: 'GoalsActionsPage3Ctrl', // Remove page from controller name and rename GASModal controller to avoid conflictd
					allowAnonymous: false,
					resolve: {},
				},
			},
			{
				state: 'LCAP3.lcapFormInfo',
				config: {
					url: '/lcapFormInfo',
					templateUrl: 'app/LCAP3/lcapFormInfo/lcapFormInfo.index.html',
					title: 'LCAP eTemplate Info',
					controller: 'LcapFormInfo3Ctrl',
					allowAnonymous: false,
					resolve: {
						/* @ngInject */
						dashboardYear: function (mainService) {
							return mainService.getDashboardYear().then(function (rsp) {
								if (rsp === 5) {
									return 2020;
								} else if (rsp === 6) {
									return 2021;
								} else {
									return rsp;
								}
							});
						},
					},
				},
			},
			{
				state: 'LCAP3.planSummary',
				config: {
					url: '/planSummary',
					templateUrl: 'app/LCAP3/planSummary/planSummary.index.html',
					title: 'LCAP eTemplate Plan Summary',
					controller: 'PlanSummary3Ctrl',
					allowAnonymous: false,
					resolve: {},
				},
			},
			{
				state: 'LCAP3.printPreview',
				config: {
					url: '/printPreview',
					templateUrl: 'app/LCAP3/printPreview/printPreview.html',
					title: 'LCAP eTemplate Print Preview',
					controller: 'PrintPreview3Ctrl',
					allowAnonymous: false,
					resolve: {
						/* @ngInject */
						preview: function (previewerService, $stateParams) {
							return previewerService.getPreviewerId($stateParams.formId).then(function (rsp) {
								return rsp || '';
							});
						},
					},
				},
			},
			{
				state: 'LCAP3.submit',
				config: {
					url: '/submit-lcap',
					templateUrl: 'app/LCAP/submit/submit.template.html',
					title: 'LCAP eTemplate Submission',
					controller: 'SubmitLCAPCtrl',
					allowAnonymous: false,
					resolve: {},
				},
			},
			{
				state: 'LCAP3.stakeholderEngagement',
				config: {
					url: '/stakeholderEngagement',
					templateUrl:
						'app/LCAP3/stakeholderEngagement/stakeholderEngagement.index.html',
					title: 'LCAP eTemplate Stakeholder Engagement',
					controller: 'StakeholderEngagement3Ctrl',
					allowAnonymous: false,
					resolve: {},
				},
			},
			{
				state: 'LCAP3.increasedServices',
				config: {
					url: '/increasedServices',
					templateUrl:
						'app/LCAP3/increasedServices/increasedServices.index.html',
					title:
						'Increased or Improved Services for Foster Youth, English Learners, and Low-income Students',
					controller: 'IncreasedServices3Ctrl', // Remove page from controller name and rename GASModal controller to avoid conflictd
					allowAnonymous: false,
					resolve: {},
				},
			},
		];
	}
})();
