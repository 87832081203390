(function () {
	'use strict';

	angular
		.module('app.lcap')
		.component('printAnnualUpdateLatest4', {
			templateUrl: 'app/LCAP4/annualUpdate/print/printAnnualUpdate.template.html',
			controller: PrintAnnualUpdate4Ctrl,
			bindings: {
				eTemplate: '<',
			},
		});

	/* @ngInject */
	function PrintAnnualUpdate4Ctrl() {
		var $ctrl = this;

		$ctrl.$onInit = activate;

		/////////////////////

		function activate() {
			$ctrl.annualGoals = $ctrl.eTemplate.annualUpdateGoals;
			$ctrl.annualUpdate = $ctrl.eTemplate.annualUpdate;
		}
	}
})();
