(function () {
  'use strict';

  angular.module('app').controller('PlanSummary3Ctrl', PlanSummary3Ctrl);

  /* @ngInject */
  function PlanSummary3Ctrl(
    $rootScope,
    $timeout,
    eTemplate,
    lcapYears,
    $stateParams,
    user
  ) {
    var vm = this;

    vm.$onInit = activate;
    vm.eTemplate = eTemplate;
    vm.inReview = $stateParams.review;
    vm.lcapYears = lcapYears;
    vm.yearCycle = lcapYears[0].yearCycle;
    vm.user = user;
    vm.schoolYearId = $stateParams.schoolYearId;

    ////////////////////////////////

    function activate() {
      $timeout(checkFeedback);
    }

    function checkFeedback() {
      vm.showFeedback =
        (vm.inReview &&
          vm.eTemplate.lcapUser.currentYearStatus.statusId === 3) ||
        vm.eTemplate.planSummary.reviewStatusId != 0;
      $rootScope.$broadcast('showFeedback', vm.showFeedback);
    }
  }
})();
