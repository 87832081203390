(function () {
	'use strict';

	angular
		.module('dashboard')
		.controller('lcapYearModalController', lcapYearModalController);

	/* @ngInject */
	function lcapYearModalController($rootScope, $scope, $state, $timeout, $uibModalInstance, user, lcapYears, lcap, lookup, currentYear, mainService, leaAccess) {
		var vm = this;
		vm.activate = activate;
		vm.lookup = lookup;
		vm.user = user;
		vm.lcapYears = lcapYears;
		vm.lcap = lcap;
		vm.dismissModal = dismissModal;
		vm.currentYear = currentYear;
		vm.updateCharterOptions = updateCharterOptions;
		vm.currentYearIndex = _.indexOf(vm.lcapYears, vm.currentYear);
		vm.completedYears = [];
		vm.lcapConfig = {};
		vm.leaAccess = leaAccess;
		vm.setLea = setLea;
		vm.resetCharterYears = resetCharterYears;
		vm.customYear = false;
		vm.annualUpdateRollover = true;
		vm.saving = false;

		activate();

		function activate() {
			$timeout(function () {
				vm.form = $scope.newLCAP;
				vm.form.customValidator = validateForm;
			});

			if (vm.lcap) {
				getCompletedYears();
				getPreviousLCAP(vm.lcap);
			}
		}

		function getCompletedYears() {
			// 4 = completed lcap for that year, see key in lookup file
			if (vm.lcap.yearOneStatus === 4) {lookupStatus(1);} // 1 for yearOne
			if (vm.lcap.yearTwoStatus === 4) {lookupStatus(2);} // 2 for yearTwo
			if (vm.lcap.yearThreeStatus === 4) {lookupStatus(3);} // 3 for yearThree

			function lookupStatus(id) {
				vm.completedYears.push(_.findWhere(vm.lcapYears, { yearNumber: id }));
			}
		}

		function getPreviousLCAP(lcap) {
			mainService.hasPreviousYear(lcap.cdsCode, lcap.schoolYearId)
				.then(function (res) {
					vm.previousLcap = res;
				});
		}

		function setLea(cds) {

			var lea = _.findWhere(vm.leaAccess, { cdsCode: cds });

			if (lea.formId != 0) {lea.formId = 0;}

			mainService.addForm(lea, false, user.userId, user.userType)
				.then(function (rsp) {
					vm.lcap = rsp;
					getPreviousLCAP(vm.lcap);
				});
		}

		function validateForm() {

			if (vm.completedYears.length > 0 && vm.lcap.schoolYearId <= 4) { 
				// Rolling Over Existing LCAP for Covid Year and new cycle,
				//Needs to be updated every cycle
				vm.lcap.reviewStatusId = 0;
				vm.lcap.schoolYearId = 3;
				vm.lcap.year = '2019-20';
				vm.lcap.yearThreeStatus = 2;
				vm.lcap.yearId = 3;
				vm.lcap.statusId = 3;
			}

			else if (vm.completedYears.length === 0 && vm.form.$valid) { // Brand New LCAP
				var startYear = 0;

				// Finding and using the start year of an LCAP.
				if (!vm.lcapConfig.lcapYear) { // If no year is specified it's the current year
					vm.lcapConfig.lcapYear = vm.currentYear.yearNumber;
					startYear = parseInt(vm.lcapConfig.lcapYear); // no stringy stuff
				} else {
					vm.customYear = true;
					startYear = parseInt(vm.lcapConfig.lcapYear); // no stringy stuff
				}

				// if charterYears, parse int
				if (vm.lcapConfig.charterYears) { // no stringy stuff, ints only
					var charterYears = parseInt(vm.lcapConfig.charterYears);
					vm.lcap.charterYears = charterYears;
				}

				// Always setting form to current year info
				vm.lcap.year = vm.currentYear.year;
				vm.lcap.schoolYearId = vm.currentYear.schoolYearId;
				vm.lcap.lcapCycleId = vm.currentYear.lcapCycleId;
				vm.lcap.yearId = vm.currentYear.yearNumber;


				// Set the actual chosen year to start the lcap if not done already
				if (!vm.lcap.yearStart) {
					var yearStart = _.findWhere(vm.lcapYears, { yearNumber: startYear });
					vm.lcap.yearStart = yearStart.year;
				}

				// Only using for testing - to reset statuses without refresh - delete after testing
				// for (var i = 1; i <= 3; i++) {
				//     setYearStatus(i, 0);
				// }

				if (vm.lcap.charter != 'Y') { // logic for non charters
					if (startYear == vm.currentYear.yearNumber) { // if current year equals chosen year
						setYearStatus(vm.currentYear.yearNumber, 2); // current year is set to 2

						for (var i = 1; i < vm.currentYear.yearNumber; i++) // loop through previous years
							{if (getYearStatus(i) === 0) {setYearStatus(i, 1);}} // any previous years that aren 0 set to 1.
					}

					else if (startYear < vm.currentYear.yearNumber) { // if they choose a previous year to start
						for (var j = startYear; j <= vm.currentYear.yearNumber; j++) // loop through years ranging from the chosen year to the current year
							{setYearStatus(j, 2);} // set any of these years in between the chosen and current year to 2

						for (var k = 1; k < startYear; k++) // loop through any years before the chosen year
							{if (getYearStatus(k) === 0) {setYearStatus(k, 1);}} // set any years prior to chosen year to 1
					}
				}

				else if (vm.lcap.charter === 'Y') { // if LCAP is a charter
					if (startYear == vm.currentYear.yearNumber) { // if choosing the current year
						setYearStatus(vm.currentYear.yearNumber, 2); // current year is set to 2

						for (var l = 1; l < vm.currentYear.yearNumber; l++) // loop through previous years
							{if (getYearStatus(l) === 0) {setYearStatus(l, 1);}} // any previous years that aren 0 set to 1.

						//////////////////// The only difference between charters and non charters ////////////////////////////////////////////
						for (var m = startYear + charterYears; m <= 3; m++) // loop through future years, begin at start year plus charter years
							{if (getYearStatus(m) === 0) {setYearStatus(m, 1);}} // Any future years after the set charter LCAP years are 1
					}

					else if (startYear < vm.currentYear.yearNumber) { // if they choose a previous year to start
						setYearStatus(startYear, 2); // start year is set to 2

						if (startYear === 2 && charterYears === 2 && vm.currentYear.yearNumber === 3) {setYearStatus(3, 2);} // Found bug while testing every scenario, quick fix

						for (var n = startYear; n <= charterYears; n++)  // loop through years ranging from the chosen year to the current year
							{if (getYearStatus(n) === 0 && n <= vm.currentYear.yearNumber) {setYearStatus(n, 2);}} // set any of these years in between the chosen and current year to 2

						for (var o = 1; o < startYear; o++)  // loop through any years before the chosen year
							{if (getYearStatus(o) === 0) {setYearStatus(o, 1);}} // set any years prior to chosen year to 1

						for (var p = startYear + charterYears; p <= 3; p++)  // loop through future years, begin at start year plus charter years
							{if (getYearStatus(p) === 0) {setYearStatus(p, 1);}} // Any future years after the set charter LCAP years are 1
					}
				}
			}
			// Save LCAP
			if (vm.customYear === true) {
				var startingYear = _.findWhere(vm.lcapYears, { yearNumber: startYear });

				if (confirm('You have chosen to start this LCAP with the year ' + startingYear.year + '. Are you sure you want to start to start this LCAP on year ' + vm.lcapConfig.lcapYear + ' of the LCAP cycle?')) {
					save(vm.lcap);
				}
			} else {save(vm.lcap);}
		}

		function save(lcap) {
			vm.saving = true;
			$rootScope.loading = true;
			if (vm.completedYears.length > 0) {
				mainService.RolloverTemplate(lcap, vm.annualUpdateRollover)
					.then(function () {
						$rootScope.loading = false;
						$uibModalInstance.close(lcap);
						if (lcap.schoolYearId <= 4) {
							$state.go('LCAP.lcapFormInfo', { formId: lcap.formId, schoolYearId: lcap.schoolYearId + 1 });
						} else {
							$state.go('LCAP5.lcapFormInfo', { formId: lcap.formId, schoolYearId: lcap.schoolYearId + 1 });
						}
					});
				} else {
					mainService.addForm(lcap, vm.annualUpdateRollover, user.userId, user.userType)
					.then(function () {
						$rootScope.loading = false;
						$uibModalInstance.close(lcap);
						if (lcap.schoolYearId <= 4) {
							$state.go('LCAP.lcapFormInfo', { formId: lcap.formId, schoolYearId: lcap.schoolYearId });
						} else {
							$state.go('LCAP5.lcapFormInfo', { formId: lcap.formId, schoolYearId: lcap.schoolYearId });
						}
					});
			}
		}

		function getYearStatus(year) {
			if (year === 1) {return vm.lcap.yearOneStatus;}
			else if (year === 2) {return vm.lcap.yearTwoStatus;}
			else if (year === 3) {return vm.lcap.yearThreeStatus;}
		}

		function setYearStatus(year, status) {
			if (year === 1) {vm.lcap.yearOneStatus = status;}
			else if (year === 2) {vm.lcap.yearTwoStatus = status;}
			else if (year === 3) {vm.lcap.yearThreeStatus = status;}
		}

		function dismissModal() {
			$uibModalInstance.close();
		}

		function resetCharterYears() {
			// Reset on year change to avoid bugs
			if (vm.lcap.charter === 'Y') {vm.lcapConfig.charterYears = '1';}
		}

		function updateCharterOptions(option) {
			if (vm.lcapConfig.lcapYear) {var startAt = _.findWhere(vm.lcapYears, { yearNumber: parseInt(vm.lcapConfig.lcapYear) });}
			if (!startAt || startAt.yearNumber === 1 || option.value + startAt.yearNumber <= 4) {return true;}
			else {return false;}
		}
	}
})();
