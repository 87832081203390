(function () {
	'use strict';

	angular
		.module('app.lcap')
		.component('videoButton', {
			templateUrl: 'app/LCAP/shared/videos/button/videoButton.template.html',
			controller: videoButtonComponent,
			transclude: true,
			bindings: {
				videoId: '<',
				title: '<',
				spanish: '<?',
			},
		});

	/* @ngInject */
	function videoButtonComponent($uibModal) {
		var $ctrl = this;
		$ctrl.openVideo = openVideo;

		/////////////////////////////

		function openVideo() {
			$uibModal.open({
				controller: 'videoModalCtrl',
				controllerAs: 'vm',
				size: 'lg',
				templateUrl: 'app/LCAP/shared/videos/videosModal/index.html',
				windowTopClass: 'instructions-modal',
				resolve: {
					videoId: function () {
						return $ctrl.videoId;
					},
					title: function () {
						return $ctrl.title;
					},
					spanish: function () {
						return $ctrl.spanish;
					},
				},
			});
		}
	}
})();
