(function () {
	'use strict';

	angular
		.module('services')
		.factory('modalService', modalService);

	function modalService() {
		var service = {};

		return service;

		//////////////////////

		///********* EVENTUALLY WILL BE USED TO CLEAN ANNUAL UPDATES/GOALS ACTIONS SERVCIES CODE ***************///
	}
})();
