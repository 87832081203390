(function () {
	'use strict';

	angular
		.module('app')
		.controller('InstructionsCtrl', instructionsCtrl);

	/* @ngInject */
	function instructionsCtrl($uibModalInstance) {
		var vm = this;
		vm.closeModal = closeModal;

		//////////////////////////////

		function closeModal(e) {
			e.preventDefault();
			$uibModalInstance.dismiss('cancel');
		}
	}
})();
