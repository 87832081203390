(function () {
	'use strict';

	angular.module('app').controller('PrintPreviewCtrl', PrintPreviewCtrl);

	PrintPreviewCtrl.$inject = ['preview', 'previewerService', 'ngClipboard', '$scope', 'validationFactory'];

	function PrintPreviewCtrl(preview, previewerService, ngClipboard, $scope, validationFactory) {
		var vm = this;
		vm.toClipboard = ngClipboard.toClipboard;
		vm.$onInit = activate;
		vm.copyUrl = copyUrl;
		vm.setPreviewPassword = setPreviewPassword;
		vm.canSetPreviewPassword = false;
		vm.isPasswordRequired = false;
		vm.status = '';
		activate();

		function setPreviewUrl(guid) {
			if (guid !== '' && guid !== '00000000-0000-0000-0000-000000000000') {
				preview.Url = window.location.origin + '/reports/' + guid;
			}
		}

		function activate() {
			vm.preview = preview;

			$scope.$watch('vm.preview.Password', function (newValue, oldValue) {
				if (oldValue === newValue) {
					vm.isPasswordRequired = false;
					validationFactory.setGlyph($('#password'), true); // valid input styles
					vm.canSetPreviewPassword = true;
					return;
				}

				if (newValue === '' && vm.isPasswordRequired) {
					validationFactory.setGlyph($('#password'), false); // valid input styles
					vm.canSetPreviewPassword = false;
				}
				else {
					validationFactory.setGlyph($('#password'), true); // valid input styles
					vm.canSetPreviewPassword = true;
				}
			});
			$scope.$watch('vm.preview.IsAccessible', function (newValue, oldValue) {
				// vm.isPasswordRequired = newValue;
				if (oldValue === newValue) {
					vm.isPasswordRequired = false;
					validationFactory.setGlyph($('#password'), true); // valid input styles
					vm.canSetPreviewPassword = true;
					return;
				}
				if (newValue) {
					validationFactory.setGlyph($('#password'), false); // valid input styles
					vm.canSetPreviewPassword = false;
				}
				else {
					validationFactory.setGlyph($('#password'), true); // valid input styles
					vm.canSetPreviewPassword = true;
				}

			});

			vm.isPasswordRequired = !preview.IsAccessible;
			setPreviewUrl(preview.PreviewerId);
		}

		function copyUrl() {
			/* Get the text field */
			vm.toClipboard(vm.preview.Url);
			vm.status = 'Copied Report URL Successfully!';
		}

		function setPreviewPassword() {
			previewerService.setPreviewerPassword(vm.preview).then(function (rsp) {
				vm.status = 'Set Successfully!';
				setPreviewUrl(rsp);
			});
		}
	}
})();
