(function () {
	'use strict';

	angular
		.module('services')
		.factory('mainService', mainService);

	function mainService(
		dataservice,
		simpleStorageService,
		$stateParams
	) {

		var service = {
			getUser: getUser,
			addForm: addForm,
			getPlanSummary: getPlanSummary,
			addPlanSummary: addPlanSummary,
			getStakeholder: getStakeholder,
			addStakeholder: addStakeholder,
			getPupil: getPupil,
			addPupil: addPupil,
			getGAS: getGAS,
			getGASGoals: getGASGoals,
			getAnnualUpdates: getAnnualUpdates,
			getAnnualUpdateGoals: getAnnualUpdateGoals,
			addAnnualUpdate: addAnnualUpdate,
			addAnnualUpdateGoals: addAnnualUpdateGoals,
			getTemplate: getTemplate,
			deleteAUGoal: deleteAUGoal,
			deleteGASGoal: deleteGASGoal,
			addGASGoal: addGASGoal,
			deleteGASOutcome: deleteGASOutcome,
			deleteGASAction: deleteGASAction,
			deleteAUAction: deleteAUAction,
			deleteAUOutcome: deleteAUOutcome,
			getDashboardYear: getDashboardYear,
			addFeedback: addFeedback,
			getFeedback: getFeedback,
			deleteFeedback: deleteFeedback,
			generatePdf: generatePdf,
			submitLCAP: submitLCAP,
			updateLCAPStatus: updateLCAPStatus,
			RolloverTemplate: RolloverTemplate,
			addBudgetOverview: addBudgetOverview,
			getTemplateDetails: getTemplateDetails,
			getOverallAnalysis: getOverallAnalysis,
			addOverallAnalysis: addOverallAnalysis,
			getAnnualUpdateAttendance: getAnnualUpdateAttendance,
			addAnnualUpdateAttendance: addAnnualUpdateAttendance,
			addAnnualUpdateSupplement: addAnnualUpdateSupplement,
			deleteAnnualUpdateAttendanceAction: deleteAnnualUpdateAttendanceAction,
			hasPreviousYear: hasPreviousYear,
			// saveGoalType: saveGoalType
		};

		return service;
		function getTemplateDetails(previewerDetails) {
			return dataservice.postData('template/GetTemplateDetails', previewerDetails);
		}
		// function saveGoalType(goalTypeDetails) {
		//   return dataservice.postData("template/SaveGoalType", goalTypeDetails);
		// }
		function getUser() {
			return dataservice.getData('template/GetUser');
		}

		function addForm(form, annualUpdateRollover, modifyerUserId, modifyerTitle) {
			annualUpdateRollover = annualUpdateRollover == undefined ? false : annualUpdateRollover;
			updateHistory({}); // Send empty object will only send essentials
			return dataservice.postData('template/AddForm/'+ annualUpdateRollover+'/'+modifyerUserId+'/'+modifyerTitle, form);
		}

		function RolloverTemplate(form, annualUpdateRollover) {
			updateHistory({});
			return dataservice.postData('template/RolloverTemplate/' + annualUpdateRollover, form);
		}

		function getPlanSummary(formId) {
			return dataservice.getData('template/GetPlanSummary/' + formId);
		}

		function addPlanSummary(plan) {
			updateHistory({ planSummaryId: plan.planSummaryId });
			return dataservice.postData('template/AddPlanSummary', plan);
		}

		function getStakeholder(formId) {
			return dataservice.getData('template/GetStakeholder/' + formId);
		}

		function addStakeholder(stake) {
			updateHistory({ stakeholderId: stake.stakeholderId });
			return dataservice.postData('template/AddStakeholder', stake);
		}

		function getPupil(formId, schoolYearId) {
			return dataservice.getData(
				'template/GetPupil/' + formId + '/' + schoolYearId
			);
		}

		function addPupil(pupil) {
			updateHistory({ pupilId: pupil.pupilId });
			return dataservice.postData('template/AddPupil', pupil);
		}

		function getAnnualUpdates(formId, schoolYearId) {
			return dataservice.getData(
				'template/GetAnnualUpdates/' + formId + '/' + schoolYearId
			);
		}

		function getAnnualUpdateGoals(formId, schoolYearId, goalId) {
			return dataservice.getData(
				'template/GetAnnualUpdateGoals/' +
				formId +
				'/' +
				schoolYearId +
				'/' +
				goalId
			);
		}

		function getGAS(formId, schoolYearId, goalId) {
			return dataservice.getData(
				'template/GetGASGoal/' + formId + '/' + schoolYearId + '/' + goalId
			);
		}

		function getGASGoals(formId, schoolYearId) {
			return dataservice.getData(
				'template/GetGASGoals/' + formId + '/' + schoolYearId + '/true'
			);
		}

		function addAnnualUpdate(update) {		
			return dataservice.postData('template/AddAnnualUpdate', update);
		}

		function addAnnualUpdateGoals(goals) {
			updateHistory({ auGoalId: goals.goalId, deletedStatusId: 1 });
			return dataservice.postData('template/AddAnnualUpdateGoals/false', goals);
		}

		function getTemplate(formId, schoolYearId, userType, userId) {
			if (userType && userId) {
				return dataservice.getData(
					'template/GetTemplate/' +
					formId +
					'/' +
					schoolYearId +
					'/' +
					userType +
					'/' +
					userId
				);
			} else {
				return dataservice.getData(
					'template/GetTemplate/' + formId + '/' + schoolYearId
				);
			}
		}

		function deleteAUGoal(goalId) {
			updateHistory({ auGoalId: goalId, deletedStatusId: 2 });
			return dataservice.postData('template/DeleteAUGoal/' + goalId);
		}

		function deleteGASGoal(goalId) {
			updateHistory({ gasGoalId: goalId, deletedStatusId: 2 });
			return dataservice.postData('template/DeleteGASGoal/' + goalId);
		}

		function addGASGoal(goal) {
			updateHistory({ gasGoalId: goal.goalId, deletedStatusId: 1 });
			return dataservice.postData('template/AddGASGoal', goal);
		}

		function deleteGASOutcome(outcomeId) {
			return dataservice.postData(
				'template/DeleteGASExpectedOutcome/' + outcomeId
			);
		}

		function deleteGASAction(actionId) {
			return dataservice.postData('template/DeleteGASAction/' + actionId);
		}

		function deleteAUAction(actionId) {
			return dataservice.postData('template/DeleteAUAction/' + actionId);
		}

		function deleteAUOutcome(id) {
			return dataservice.postData('template/DeleteAUOutcome/' + id);
		}

		function getDashboardYear() {
			return dataservice.getData('template/GetCurrentSchoolYear');
		}

		function addFeedback(feedback) {
			return dataservice.postData('template/AddFeedback', feedback);
		}

		function getFeedback(formId, sectionId, schoolYearId) {
			return dataservice.getData(
				'template/GetFeedbackBySection/' +
				formId +
				'/' +
				sectionId +
				'/' +
				schoolYearId
			);
		}

		function deleteFeedback(commentId) {
			return dataservice.postData('template/DeleteFeedback/' + commentId);
		}

		function generatePdf(urls) {
			return dataservice.postPDFData('template/GeneratePDF', urls);
		}

		function submitLCAP(form) {
			return dataservice.postData('template/SubmitLCAP', form);
		}

		function updateLCAPStatus(form) {
			return dataservice.postData('template/UpdateLCAPStatus', form);
		}

		function addBudgetOverview(budget) {
			// updateHistory({planSummaryId: plan.planSummaryId});
			return dataservice.postData('template/AddBudgetOverview', budget);
		}

		function getOverallAnalysis(formId, schoolYearId) {
			return dataservice.getData('template/GetOverallAnalysis/' + formId + '/' + schoolYearId);
		}

		function addOverallAnalysis(form) {
			return dataservice.postData('template/AddOverallAnalysis', form);
		}

		function getAnnualUpdateAttendance(formId, schoolYearId) {
			return dataservice.getData('template/GetAnnualUpdateAttendance/' + formId + '/' + schoolYearId);
		}

		function addAnnualUpdateAttendance(form) {
			return dataservice.postData('template/AddAnnualUpdateAttendance', form);
		}

		function addAnnualUpdateSupplement(form) {
			return dataservice.postData('template/AddAnnualUpdateSupplement', form);
		}

		function deleteAnnualUpdateAttendanceAction(actionId) {
			return dataservice.postData('template/DeleteAnnualUpdateAttendanceAction/' + actionId);
		}

		function hasPreviousYear(cdsCode, currentYearId) {
			return dataservice.getData('template/hasPreviousYear/' + cdsCode + '/' + currentYearId);
		}

		///////////////////////////////////////////////////////////////
		//                   Historical LCAP Update                 //
		///////////////////////////////////////////////////////////////

		function updateHistory(history) {
			var user = simpleStorageService.getItem('user');
			history.formId = $stateParams.formId; // required
			history.schoolYearId = $stateParams.schoolYearId; // required
			history.userId = user != null ? user.userId : -1; // required

			return dataservice.postData('template/UpdateHistory', history);
		}
	}
})();
