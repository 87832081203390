(function () {
	'use strict';

	angular
		.module('dashboard')
		.controller('changePasswordController', changePasswordController);

	/* @ngInject */
	function changePasswordController($uibModalInstance, $scope, $timeout, user, validationFactory, dashboardService) {
		var vm = this;

		vm.$onInit = activate;
		vm.password = {
			new: '',
			confirm: '',
		};
		vm.showError = false;
		vm.dismissModal = dismissModal;
		vm.checkMatchup = checkMatchup;

		/////////////////////

		function activate() {
			$timeout(function () {
				vm.form = $scope.changePassword;
				vm.form.customValidator = validate;
			});
		}

		function validate() {
			if (vm.password.new === vm.password.confirm && vm.password.new.length > 0) {
				user.password = vm.password.confirm;

				dashboardService.updatePassword(user)
					.then(function () {
						$uibModalInstance.close();
					}).catch(function (e) {
						toastr.error(e.data);
					});
			} else {vm.showError = true;}
		}

		function checkMatchup() {
			var equal = vm.password.new === vm.password.confirm;
			var confirmLength = vm.password.confirm.length > 0;

			if (equal === true && confirmLength) {
				validationFactory.setGlyph($('#confirmPassword'), true); // valid input styles
				vm.showError = false;
			} else if (equal === false && confirmLength) {
				validationFactory.setGlyph($('#confirmPassword'), false); // invalid input styles
				vm.showError = true;
			} else {
				validationFactory.setGlyph($('#confirmPassword')); // remove input styles
				vm.showError = false;
			}
		}

		function dismissModal() {
			$uibModalInstance.dismiss();
		}
	}
})();
