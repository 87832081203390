(function () {
	'use strict';

	angular
		.module('app')
		.controller('Reports202122Ctrl', Reports202122Ctrl);

	/* @ngInject */
	function Reports202122Ctrl($filter, $rootScope, $timeout, printDetails, lcapYears, mainService, userType) {
		var vm = this;

		vm.$onInit = activate;
		vm.viewReport = viewReport;
		vm.lcapYears = lcapYears;
		vm.eTemplate = {};
		vm.password = '';

		function activate() {
			printDetails.contributingExpenditures[0] = angular.extend({}, printDetails.contributingExpenditures[0]);
			var expenditure = printDetails.contributingExpenditures[0];
			expenditure['LEA-Wide LCFF Total'] = (expenditure['LEA-Wide LCFF Total'] || '$0.00');
			expenditure['Targeted LCFF Total'] = (expenditure['Targeted LCFF Total'] || '$0.00');
			expenditure['Schoolwide LCFF Total'] = (expenditure['Schoolwide LCFF Total'] || '$0.00');
			expenditure['LEA-Wide Total'] = (expenditure['LEA-Wide Total'] || '$0.00');
			expenditure['Targeted Total'] = (expenditure['Targeted Total'] || '$0.00');
			expenditure['Schoolwide Total'] = (expenditure['Schoolwide Total'] || '$0.00');

			var currencyFilter = $filter('currency');
			var totalExpenditures = printDetails.totalExpenditureDetails;
			printDetails.totalPersonnelExpendituresCurrency = currencyFilter(getTotalPersonnel(totalExpenditures));
			printDetails.totalNonPersonnelExpendituresCurrency = currencyFilter(getTotalNonPersonnel(totalExpenditures));

			printDetails.totalLcffFundsCurrency = currencyFilter(getLcffTotals(expenditure));
			printDetails.totalFundsCurrency = currencyFilter(getTotalFunds(expenditure));

			vm.printDetails = printDetails;
			vm.isDataTableVisible = printDetails.IsDataTableVisible;
			vm.section = 'all';
			vm.landscape = vm.section === 'all' || vm.section === 'landscape';
			vm.portrait = vm.section === 'all' || vm.section === 'portrait';
			vm.isAccessible = (
				printDetails.IsAccessibleToPublic ||
				printDetails.IsAccessible === false || // despite the name, IsAccessible means IsPasswordProtected
				userType === 'Admin' ||
				userType === 'Reviewer'
			);

			if (vm.isAccessible) {
				$timeout(viewReport());
			}
		}

		function getLcffTotals(expenditure) {
			var leaWideLcffTotal = expenditure['LEA-Wide LCFF Total'].replace(/[$|,]*/g, '');
			var targetedlcfftotal = expenditure['Targeted LCFF Total'].replace(/[$|,]*/g, '');
			var schoolwideLcffTotal = expenditure['Schoolwide LCFF Total'].replace(/[$|,]*/g, '');
			return parseFloat(leaWideLcffTotal) + parseFloat(targetedlcfftotal) + parseFloat(schoolwideLcffTotal);
		}

		function getTotalFunds(expenditure) {
			var leaWideLcffTotal = expenditure['LEA-Wide Total'].replace(/[$|,]*/g, '');
			var targetedlcfftotal = expenditure['Targeted Total'].replace(/[$|,]*/g, '');
			var schoolwideLcffTotal = expenditure['Schoolwide Total'].replace(/[$|,]*/g, '');
			return parseFloat(leaWideLcffTotal) + parseFloat(targetedlcfftotal) + parseFloat(schoolwideLcffTotal);
		}

		function getTotalPersonnel(collection) {
			return Array.isArray(collection) && collection.length > 0
				? _.uniq(
					collection.map(function (x) {
						return parseFloat(x.TotalPersonnel.replace(/[$|,]*/g, '') || '0');
					})
				).reduce(function (x, y) {
					return x + y;
				})
				: 0.0;
		}

		function getTotalNonPersonnel(collection) {
			return Array.isArray(collection) && collection.length > 0
				? _.uniq(
					collection.map(function (x) {
						return parseFloat(x.TotalNonPersonnel.replace(/[$|,]*/g, '') || '0');
					})
				).reduce(function (x, y) {
					return x + y;
				})
				: 0.0;
		}

		function viewReport() {
			$rootScope.loading = true;
			printDetails.password = vm.password;

			mainService.getTemplateDetails(printDetails)
				.then(function (rsp) {
					if (rsp.data !== false) {
						vm.isAccessible = true;
						vm.eTemplate = rsp;

						$timeout(function () {
							$('footer').hide();
							$('header').hide();
						});
					} else {
						alert('Invalid password');
					}
				})
				.finally(function () {
					$rootScope.loading = false;
				});
		}
	}
})();
