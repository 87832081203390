(function () {
	'use strict';

	angular
		.module('app')
		.controller('ReportsSupplementCtrl', ReportsSupplementCtrl);

	/* @ngInject */
	function ReportsSupplementCtrl($filter, $rootScope, $timeout, printDetails, lcapYears, mainService, userType) {
		var vm = this;

		vm.$onInit = activate;
		vm.viewReport = viewReport;
		vm.lcapYears = lcapYears;
		vm.eTemplate = {};
		vm.password = '';

		function activate() {
			vm.printDetails = printDetails;
			vm.section = 'all';
			vm.isAccessible = (
				printDetails.IsAccessibleToPublic ||
				printDetails.IsAccessible === false || // despite the name, IsAccessible means IsPasswordProtected
				userType === 'Admin' ||
				userType === 'Reviewer'
			);

			if (vm.isAccessible) {
				$timeout(viewReport());
			}
		}

		function viewReport() {
			$rootScope.loading = true;
			printDetails.password = vm.password;

			mainService.getTemplateDetails(printDetails)
				.then(function (rsp) {
					if (rsp.data !== false) {
						vm.isAccessible = true;
						vm.eTemplate = rsp;

						$timeout(function () {
							$('footer').hide();
							$('header').hide();
						});
					} else {
						alert('Invalid password');
					}
				})
				.finally(function () {
					$rootScope.loading = false;
				});
		}
	}
})();
