(function () {
	'use strict';

	angular.module('app.lcap').component('printIncreasedServices5', {
		templateUrl:
			'app/LCAP5/increasedServices/print/printIncreasedServices.template.html',
		controller: PrintIncreasedServices4,
		bindings: {
			eTemplate: '<',
			years: '<',
		},
	});

	/* @ngInject */
	function PrintIncreasedServices4() {
		var $ctrl = this;

		$ctrl.$onInit = activate;

		/////////////////////

		function activate() {
			$ctrl.pupil = $ctrl.eTemplate.unduplicatedPupil;
			$ctrl.currentYear = _.findWhere($ctrl.years, { isCurrentYear: true });
		}
	}
})();
