(function () {
	'use strict';

	angular
		.module('app')
		.controller('UnauthorizedCtrl', UnauthorizedCtrl);

	function UnauthorizedCtrl() {
	}
})();
