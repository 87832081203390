(function () {
	'use strict';

	angular
		.module('app')
		.controller('GoalsActionsServicesCtrl', goalsActionsServicesCtrl);

	/* @ngInject */
	function goalsActionsServicesCtrl($scope, $rootScope, $uibModalInstance, $stateParams, $timeout, $ngBootbox,
		$anchorScroll, $location, validationFactory, editableGoal, goalIndex, mainService, lookup, lcapYears, eTemplate, user) {
		var vm = this;

		vm.$onInit = activate;
		vm.lookup = lookup;
		vm.dismissModal = dismissModal;
		vm.eTemplate = eTemplate;
		vm.newGoal = {};
		vm.newOutcome = {};
		vm.newAction = { isOpen: true, isComplete: 1, isSubmitted: false, contributing: null };
		vm.deleteOutcome = deleteOutcome;
		vm.addOutcome = addOutcome;
		vm.addAction = addAction;
		vm.deleteAction = deleteAction;
		vm.saveGoal = saveGoal;
		vm.checkPriorities = checkPriorities;
		vm.accordion = accordion;
		vm.goalIndex = goalIndex;
		vm.inReview = $stateParams.review;
		vm.lcapYears = lcapYears;
		vm.user = user;
		vm.closeModal = closeModal;
		vm.checkNew = checkNew;
		vm.eoRequired = {
			yearOne: { yearNumber: 1, required: true },
			yearTwo: { yearNumber: 2, required: true },
			yearThree: { yearNumber: 3, required: true },
		};
		vm.loading = true;

		$scope.$on('loginChanged', function () { // Closes Modal on autologout
			$uibModalInstance.dismiss('cancel');
		});

		function activate() {
			$timeout(function () {
				vm.form = $scope.goalsActionsServicesGoal; // Form Object
				vm.form.customValidator = saveGoal; // Custom Validator

				$rootScope.$broadcast('elastic:adjust'); // Adjust textarea heights

				// $('#accordion').sortable({ // jQuery UI sortable
				//     items: ".panel:not(.unsortable)",
				//     update: updateSortOrder
				// });

				// $(".sortable").disableSelection(); // Disable the items with class 'unsortable'
			});

			setupGoals();
		}

		function disableYears() {
			vm.disableYearOne = vm.eTemplate.lcapUser.yearOneStatus != 2 && vm.eTemplate.lcapUser.yearOneStatus != 0;
			vm.disableYearTwo = vm.eTemplate.lcapUser.yearTwoStatus != 2 && vm.eTemplate.lcapUser.yearTwoStatus != 0;
			vm.disableYearThree = vm.eTemplate.lcapUser.yearThreeStatus != 2 && vm.eTemplate.lcapUser.yearThreeStatus != 0;
		}

		function checkNew() {
			if (vm.newGoal.details.goalStatus === 'New') {
				var currentYear = vm.eTemplate.lcapUser.currentYear.yearNumber;

				_.each(vm.eoRequired, function (item) {
					if (item.yearNumber < currentYear) {item.required = false;}
					else {item.required = true;}
				});
			} else {
				_.each(vm.eoRequired, function (item) {
					item.required = true;
				});
			}
		}

		// function updateSortOrder(event, ui) {
		//     var $panel = $(this).find('.panel');

		//     _.each($panel, function(item, i) {
		//         $(item).data().$scope.action.sortOrder = i; // Set sortOrder
		//     });

		//     $timeout(sortGoals);
		// }

		function accordion(action, index, form, save) {
			$location.hash('accordion'); // anchor action to top of page
			$anchorScroll(); // anchor action to top of page
			form = form['actionForm' + index]; // current ng-form
			vm.actionToSave = action; // Used for save function
			vm.actionToSaveIndex = index; // Used for save function

			$timeout(function () {
				$rootScope.$broadcast('elastic:adjust'); // Adjust textarea heights
			});

			if (save) { // Saving Actions
				action.isSubmitted = true;
				form.change = false;
				form.customValidator = saveAction;
				validationFactory.validateForm(form);
			} else { // Not Saving, just navigating actions
				_.each(vm.newGoal.actions, function (item) {
					// if (i != index) item.isOpen = false;
					// else item.isOpen = !item.isOpen;
					if (item != action) {item.isOpen = false;}
					else {item.isOpen = !item.isOpen;}
				});
			}
		}

		function saveAction(form) {
			vm.savingAction = true;
			validateContributions(vm.actionToSave, vm.actionToSaveIndex, form);
			// Valid Action
			if (form.$valid) {
				vm.actionToSave.isComplete = 3;
				vm.actionToSave.isOpen = false;
			}

			// Invalid Action
			else if (form.$invalid) {
				if (confirm('Action #' + (vm.actionToSaveIndex + 1) + ' is incomplete. Would you like to continue?')) {
					vm.actionToSave.isComplete = 2;
					vm.actionToSave.isOpen = false;
				}
				$rootScope.modalOpen = true;
			}
		}

		function addAction() {
			if (vm.newGoal.actions.length)
				{_.each(vm.newGoal.actions, function (item) { item.isOpen = false; });}

			var newAction = angular.copy(vm.newAction);
			newAction.sortOrder = vm.newGoal.actions.length;

			vm.newGoal.actions.push(newAction);
		}

		function deleteAction(index, actionId) { // Delete and sort actions
			$rootScope.modalOpen = true;
			if (confirm('Are you sure you want to delete Action #' + (index + 1) + '?')) {
				if (actionId != null) {mainService.deleteGASAction(actionId);}

				vm.newGoal.actions.splice(index, 1);
				$timeout(sortGoals);
			}
		}

		function sortGoals() {
			vm.newGoal.actions = _.sortBy(vm.newGoal.actions, 'sortOrder');

			$timeout(function () {
				_.each(vm.newGoal.actions, function (item, i) { // set new sort order on delete
					item.sortOrder = i;
				});
			});
		}

		function checkPriorities(form) {
			if (!vm.savingAction && vm.newGoal) {return validationFactory.validatePriorities(vm.newGoal.details, form);}
		}

		function deleteOutcome(index, outcomeId) {
			$rootScope.modalOpen = true;
			if (confirm('Are you sure you want to delete Outcome #' + (index + 1) + '?')) {
				if (outcomeId != null) {mainService.deleteGASOutcome(outcomeId);}
				vm.newGoal.expectedOutcomes.splice(index, 1);
			}
		}

		function addOutcome() {
			vm.newGoal.expectedOutcomes.push(vm.newOutcome);
			vm.newOutcome = {};
		}

		function checkActions() { // Checking/Validating Actions
			vm.actions = {
				complete: _.size(_.filter(vm.newGoal.actions, function (item) {
					return item.isComplete === 3;
				})),
				incomplete: _.size(_.filter(vm.newGoal.actions, function (item) {
					return item.isComplete === 2;
				})),
				draft: _.size(_.filter(vm.newGoal.actions, function (item) {
					return item.isComplete === 1;
				})),
				valid: function () {
					return this.complete >= 1 && this.incomplete === 0 && this.draft === 0 ? true : false;
				},
				invalid: function () {
					return this.incomplete != 0 || this.draft != 0 || this.complete === 0 ? true : false;
				},
			};
		}

		function checkFeedback() {
			vm.showFeedback = (vm.inReview && vm.eTemplate.lcapUser.currentYearStatus.statusId === 3) || (vm.newGoal.details.reviewStatusId != 0 && vm.newGoal.details.goalId);
		}

		function saveGoal(form, status) { // Saving and Validation
			vm.savingAction = false;
			checkActions();

			$timeout(function () {
				var saveDraft = status === false && vm.newGoal.details != null && vm.newGoal.details.goal;
				var formValid = form.$valid && checkPriorities(form) && vm.actions.valid && vm.newGoal.expectedOutcomes.length > 0;
				var formInvalid = form.$invalid || !checkPriorities(form) || (vm.actions.invalid && vm.actions.draft === 0) || vm.newGoal.expectedOutcomes.length === 0;
				var goalDetailsRequired = !vm.newGoal.details || !vm.newGoal.details.goal;
				var unsavedActions = vm.actions.draft >= 1 && vm.newGoal.actions.length != 0;

				if (saveDraft) {closeModal(false);} // Save as draft
				else if (formValid) {
					if (vm.newGoal.details.reviewStatusId === 2) {
						$ngBootbox.customDialog(getReviewEditModal());
					} else if (vm.newGoal.details.reviewStatusId === 3) {
						if (confirm(lookup.ERROR_MESSAGES.APPROVED_REVIEW_CHANGE)) {
							vm.newGoal.details.reviewStatusId = 1; // Reset for re-review
							closeModal(true); // Save Valid Form
						}
					} else {
						closeModal(true); // Save Valid Form
					}
				}
				else if (formInvalid) { // Invalid Form
					if (goalDetailsRequired) { // Goal details are required
						alert(vm.lookup.ERROR_MESSAGES.GOAL_DESCRIPTION);
						$rootScope.modalOpen = true;
					} else if (unsavedActions) { // Unsaved Actions Error
						$rootScope.modalOpen = true;
						alert(vm.lookup.ERROR_MESSAGES.UNSAVED_ACTIONS);
					} else { // Save incomplete goal as draft
						if (confirm(vm.lookup.ERROR_MESSAGES.INCOMPLETE_GOAL)) {
							closeModal(false);
						} else {
							$rootScope.modalOpen = true;
						}
					}
				}
			});
		}

		function closeModal(status) { // Closing modal and saving changes
			sortGoals();

			$timeout(function () {
				vm.newGoal.details.status = status;
				$uibModalInstance.close(vm.newGoal, vm.form);
				$rootScope.modalOpen = false;
			});
		}

		function getReviewEditModal() {
			var modal = {
				message: lookup.ERROR_MESSAGES.REQUESTED_REVIEW_CHANGES,
				buttons: {
					danger: {
						label: 'Still Working',
						className: 'btn-warning',
						callback: function () {
							vm.newGoal.details.reviewStatusId = 2;
							closeModal(true);
						},
					},
					success: {
						label: 'Ready for Review',
						className: 'btn-success',
						callback: function () {
							vm.newGoal.details.reviewStatusId = 1;
							closeModal(true);
						},
					},
				},
			};

			return modal;
		}

		function dismissModal() { // Cancelling modal and all changes
			if (!vm.inReview) {
				if (confirm(vm.lookup.ERROR_MESSAGES.LOST_CHANGES)) {
					$uibModalInstance.dismiss('cancel');
					$rootScope.modalOpen = false;
				} else {
					$rootScope.modalOpen = true;
				}
			} else {$uibModalInstance.dismiss('cancel');}
		}

		function setupGoals() {
			if (editableGoal) {
				mainService.getGAS(editableGoal.details.formId, editableGoal.details.schoolYearId, editableGoal.details.goalId)
					.then(function (rsp) {
						vm.newGoal = angular.copy(rsp);
						vm.newGoal.reviewStatusId = vm.newGoal.details.reviewStatusId;
						vm.newGoal.sortOrder = vm.newGoal.details.sortOrder;

						_.each(vm.newGoal.actions, function (item) {
							item.isOpen = false;
							item.isSubmitted = false;
						});

						checkFeedback();
						disableYears();
						checkNew();
						sortGoals();

						var goalIndex = _.findIndex(vm.eTemplate.goalsActionsServices.goals, { goalId: vm.newGoal.goalId });
						vm.eTemplate.goalsActionsServices.goals[goalIndex] = vm.newGoal;
						vm.loading = false;
					})
					.catch(function () {
						toastr.error('Error opening goal, refresh and try again');
						$uibModalInstance.dismiss('cancel');
						vm.loading = false;
					});
			} else {
				vm.newGoal = {
					expectedOutcomes: [{}],
					actions: [],
					details: {
						reviewStatusId: vm.eTemplate.goalsActionsServices.reviewStatusId != 0 ? 1 : 0,
					},
				};

				checkFeedback();
				disableYears();
				checkNew();
				sortGoals();
				vm.loading = false;
			}
		}

		// Don't judge me becaues I'm ugly
		function validateContributions(action, index, form) {
			if (action.contributing === false) {
				// action.metStudents = null;
				// action.metScopeOfServices = null;
				// action.metLocationsAnswer = null;
				// action.metLocationsInput = null;

				// action.metEnglishLearners = false;
				// action.metFosterYouth = false;
				// action.metLowIncome = false;

				// if (action.notMetSpecificStudentGroups === false) action.notMetStudentsInput = null;
				// else if (action.notMetLocationsAnswer === 'All Schools') action.notMetLocationsInput = null;

				if (action.notMetAllStudents === false &&
					action.notMetStudentsWithDisabilities === false &&
					action.notMetSpecificStudentGroups === false) {
					form['notContributingStudents' + (index + 1)].$setValidity('requred', false);
				} else {form['notContributingStudents' + (index + 1)].$setValidity('requred', true);}
			} else if (action.contributing === true) {
				// action.notMetStudentsAnswer = null;
				// action.notMetStudentsInput = null;
				// action.notMetLocationsAnswer = null;
				// action.notMetLocationsInput = null;

				// action.notMetAllStudents = false;
				// action.notMetStudentsWithDisabilities = false;
				// action.notMetSpecificStudentGroups = false;

				// if (action.metLocationsAnswer === 'All Schools') action.metLocationsInput = null;
				if (action.metEnglishLearners === false &&
					action.metFosterYouth === false &&
					action.metLowIncome === false) {
					form['ContributingStudents' + (index + 1)].$setValidity('requred', false);
				} else {form['ContributingStudents' + (index + 1)].$setValidity('requred', true);}
			}
		}
	}
})();
