(function () {
	'use strict';

	angular
		.module('services')
		.factory('previewerService', previewerService);

	function previewerService(dataservice) {
		var service = {
			getPreviewDetails: getPreviewDetails,
			getPreviewDetailsBudget: getPreviewDetailsBudget,
			getPreviewerId: getPreviewerId,
			setPreviewerPassword: setPreviewerPassword,
			previewPrint: previewPrint,
			getDataEntryTable: getDataEntryTable,
			getTotalExpenditures: getTotalExpenditures,
			getContributingExpenditures: getContributingExpenditures,
			getAnnualUpdateExpenditures: getAnnualUpdateExpenditures,
			getAnnualUpdateContributingActionsExpenditures: getAnnualUpdateContributingActionsExpenditures,
			getLCFFCarryoverExpenditures: getLCFFCarryoverExpenditures,
			getPrintPreviews: getPrintPreviews,
		};
		return service;
		function getPreviewDetails(guid) {
			return dataservice.getData('Previewer/getPreviewerDetails/' + guid);
		}

		function getPreviewDetailsBudget(guid) {
			return dataservice.getData('Previewer/getPreviewDetailsBudget/' + guid);
		}

		function getPreviewerId(formId) {
			return dataservice.getData('Previewer/getPreviewerId/' + formId);
		}

		function getPrintPreviews(formId) {
			return dataservice.getData('Previewer/getPrintPreviews/' + formId);
		}

		function previewPrint(previewPrint) {
			return dataservice.postData('Previewer/previewPrint', previewPrint);
		}

		function getContributingExpenditures(guid) {
			return dataservice.getData('Report/getContributingExpendituresTable/' + guid);
		}

		function getTotalExpenditures(guid) {
			return dataservice.getData('Report/getTotalExpenditures/' + guid);
		}

		function getDataEntryTable(guid) {
			return dataservice.getData('Report/getDataEntryTable/' + guid);
		}

		function getAnnualUpdateExpenditures(guid) {
			return dataservice.getData('Report/GetAnnualUpdateTable/' + guid);
		}

		function getAnnualUpdateContributingActionsExpenditures(guid) {
			return dataservice.getData('Report/GetAnnualUpdateContributingActionsTable/' + guid);
		}

		function getLCFFCarryoverExpenditures(guid) {
			return dataservice.getData('Report/GetLCFFCarryoverTable/' + guid);
		}

		function setPreviewerPassword(previewerDetails) {
			return dataservice.postData(
				'Previewer/setPreviewerPassword',
				previewerDetails
			);
		}
	}
})();
