(function() {
    'use strict';

    angular
        .module('app.lcap')
        .component('printBudgetOverview', {
            templateUrl: 'app/LCAP/budgetOverview/print/printBudgetOverview.template.html',
            controller: PrintBudgetOverview,
            bindings: {
                eTemplate: '<',
                years: '<',
            },
        });

    /* @ngInject */
    function PrintBudgetOverview(chartsFactory) {
        var $ctrl = this;
        $ctrl.$onInit = activate;
        $ctrl.lcapDetails = $ctrl.eTemplate.lcapUser;
        $ctrl.budgetOverview = $ctrl.eTemplate.budgetOverview;
        $ctrl.numberWithCommas = numberWithCommas;

        function activate() {
            $ctrl.highNeedsExpenditureDiff = 
                $ctrl.budgetOverview.currentExpendituresForHighNeeds - 
                $ctrl.budgetOverview.currentEstimatedExpendituresForHighNeeds;

            $ctrl.showDescribeActionsServicesForHighNeeds = 
                $ctrl.budgetOverview.describeActionsServicesForHighNeeds &&
                ($ctrl.budgetOverview.futureExpendituresForHighNeeds < $ctrl.budgetOverview.supplementalGrant);

            genCharts();
        }

        function genCharts() {
            genRevnuePieChart();
            genBudgetedExpendituresBarChart();
            genHighNeedsChart();

            $ctrl.chartOptions = chartsFactory.getAllOptions($ctrl.pie.one.data, $ctrl.pie.two.data);
        }


        function genRevnuePieChart() {
            $ctrl.pie = {
                one: {
                    labels: ['All Other State Funds', 'All Local Funds', 'All Federal Funds', 'Total LCFF Funds'],
                    data: [$ctrl.budgetOverview.otherStateFunds, $ctrl.budgetOverview.localFunds, $ctrl.budgetOverview.federalFunds, $ctrl.budgetOverview.totalFunds],
                },
                two: {
                    labels: ['LCFF Supplemental & Concentration Grants', 'All Other LCFF Funds'],
                    data: [$ctrl.budgetOverview.supplementalGrant, $ctrl.budgetOverview.totalFunds - $ctrl.budgetOverview.supplementalGrant],
                },
            };
        }

        function genBudgetedExpendituresBarChart() {
            $ctrl.expenditureChartLabels = [['Total Budgeted', 'General Fund Expenditures'], ['Total Budgeted', 'Expenditures in LCAP']];
            $ctrl.expenditureChartData = [$ctrl.budgetOverview.futureGeneralFunds, $ctrl.budgetOverview.futureExpendituresInLCAP];
        }

        function genHighNeedsChart() {
            $ctrl.highNeedsChart = {
                labels: [['Total Budgeted Expenditures for', 'High Needs Students in the LCAP'], ['Estimated Actual Expenditures for', 'High Needs Students in LCAP']],
                data: [$ctrl.budgetOverview.currentExpendituresForHighNeeds, $ctrl.budgetOverview.currentEstimatedExpendituresForHighNeeds],
            };
        }

        function numberWithCommas(x) {
            if (!x) {x = 0;}
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    }
})();