(function() {
    'use strict';

    angular
        .module('app.lcap')
        .component('printStakeholderEngagement', {
            templateUrl: 'app/LCAP/stakeholderEngagement/print/printStakeholderEngagement.template.html',
            controller: PrintStakeholderEngagement,
            bindings: {
                eTemplate: '<',
            },
        });

    /* @ngInject */
    function PrintStakeholderEngagement() {
        var $ctrl = this;
        $ctrl.stake = $ctrl.eTemplate.stakeholderEngagement;
    }
})();