(function () {
	'use strict';

	angular.module('app.lcap').run(appRun);

	appRun.$inject = ['routerHelper', '$rootScope'];

	function appRun(routerHelper, $rootScope) {
		routerHelper.configureStates(getStates());

		$rootScope.$on('$stateChangeSuccess', function () {
			document.body.scrollTop = document.documentElement.scrollTop = 0;
		});
	}

	function getStates() {
		return [
			{
				state: 'LCAP5',
				config: {
					url: '/LCAP5/{formId:int}/{schoolYearId:int}', // maybe use state param for review or just check by usertype
					params: { review: null },
					templateUrl: 'app/LCAP5/lcap.index.html',
					title: 'LCAP eTemplate',
					controller: 'LCAP5Ctrl',
					allowAnonymous: false,
					resolve: {
						/* @ngInject */
						user: function (dashboardService) {
							return dashboardService.getUserFromClaims();
						},
						/* @ngInject */
						eTemplate: function (mainService, $stateParams, $state) {
							return mainService
								.getTemplate($stateParams.formId, $stateParams.schoolYearId)
								.then(function (rsp) {
									if (rsp === 'Access denied') {
										$state.go('unauthorized');
									}

									return {
										overallAnalysis: rsp.overallAnalysis,
										annualUpdateAttendance: rsp.annualUpdateAttendance,
										annualUpdateSupplement: rsp.annualUpdateSupplement,
										lcapUser: rsp.user,
										planSummary: rsp.planSummary,
										budgetOverview: rsp.budgetOverview,
										stakeholderEngagement: rsp.stakeHolder,
										unduplicatedPupils: rsp.unduplicatedPupil,
										annualUpdate: rsp.annualUpdate,
										annualUpdateGoals: rsp.annualUpdateGoals,
										goalsActions: {
											goals: rsp.goals,
										},
										feedback: rsp.feedback,
									};
								});
						},
						/* @ngInject */
						lcapYears: function (dashboardService, user) {
							return dashboardService.getSchoolYears(user.lcapCycleId);
						},
						/* @ngInject */
						preview: function (previewerService, $stateParams) {
							return previewerService.getPreviewerId($stateParams.formId).then(function (rsp) {
								rsp.FormId = $stateParams.formId;
								rsp.SchoolYearId = $stateParams.schoolYearId;
								return rsp || '';
							});
						},
					},
				},
			},
			{
				state: 'LCAP5.annualUpdate',
				config: {
					url: '/annualUpdate',
					templateUrl: 'app/LCAP5/annualUpdate/annualUpdate.index.html',
					title: 'LCAP eTemplate Annual Update',
					controller: 'AnnualUpdatePage5Ctrl',
					allowAnonymous: false,
					resolve: {},
				},
			},
			{
				state: 'LCAP5.annualUpdateAttendance',
				config: {
					url: '/annualUpdateAttendance',
					templateUrl: 'app/LCAP5/annualUpdateAttendance/annualUpdateAttendance.index.html',
					title: 'Attendance eTemplate Annual Update',
					controller: 'AnnualUpdateAttendancePage5Ctrl',
					allowAnonymous: false,
					resolve: {},
				},
			},
			{
				state: 'LCAP5.annualUpdateSupplement',
				config: {
					url: '/annualUpdateSupplement',
					templateUrl: 'app/LCAP5/annualUpdateSupplement/annualUpdateSupplement.index.html',
					title: 'Supplement to the Annual Update',
					controller: 'AnnualUpdateSupplementPage5Ctrl',
					allowAnonymous: false,
					resolve: {},
				},
			},
			{
				state: 'LCAP5.overallAnalysis',
				config: {
					url: '/overallAnalysis',
					templateUrl: 'app/LCAP5/overallAnalysis/overallAnalysis.index.html',
					title: 'LCAP eTemplate Annual Update',
					controller: 'OverallAnalysisPage5Ctrl',
					allowAnonymous: false,
					resolve: {},
				},
			},
			{
				state: 'LCAP5.expenditures',
				config: {
					url: '/expenditures',
					templateUrl: 'app/LCAP5/expenditures/expenditures.html',
					title: 'LCAP eTemplate Action Tables',
					controller: 'ExpenditureTable5Ctrl',
					allowAnonymous: false,
					resolve: {},
				},
			},
			{
				state: 'LCAP5.budgetOverview',
				config: {
					url: '/budgetOverview',
					templateUrl: 'app/LCAP5/budgetOverview/budgetOverview.index.html',
					title: 'LCAP eTemplate Budget Overview for Parents',
					controller: 'BudgetOverview5Ctrl',
					allowAnonymous: false,
					resolve: {},
				},
			},
			{
				state: 'LCAP5.goalsActions',
				config: {
					url: '/goalsActions',
					templateUrl: 'app/LCAP5/goalsActions/goalsActions.index.html',
					title: 'LCAP eTemplate Goals, Actions & Services',
					controller: 'GoalsActionsPage5Ctrl', // Remove page from controller name and rename GASModal controller to avoid conflictd
					allowAnonymous: false,
					resolve: {},
				},
			},
			{
				state: 'LCAP5.lcapFormInfo',
				config: {
					url: '/lcapFormInfo',
					templateUrl: 'app/LCAP5/lcapFormInfo/lcapFormInfo.index.html',
					title: 'LCAP eTemplate Info',
					controller: 'LcapFormInfo5Ctrl',
					allowAnonymous: false,
					resolve: {
						/* @ngInject */
						dashboardYear: function (mainService) {
							return mainService.getDashboardYear().then(function (rsp) {
								if (rsp === 5) {
									return 2020;
								} else if (rsp === 6) {
									return 2021;
								} else {
									return rsp;
								}
							});
						},
					},
				},
			},
			{
				state: 'LCAP5.planSummary',
				config: {
					url: '/planSummary',
					templateUrl: 'app/LCAP5/planSummary/planSummary.index.html',
					title: 'LCAP eTemplate Plan Summary',
					controller: 'PlanSummary5Ctrl',
					allowAnonymous: false,
					resolve: {},
				},
			},
			{
				state: 'LCAP5.printPreview',
				config: {
					url: '/printPreview',
					templateUrl: 'app/LCAP5/printPreview/printPreview.html',
					title: 'LCAP eTemplate Print Preview',
					controller: 'PrintPreview5Ctrl',
					allowAnonymous: false,
					resolve: {
					},
				},
			},
			{
				state: 'LCAP5.submit',
				config: {
					url: '/submit-lcap',
					templateUrl: 'app/LCAP5/submit/submit.template.html',
					title: 'LCAP eTemplate Submission',
					controller: 'SubmitLCAP5Ctrl',
					allowAnonymous: false,
					resolve: {},
				},
			},
			{
				state: 'LCAP5.stakeholderEngagement',
				config: {
					url: '/stakeholderEngagement',
					templateUrl:
						'app/LCAP5/stakeholderEngagement/stakeholderEngagement.index.html',
					title: 'LCAP eTemplate Engaging Educational Partners',
					controller: 'StakeholderEngagement5Ctrl',
					allowAnonymous: false,
					resolve: {},
				},
			},
			{
				state: 'LCAP5.increasedServices',
				config: {
					url: '/increasedServices',
					templateUrl:
						'app/LCAP5/increasedServices/increasedServices.index.html',
					title:
						'Increased or Improved Services for Foster Youth, English Learners, and Low-income Students',
					controller: 'IncreasedServices5Ctrl', // Remove page from controller name and rename GASModal controller to avoid conflictd
					allowAnonymous: false,
					resolve: {},
				},
			},
		];
	}
})();
