(function () {
	'use strict';

	angular
		.module('dashboard')
		.controller('DashboardCtrl', dashboardCtrl);

	/* @ngInject */
	function dashboardCtrl($state, $stateParams, $scope, $rootScope, user, forms, users, lcapYears, dashboardYear, $ngBootbox, leaAccess, simpleStorageService) {
		var vm = this;

		vm.$onInit = activate;
		vm.user = user;
		vm.users = users;
		vm.lcapYears = lcapYears;
		vm.dashboardYear = dashboardYear;
		vm.leaAccess = leaAccess;
		vm.dashboardNav = dashboardNav;
		vm.currentYear = _.findWhere(vm.lcapYears, { isCurrentYear: true });

		function activate() {
			var useDataWarning = false;
			var hideDataWarning = simpleStorageService.getItem('hideDataWarning');

			if (!$stateParams.view) {$state.go('.', { view: 'lcaps' });}
			else if (useDataWarning && !hideDataWarning && !$rootScope.warned) {$ngBootbox.customDialog(getDataWarning());}

			if (user.userType == 'Admin') {
				vm.forms = forms.filter(function (form) { return form.schoolYearId > 3; });
			} else {
				vm.forms = forms;
			}

			vm.view = $stateParams.view;
		}

		function dashboardNav(route) {
			$state.go('.', { view: route });
		}

		function getDataWarning() {
			var modal = {
				templateUrl: 'app/dashboard/dataWarning.template.html',
				scope: $scope,
				buttons: {
					primary: {
						label: 'Ok',
						class: 'btn-primary',
						callback: function () {
							if ($scope.testBox === true)
								{simpleStorageService.setItem('hideDataWarning', $scope.testBox);}

							$rootScope.warned = true;
						},
					},
				},
			};

			return modal;
		}
	}
})();
