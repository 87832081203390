(function () {
	angular.module('app', [
		'ui.router',
		'ui.bootstrap',
		'services',
		'helpers',
		'ngBootbox',
		'dashboard',
		'app.validation',
		'app.lcap',
		'monospaced.elastic',
		'ngClipboard',
	])
		.constant(
			'setupDragAndDropForDetails',
			function setupDragAndDropForDetails(containerId, list, updateFn) {
				var $el = document.getElementById(containerId);
				if ($el == null) {
					return alert('Container #' + containerId + ' not found.');
				}

				var details = /** @type{HTMLDetailsElement[]} */(
					Array.prototype.slice.call($el.querySelectorAll('details[draggable="true"]'))
				);
				if (details.length === 0) {
					console.info('Container #' + containerId + ' has zero elements with selector \'details[draggable="true"]\'.');
					return;
				}

				var summary = details[0].querySelector('summary');
				if (summary == null) {
					return alert('No <summary> found for first <details> of container #' + containerId);
				}

				var detailPlaceholder = document.createElement('div');
				detailPlaceholder.style.setProperty('padding', '2.69rem');
				detailPlaceholder.style.setProperty('margin-bottom', '2.3rem');
				detailPlaceholder.style.setProperty('border', '0.23rem grey dotted');
				detailPlaceholder.style.setProperty('height', summary.offsetHeight.toString());
				detailPlaceholder.style.setProperty('width', summary.offsetWidth.toString());

				// all of these need to be set so that the placeholder doesn't break everything
				detailPlaceholder.draggable = true;
				detailPlaceholder.ondragstart = function () {
					return false;
				};
				detailPlaceholder.ondragover = function (e) {
					e.preventDefault();
					return false;
				};
				detailPlaceholder.ondrop = function (e) {
					var el = /** @type {HTMLElement} */(detailPlaceholder.nextElementSibling);
					/** @type {Function} */(el.ondrop)(e);
				};

				var _dragTarget;
				details
					.forEach(function (detail) {
						if (detail.ondragstart != null) {
							return;
						}
						detail.ondragstart = function (e) {
							if (detail.open) {
								return false;
							}
							/** @type {DataTransfer} */(e.dataTransfer).setData('text/plain', containerId);
							_dragTarget = detail;
							_dragTarget.classList.remove('panel-default');
							_dragTarget.classList.add('panel-info');
							setTimeout(function () {
								_dragTarget.classList.add('panel-default');
								_dragTarget.classList.remove('panel-info');
							});
						};
						detail.ondragover = function (e) {
							e.preventDefault();
							return false;
						};
						detail.ondragenter = function (e) {
							e.preventDefault();
							if (_dragTarget === detail) {
								detailPlaceholder.remove();
							} else {
								if (_dragTarget != null && _dragTarget.parentElement === detail.parentElement) {
									if (details.indexOf(_dragTarget) > details.indexOf(detail)) {
										/** @type {HTMLElement} */(detail.parentElement)
											.insertBefore(detailPlaceholder, detail);
									} else {
										/** @type {HTMLElement} */(detail.parentElement)
											.insertBefore(
												detailPlaceholder,
												/** @type {HTMLElement} */(detail.nextElementSibling)
											);
									}
								}
							}
							return false;
						};
						detail.ondragend = function (e) {
							e.preventDefault();
							_dragTarget = null;
							detailPlaceholder.remove();
							return false;
						};
						detail.ondrop = function (e) {
							e.stopPropagation();
							if (_dragTarget !== detail) {
								var id = /** @type {DataTransfer} */(e.dataTransfer).getData('text/plain');
								if (id !== containerId) {
									alert('Cannot drag item to different section.');
								} else {
									var currentIndex = Number(detail.dataset.index);
									var transferIndex = Number(_dragTarget.dataset.index);
									var transfer = list[transferIndex];
									list.splice(transferIndex, 1);
									list.splice(currentIndex, 0, transfer);
									updateFn();
								}
							}
							return false;
						};
					});
			}
		)
		.run(['authService', function (authService) {
			authService.fillAuthData();
		}])
		.config(function ($httpProvider) {
			$httpProvider.interceptors.push('authInterceptorService');
		});
})();
