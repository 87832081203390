(function () {
	'use strict';

	angular
		.module('app.lcap')
		.factory('chartsFactory', chartsFactory);

	/* @ngInject */
	function chartsFactory() {
		var chart = {
			getBarChart: getBarChart,
			getHorizantalBarChart: getHorizantalBarChart,
			getDataSetOverrides: getDataSetOverrides,
			getAllOptions: getAllOptions,
		};

		// Charts Colors
		var $purple = '153,122,190';
		var $green = '197,224,180';
		var $blue = '91,155,213';
		var $yellow = '249,215,28';

		var totalFundsPct;

		// CHARTS GLOBAL DEFAULTS
		// -------------------------
		Chart.defaults.global.defaultFontSize = 18;
		Chart.defaults.global.defaultFontStyle = 'normal';
		Chart.defaults.global.defaultFontFamily = 'Helvetica';
		Chart.defaults.global.defaultFontColor = '#000000';

		// Legend Defaults
		Chart.defaults.global.legend.display = true;
		Chart.defaults.global.legend.position = 'bottom';

		return chart;

		////////////////

		function getAllOptions(pieOneDataset, pieTwoDataset) {
			var chartOptions = {
				verticalBarOptions: getBarChart(),
				horizantalBarOptions: getHorizantalBarChart(),
				pieOneOptions: getPieChart(pieOneDataset),
				pieTwoOptions: getPieChart(pieTwoDataset, totalFundsPct),
				verticalBarOverrides: getDataSetOverrides('verticalBar'),
				horizantalBarOverrides: getDataSetOverrides('horizantalBar'),
				pieOverrides: getDataSetOverrides('pie'),
			};

			return chartOptions;
		}

		function getDataSetOverrides(type) {
			var datasets;
			if (type === 'verticalBar' || type === 'horizantalBar') {
				datasets = {
					backgroundColor: [getRGBA($blue, .8), getRGBA($green, .8)],
					hoverBackgroundColor: [getRGBA($blue, .8), getRGBA($green, .8)],
					borderColor: [getRGBA($blue, .8), getRGBA($green, .8)],
				};
			} else if (type === 'pie') {
				var colors = [getRGBA($yellow, .6), getRGBA($green, 1), getRGBA($purple, .5), getRGBA($blue, .5)];

				datasets = {
					backgroundColor: colors,
					hoverBackgroundColor: colors,
					hoverBorderColor: colors,
					hoverBorderWidth: [0, 0, 0, 0],
				};
			}

			return datasets;
		}

		function getPct(total, value, target) {
			if (!target) {target = 100;}
			return (value / total) * target;
		}

		function roundPct(dataSet, target) {
			var off = target - _.reduce(dataSet, function (acc, x) { return acc + Math.round(x); }, 0);
			return _.chain(dataSet).
				map(function (x, i) { return Math.round(x) + (off > i) - (i >= (dataSet.length + off)); }).
				value();
		}

		function numberWithCommas(x) {
			if (!x) {x = 0;}
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		}

		function getPieChart(dataset, target) {
			if (!target) {target = 100;}

			var percentages = [];
			var total = dataset.reduce(function (one, two) {
				return one + two;
			});

			for (var i = 0; i < dataset.length; i++) {
				var item = dataset[i];
				var percent = getPct(total, item, target);
				percentages.push(percent);
			}

			percentages = roundPct(percentages, target);

			if (!totalFundsPct) {totalFundsPct = percentages[3];}

			var options = {
				tooltips: { enabled: false },
				layout: {
					padding: {
						left: 0,
						right: 50,
						top: 50,
						bottom: 25,
					},
				},
				plugins: {
					datalabels: {
						formatter: function (value, chart) {
							return '$' + numberWithCommas(value) + '\n' + percentages[chart.dataIndex] + '%';
						},
						anchor: function (ctx) {
							return percentages[ctx.dataIndex] > 20 ? 'center' : 'end';
						},
						align: function (ctx) {
							return percentages[ctx.dataIndex] > 20 ? 'center' : 'end';
						},
						display: 'auto',
						color: 'black',
						font: {
							size: '16',
							weight: 'bold',
						},
						textAlign: 'center',
						backgroundColor: function (ctx) {
							return percentages[ctx.dataIndex] > 20 ? null : ctx.dataset.backgroundColor[ctx.dataIndex];
						},
						borderRadius: 8,
					},
				},
				legend: {
					position: 'right',
					labels: {
						generateLabels: function (chart) {
							var data = chart.data;
							if (data.labels.length && data.datasets.length) {
								return data.labels.map(function (label, i) {
									var meta = chart.getDatasetMeta(0);
									var ds = data.datasets[0];
									var arc = meta.data[i];
									var custom = arc && arc.custom || {};
									var getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
									var arcOpts = chart.options.elements.arc;
									var fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
									var stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
									var bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

									// We get the value of the current label
									var value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];
									return {
										// Instead of `text: label,`
										// We add the value to the string
										text: label + ' - $' + numberWithCommas(value) + ' (' + percentages[i] + '%)',
										fillStyle: fill,
										strokeStyle: stroke,
										lineWidth: bw,
										hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
										index: i,
									};
								});
							} else {
								return [];
							}
						},
					},
				},
				maintainAspectRatio: false,
				// responsive: true,
			};

			return { options: options, percentages: percentages };
		}

		function getBarChart() {
			var options = {
				scales: {
					yAxes: [{
						ticks: {
							precision: 0,
							beginAtZero: true,
							callback: function (value) {
								return '$' + numberWithCommas(value) + ' ';
							},
						},
					}],
					xAxes: [{
						maxBarThickness: 100,
					}],
				},
				maintainAspectRatio: false,
				tooltips: { enabled: false },
				legend: { display: false },
				plugins: {
					datalabels: {
						formatter: function (value) {
							return '$' + numberWithCommas(value);
						},
						display: true,
						color: 'black',
						font: {
							size: '16',
							weight: 'bold',
						},
					},
				},
			};

			return options;
		}

		function getHorizantalBarChart() {
			var options = {
				scales: {
					yAxes: [{
						maxBarThickness: 100,
					}],
					xAxes: [{
						ticks: {
							precision: 0,
							beginAtZero: true,
							callback: function (value) {
								return '$' + numberWithCommas(value) + ' ';
							},
						},
					}],
				},
				tooltips: { enabled: false },
				legend: { display: false },
				maintainAspectRatio: false,
				plugins: {
					datalabels: {
						formatter: function (value) {
							return '$' + numberWithCommas(value);
						},
						display: true,
						color: 'black',
						font: {
							size: '16',
							weight: 'bold',
						},
					},
				},
			};

			return options;
		}

		function getRGBA(color, opacity) {
			return 'rgba(' + color + ',' + opacity + ')';
		}
	}
}());
