(function () {
	'use strict';

	angular
		.module('app.lcap')
		.component('printPlanSummaryLatest4', {
			templateUrl: 'app/LCAP4/planSummary/print/printPlanSummary.template.html',
			controller: PrintPlanSummary4Ctrl,
			bindings: {
				eTemplate: '<',
				years: '<',
			},
		});

	/* @ngInject */
	function PrintPlanSummary4Ctrl() {
		var $ctrl = this;

		$ctrl.$onInit = activate;

		/////////////////////

		function activate() {
			$ctrl.plan = $ctrl.eTemplate.planSummary;
		}
	}
})();
