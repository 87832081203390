(function () {
	'use strict';

	angular.module('app').controller('AnnualUpdate5Ctrl', annualUpdate5Ctrl);

	/* @ngInject */
	function annualUpdate5Ctrl(
		$scope,
		$rootScope,
		$uibModalInstance,
		$ngBootbox,
		$timeout,
		$stateParams,
		eTemplate,
		editableGoal,
		goalIndex,
		mainService,
		lcapLatestLookup,
		validationFactory,
		setupDragAndDropForDetails,
		user
	) {
		var vm = this;

		vm.$onInit = activate;
		vm.lookup = lcapLatestLookup;
		vm.editableGoal = editableGoal;
		vm.dismissModal = dismissModal;
		vm.newGoal = {};
		vm.newAction = {};
		vm.save = save;
		vm.addAction = addAction;
		vm.deleteAction = deleteAction;
		vm.checkPriorities = checkPriorities;
		vm.calculateFunds = calculateFunds;
		vm.goalIndex = goalIndex;
		vm.addOutcome = addOutcome;
		vm.deleteOutcome = deleteOutcome;
		vm.inReview = $stateParams.review;
		vm.eTemplate = eTemplate;
		vm.closeModal = closeModal;
		vm.user = user;
		vm.isAdminUser = user.userType == 'Admin';
		vm.loading = true;
		vm.formId = $stateParams.formId || 0;
		vm.scopeOptions = [{ Text: 'LEA-wide', Value: 1 }, { Text: 'Schoolwide', Value: 2 }, {
			Text: 'Limited', Value: 3,
		}];

		$scope.$on('loginChanged', function () {
			// Closes Modal on autologout
			$uibModalInstance.dismiss('cancel');
		});

		$rootScope.$on('validate', function(){
			showOutcomeSectionValidators();
			showActionSectionValidators();
		});

		function activate() {
			$timeout(function () {
				vm.form = $scope.annualUpdateGoal;
				vm.form.customValidator = save;

				$rootScope.$broadcast('elastic:adjust'); // Adjust textarea heights
			});

			setupGoals();
		}

		function addAction() {
			vm.newGoal.actionsServices.push(vm.newAction); // push existing action
			vm.newAction = {}; // reset
			$timeout(setupDragAndDrop);
		}

		function setupDragAndDrop() {
			// setupDragAndDropForDetails('annual-measurable-outcomes', vm.newGoal.outcomes, $timeout);
			setupDragAndDropForDetails('actions-services', vm.newGoal.actionsServices, $timeout);
		}

		function calculateFunds(index) {
			var action = vm.newGoal.actionsServices[index];
			var totalFunds = (
				(action.lcffFunds || 0.0) +
				(action.otherStateFunds || 0.0) +
				(action.localFunds || 0.0) +
				(action.federalFunds || 0.0)
			);

			action.totalFunds = totalFunds.toFixed(2);
		}

		function setupGoals() {
			if (editableGoal) {
				mainService
					.getAnnualUpdateGoals(
						editableGoal.formId,
						editableGoal.schoolYearId,
						editableGoal.goalId
					)
					.then(function (rsp) {
						vm.newGoal = angular.copy(rsp);
						vm.newGoal.reviewStatusId = vm.newGoal.details.reviewStatusId || 0;

						checkFeedback();
						setWasRolledOver();

						if (!vm.newGoal.actionsServices.length)
							{vm.newGoal.actionsServices = [{}];}
						if (!vm.newGoal.outcomes.length) {vm.newGoal.outcomes = [{}];}

						// I know we import goalIndex already, I want to make sure I'm replacing the correct goal
						var goalIndex = _.findIndex(vm.eTemplate.annualUpdateGoals, {
							goalId: vm.newGoal.goalId,
						});
						vm.eTemplate.annualUpdateGoals[goalIndex] = vm.newGoal;

						vm.loading = false;
						$timeout(setupDragAndDrop);
						$timeout(function(){
							$rootScope.$broadcast('validate');
						});
					})
					.catch(function () {
						toastr.error('Error opening goal, refresh and try again');
						$uibModalInstance.dismiss('cancel');
						vm.loading = false;
					});
			} else {
				vm.newGoal = {
					actionsServices: [{}],
					outcomes: [{}],
					details: {
						reviewStatusId:
							vm.eTemplate.annualUpdateGoals.reviewStatusId != 0 ? 1 : 0,
					},
				};

				checkFeedback();
				setWasRolledOver();
				vm.loading = false;
			}
		}

		function checkFeedback() {
			vm.showFeedback =
				(vm.inReview &&
					vm.eTemplate.lcapUser.currentYearStatus.statusId === 3) ||
				(vm.newGoal.details.reviewStatusId != 0 && vm.newGoal.details.goalId);
		}

		function setWasRolledOver() {
			vm.wasRolledOver =
				vm.newGoal.schoolYearId === 3 &&
					vm.eTemplate.lcapUser.yearTwoStatus === 4
					? true
					: false;
		}

		function addOutcome() {
			vm.newGoal.outcomes.push({});
			$timeout(setupDragAndDrop);
		}

		function deleteOutcome(index, outcome) {
			if (confirm('Are you sure you want to delete Outcome #' + (index + 1) + '?')) {
				if (outcome.id != null) {mainService.deleteAUOutcome(outcome.id);}
				vm.newGoal.outcomes.splice(index, 1);
			}
		}

		function deleteAction(index, actionId) {
			if (confirm('Are you sure you want to delete Action #' + (index + 1) + '?')) {
				if (actionId != null) {mainService.deleteAUAction(actionId);}
				vm.newGoal.actionsServices.splice(index, 1);
			}
		}

		function checkPriorities(form) {
			return validationFactory.validatePriorities(vm.newGoal.details, form);
		}

		function save(form, status){
			if(!vm.newGoal.goalId){
				$ngBootbox.customDialog(getSaveGoalModal(form, status));
			} else {
				saveGoal(form, status, vm.newGoal.isRolloverGoal);
			}
		}

		function saveGoal(form, status, isRolloverGoal) {
			$rootScope.$broadcast('validate');
			vm.newGoal.details.reviewStatusId = 0; //reset form status
			vm.newGoal.isRolloverGoal = isRolloverGoal;

			var saveDraft =
			status === false &&
			vm.newGoal.details != null &&
			vm.newGoal.details.goal;
			var formValid =
			form.$valid &&
			checkPriorities(form) === true &&
			vm.newGoal.actionsServices.length > 0;
			var formInvalid =
			form.$invalid ||
			checkPriorities(form) === false ||
			vm.newGoal.actionsServices.length === 0;
			var goalDetailsRequired = !vm.newGoal.details || !vm.newGoal.details.goal;
			
			if (saveDraft) {
				closeModal(true, false);
			}
			// Save as draft
			else if (formValid) {
				if (vm.newGoal.details.reviewStatusId === 2) {
					if (confirm(vm.lookup.ERROR_MESSAGES.REQUESTED_REVIEW_CHANGES)) {
						vm.newGoal.details.reviewStatusId = 2;
						closeModal(true, false);
					} else {
						vm.newGoal.details.reviewStatusId = 1;
						closeModal(true, false);
					}
				} else if (vm.newGoal.details.reviewStatusId === 3) {
					if (confirm(vm.lookup.ERROR_MESSAGES.APPROVED_REVIEW_CHANGE)) {
						vm.newGoal.details.reviewStatusId = 1; // Reset for re-review
						closeModal(true, false); // Save Valid Form
					}
				} else {
					vm.newGoal.details.isComplete = true;
					closeModal(true, false); // Save Valid Form
				}
			} else if (formInvalid) {
				// Invalid Form
				if (goalDetailsRequired) {
					// Goal details are required
					alert(vm.lookup.ERROR_MESSAGES.GOAL_DESCRIPTION);
				} else {
					// Save incomplete goal as draft
					if (confirm(vm.lookup.ERROR_MESSAGES.INCOMPLETE_GOAL)) {
						closeModal(false, false);
					}
				}
			}
		}

		function getSaveGoalModal(form, status) {
			var modal = {
				message: vm.lookup.ROLLOVER_GOAL_MESSAGE,
				buttons: {
					danger: {
						label: 'No',
						className: 'btn-warning',
						callback: function () {
							saveGoal(form, status, false);
						},
					},
					success: {
						label: 'Yes',
						className: 'btn-primary',
						callback: function () {
							saveGoal(form, status, true);
						},
					},
				},
			};

			return modal;
		}

		function closeModal(status, modalOpen) {
			vm.newGoal.details.status = status;
			$uibModalInstance.close(vm.newGoal, vm.form);
			$rootScope.modalOpen = modalOpen;
		}

		function dismissModal() {
			if (!vm.inReview) {
				if (confirm(vm.lookup.ERROR_MESSAGES.LOST_CHANGES)) {
					$uibModalInstance.dismiss('cancel');
					$rootScope.modalOpen = false;
				} else {
					$rootScope.modalOpen = true;
				}
			} else {$uibModalInstance.dismiss('cancel');}
		}

		function showOutcomeSectionValidators() {
			if(vm.newGoal.outcomes.length != 0){
				var outcomePropNames =  Object.keys(getEmptyOutcome());
				vm.newGoal.outcomes.forEach(function(outcome,i){
					outcome.valid = true;
					var j = 0;
					while (j < outcomePropNames.length) {
						if($rootScope.form[outcomePropNames[j] + i] && $rootScope.form[outcomePropNames[j] + i].$invalid === true){
							outcome.valid = false;
							j = outcomePropNames.length + 1;
						}
						j++;
					}	
				});
			}	
		}

		function showActionSectionValidators() {
			if(vm.newGoal.actionsServices.length != 0){
				var actionPropNames =  Object.keys(getEmptyAction());
				vm.newGoal.actionsServices.forEach(function(action,i){
					action.valid = true;
					var j = 0;
					while (j < actionPropNames.length) {
						if($rootScope.form[actionPropNames[j] + i] && $rootScope.form[actionPropNames[j] + i].$invalid === true){
							action.valid = false;
							j = actionPropNames.length + 1;
						}
						j++;
					}	
				});
			}	
		}

		function getEmptyOutcome(){
			return {
				metricsIndicators: null,
				baseline: null,
				expectedOutcomeOne: null,
				expectedOutcomeTwo: null,
				expectedOutcomeThree: null,
				desiredOutcome: null,	
			};
		}

		function getEmptyAction(){
			return {
				title: null,
				description: null,
				contributing: null,
				studentGroups: null,
				scope: null,
				unduplicatedStudentGroups: null,
				location: null,
				timeSpan: null,
				lcffFunds: null,
				otherStateFunds: null,
				localFunds: null,
				federalFunds: null,
				totalFunds: null,
				estimatedActualExpenditures: null,
				estimatedActualExpendituresContributingActions: null,
				plannedPercentageImprovedServices: null,
				estimatedActualPercentageOfImprovedServices: null,
			};
		}
	}
})();
