(function () {
	'use strict';

	angular
		.module('app')
		.controller('LoginCtrl', loginCtrl);

	/* @ngInject */
	function loginCtrl($scope, $timeout, authService, $state, $rootScope) {
		var vm = this;

		vm.$onInit = activate;

		////////////////

		function activate() {
			vm.lockdown = $rootScope.lockdown;
			vm.loginForm = { username: '', password: '' };
			vm.login = login;

			$timeout(function () {
				if ($rootScope.lockdown === false) {
					vm.form = $scope.login;
					vm.form.customValidator = login;
				}
			});

			authService.logOut();
		}

		function login() {
			if ($rootScope.lockdown) {
				return;
			}
			var t = setTimeout(function () { toastr.info('Logging in...'); }, 200);
			authService.login(vm.loginForm)
				.then(function () {
					clearTimeout(t);
					toastr.success('Logged in');
					$state.go('dashboard', { view: 'lcaps' });
				})
				.catch(function (rsp) {
					clearTimeout(t);
					toastr.error(rsp.data.title);
				});
		}
	}
})();
