(function () {
	'use strict';

	angular
		.module('dashboard')
		.controller('lcapStatusModalController', lcapStatusModalController);

	/* @ngInject */
	function lcapStatusModalController($uibModalInstance, form, lookup, mainService) {
		var vm = this;
		vm.$onInit = activate;
		vm.form = form;
		vm.dismissModal = dismissModal;
		vm.updateStatus = updateStatus;
		vm.lookupStatuses = lookup.LCAP_STATUS;

		function activate() {
			vm.status = form.currentYearStatus.status;
			setStatuses();
		}

		function dismissModal() {
			$uibModalInstance.dismiss();
		}

		function setStatuses() {
			vm.statuses = [];

			lookup.LCAP_STATUS.forEach(function (item) {
				if (item.statusId >= 2 && item.statusId <= form.currentYearStatus.statusId) {
					vm.statuses.push(item.status);
				}
			});
		}

		function updateStatus() {
			var status = _.findWhere(lookup.LCAP_STATUS, { status: vm.status });

			if (form.yearOneStatus === form.currentYearStatus.statusId)
				{form.yearOneStatus = status.statusId;}

			if (form.yearTwoStatus === form.currentYearStatus.statusId)
				{form.yearTwoStatus = status.statusId;}

			if (form.yearThreeStatus === form.currentYearStatus.statusId)
				{form.yearThreeStatus = status.statusId;}

			mainService.updateLCAPStatus(form)
				.then(function () {
					$uibModalInstance.close(form);
				});
		}
	}
})();
