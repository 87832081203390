(function () {
	'use strict';

	angular
		.module('dashboard')
		.controller('lcapPrintModalController', lcapPrintModalController);

	/* @ngInject */
	function lcapPrintModalController($state, $window, type, $uibModalInstance, user, lcapYears, lookup, currentYear, form, printPreviews) {
		var vm = this;
		vm.activate = activate;
		vm.lookup = lookup;
		vm.user = user;
		vm.lcapYears = lcapYears;
        vm.lcapYear = '0';
		vm.currentYear = currentYear;
        vm.dismissModal = dismissModal;
		vm.print = print;
		vm.setPrintId = setPrintId;
		vm.form = form;
		vm.type = type;
		vm.printPreviews = printPreviews;
		vm.selectSchoolYear = 0;

		activate();

		function activate() {
			setLcapYears();
		}

		function setLcapYears() {
			vm.lcapYears = _.filter(vm.lcapYears, function(lcapYear) {
				var year = _.findWhere(vm.printPreviews, {SchoolYearId: lcapYear.schoolYearId});
				return year;
			});
		}

        function dismissModal() {
			$uibModalInstance.close();
		}

		function setPrintId() {
			vm.selectSchoolYear = _.findWhere(vm.lcapYears, {year: vm.lcapYear});
			vm.printPreview = _.findWhere(vm.printPreviews, {SchoolYearId: vm.selectSchoolYear.schoolYearId});
			vm.printId = vm.printPreview.PreviewerId;
			if (vm.type == 'report-budget'){
				vm.printId = vm.printPreview.OverviewUrl;
			}
		}

		function print() {
			var yearRoute = vm.type == 'report-supplement' ? '' : ('/' + vm.lcapYear);

			var url = $state.href(vm.type + yearRoute, { guid: vm.printId });
			$window.open(url, '_blank');
			$uibModalInstance.close();
		}
	}
})();