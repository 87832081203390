(function() {
    'use strict';

    angular
        .module('app')
        .controller('UnduplicatedPupilsCtrl', UnduplicatedPupilsCtrl);
        
    /* @ngInject */
    function UnduplicatedPupilsCtrl($rootScope, $timeout, eTemplate, $stateParams, lcapYears, user) {
        var vm = this;
        
        vm.$onInit = activate;
        vm.eTemplate = eTemplate;
        vm.lcapYears = lcapYears;
        vm.disableYears = disableYears;
        vm.inReview = $stateParams.review;
        vm.currentYear = _.findWhere(lcapYears, {isCurrentYear: true});
        vm.user = user;
        
        ////////////////////////////////

        function activate() {
            $timeout(checkFeedback);
            $timeout(disableYears);
        }

        function checkFeedback() {
            vm.showFeedback = (vm.inReview && vm.eTemplate.lcapUser.currentYearStatus.statusId === 3) || vm.eTemplate.unduplicatedPupils.reviewStatusId != 0;
            $rootScope.$broadcast('showFeedback', vm.showFeedback);
        }  

        function disableYears() {
            vm.disableYearOne = vm.eTemplate.lcapUser.yearOneStatus != 2 && vm.eTemplate.lcapUser.yearOneStatus != 0;
            vm.disableYearTwo = vm.eTemplate.lcapUser.yearTwoStatus != 2 && vm.eTemplate.lcapUser.yearTwoStatus != 0;
            vm.disableYearThree = vm.eTemplate.lcapUser.yearThreeStatus != 2 && vm.eTemplate.lcapUser.yearThreeStatus != 0;
        }
    }
})();