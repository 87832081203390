(function() {
    'use strict';

    angular
        .module('app')
        .controller('PrintCtrl', PrintCtrl);

    /* @ngInject */
    function PrintCtrl(lookup, user, eTemplate, lcapYears) {
        var vm = this;

        vm.$onInit = activate;
        vm.lookup = lookup;
        vm.eTemplate = eTemplate;
        vm.user = user;
        vm.lcapYears = lcapYears;

        ////////////////////////////////

        function activate() {
           
        }
    }
})();