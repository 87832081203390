(function () {
	'use strict';

	angular.module('app').controller('GoalsActions4Ctrl', GoalsActions4Ctrl);

	/* @ngInject */
	function GoalsActions4Ctrl (
		$scope,
		$rootScope,
		$uibModalInstance,
		$stateParams,
		$timeout,
		$ngBootbox,
		validationFactory,
		editableGoal,
		goalIndex,
		mainService,
		lcapYears,
		eTemplate,
		user,
		setupDragAndDropForDetails,
		lcapLatestLookup
	) {
		var vm = this;

		vm.$onInit = activate;
		vm.lookup = lcapLatestLookup;
		vm.dismissModal = dismissModal;
		vm.eTemplate = eTemplate;
		vm.newGoal = {};
		vm.newOutcome = {};
		vm.newAction = {
			isOpen: true,
			isComplete: 1,
			isSubmitted: false,
			contributing: null,
			totalPersonnel: 0.0,
		};
		vm.deleteOutcome = deleteOutcome;
		vm.addOutcome = addOutcome;
		vm.addAction = addAction;
		vm.deleteAction = deleteAction;
		vm.save = save;
		vm.checkPriorities = checkPriorities;
		vm.goalIndex = goalIndex;
		vm.inReview = $stateParams.review;
		vm.lcapYears = lcapYears;
		vm.user = user;
		vm.isAdminUser = user.userType == 'Admin';
		vm.closeModal = closeModal;
		vm.checkNew = checkNew;
		vm.eoRequired = {
			yearOne: { yearNumber: 1, required: true },
			yearTwo: { yearNumber: 2, required: true },
			yearThree: { yearNumber: 3, required: true },
		};
		vm.loading = true;
		vm.isFirstYearOfCycle = false;
		vm.changeLocation = changeLocation;
		vm.calculateFunds = calculateFunds;
		vm.scopeOptions = [{ Text: 'LEA-wide', Value: 1 }, { Text: 'Schoolwide', Value: 2 }, {
			Text: 'Limited', Value: 3,
		}];

		// vm.focusGoalCount = focusGoalCount;
		// vm.hasASupportingPrioritizationGoal = hasASupportingPrioritizationGoal;

		$scope.$on('loginChanged', function () {
			// Closes Modal on autologout
			$uibModalInstance.dismiss('cancel');
		});

		$rootScope.$on('validate', function(){
			showOutcomeSectionValidators();
			showActionSectionValidators();
		});

		function activate() {
			$timeout(function () {
				vm.form = $scope.goalsActionsGoal; // Form Object
				vm.form.customValidator = save; // Custom Validator

				vm.isFirstYearOfCycle =
					_.findWhere(vm.lcapYears, {
						isCurrentYear: true,
						yearNumber: 1,
					}) !== undefined;

				$rootScope.$broadcast('elastic:adjust'); // Adjust textarea heights
			});

			setupGoals();
		}

		function setupDragAndDrop() {
			setupDragAndDropForDetails('expected-outcomes', vm.newGoal.expectedOutcomes, $timeout);
			setupDragAndDropForDetails('actions', vm.newGoal.actions, $timeout);
		}

		function calculateFunds(index) {
			var action = vm.newGoal.actions[index];
			var totalFunds = (
				(action.lcffFunds || 0.0) +
				(action.otherStateFunds || 0.0) +
				(action.localFunds || 0.0) +
				(action.federalFunds || 0.0)
			);

			if (totalFunds && action.totalPersonnel > -1 && totalFunds > 0.0) {
				action.totalNonPersonnel = (totalFunds - action.totalPersonnel).toFixed(2);
				action.personnelExpense = ((action.totalPersonnel / totalFunds) * 100.0).toFixed(2) + '%';
			} else {
				action.totalNonPersonnel = '0.00';
				action.personnelExpense = '0.00%';
			}

			action.totalFunds = totalFunds.toFixed(2);
		}

		function changeLocation(index) {
			vm.newGoal.actions[index].placeHolder = !vm.newGoal.actions[index].placeHolder
				? '(Optional)'
				: '';
		}
		function disableYears() {
			vm.disableYearOne =
				vm.eTemplate.lcapUser.yearOneStatus != 2 &&
				vm.eTemplate.lcapUser.yearOneStatus != 0;
			vm.disableYearTwo =
				vm.eTemplate.lcapUser.yearTwoStatus != 2 &&
				vm.eTemplate.lcapUser.yearTwoStatus != 0;
			vm.disableYearThree =
				vm.eTemplate.lcapUser.yearThreeStatus != 2 &&
				vm.eTemplate.lcapUser.yearThreeStatus != 0;
		}

		function checkNew() {
			if (vm.newGoal.details.goalStatus === 'New') {
				var currentYear = vm.eTemplate.lcapUser.currentYear.yearNumber;

				_.each(vm.eoRequired, function (item) {
					if (item.yearNumber < currentYear) {item.required = false;}
					else {item.required = true;}
				});
			} else {
				_.each(vm.eoRequired, function (item) {
					item.required = true;
				});
			}
		}

		function addAction() {
			vm.newGoal.actions.push(angular.copy(vm.newAction));
			$timeout(setupDragAndDrop);
		}

		function deleteAction(index, actionId) {
			$rootScope.modalOpen = true;
			if (confirm('Are you sure you want to delete Action #' + (index + 1) + '?')) {
				if (actionId != null) {mainService.deleteGASAction(actionId);}
				vm.newGoal.actions.splice(index, 1);
			}
		}

		function checkPriorities(form) {
			if (!vm.savingAction && vm.newGoal)
				{return validationFactory.validatePriorities(vm.newGoal.details, form);}
		}

		function deleteOutcome(index, outcomeId) {
			$rootScope.modalOpen = true;
			if (confirm('Are you sure you want to delete Outcome #' + (index + 1) + '?')) {
				if (outcomeId != null) {mainService.deleteGASOutcome(outcomeId);}
				vm.newGoal.expectedOutcomes.splice(index, 1);
			}
		}

		function addOutcome() {
			vm.newGoal.expectedOutcomes.push(vm.newOutcome);
			vm.newOutcome = {};
			$timeout(setupDragAndDrop);
		}

		function checkActions() {
			// Checking/Validating Actions
			vm.actions = {
				complete: vm.newGoal.actions.filter(function (item) {
					return item.isComplete === 3;
				}).length,
				incomplete: vm.newGoal.actions.filter(function (item) {
					return item.isComplete === 2;
				}).length,
				draft: vm.newGoal.actions.filter(function (item) {
					return item.isComplete === 1;
				}).length,
				valid: function () {
					return this.complete >= 1 && this.incomplete === 0 && this.draft === 0
						? true
						: false;
				},
				invalid: function () {
					return vm.newGoal.actions.length == 0 || this.incomplete != 0 || this.draft != 0 || this.complete === 0
						? true
						: false;
				},
			};

		}

		function checkFeedback() {
			vm.showFeedback =
				(vm.inReview &&
					vm.eTemplate.lcapUser.currentYearStatus.statusId === 3) ||
				(vm.newGoal.details.reviewStatusId != 0 && vm.newGoal.details.goalId);
		}

		function save(form, status){
			if(!vm.newGoal.details.goalId){
				saveGoal(form, status, true);
			} else {
				saveGoal(form, status, vm.newGoal.details.isRolloverGoal);
			}
		}

		function saveGoal(form, status, isRolloverGoal) {
			$rootScope.$broadcast('validate');
			var hasClosed = false;
			$scope.$on('modal.closing', function (e, goal, isClose) {
				if (hasClosed === true || isClose === false) {return;}

				e.preventDefault();

				// if this is the first goal
				if (!vm.eTemplate.goalsActions.goals)
					{vm.eTemplate.goalsActions.goals = [];}

				// Get existing goal index then update existing goal
				var goalIndex = _.findIndex(vm.eTemplate.goalsActions.goals, {
					goalId: goal.goalId,
				});

				if (goalIndex != -1) {
					// update existing goal
					vm.eTemplate.goalsActions.goals[goalIndex] = goal;
					// goal.details.goalType = goal.details.goalType || vm.goal.goalType;
				} else {
					// New goal
					goal.details.yearReviewed =
						vm.eTemplate.lcapUser.currentYear.year;
					goal.formId = $stateParams.formId; // both formId's need to be set
					goal.details.formId = $stateParams.formId; // both formId's need to be set
					vm.eTemplate.goalsActions.goals.push(goal); // push new goal
				}
				goal.details.isRolloverGoal = isRolloverGoal;
				goal.details.schoolYearId = $stateParams.schoolYearId; // Needs to be dynamic
				
				toastr.info('Saving...');
				mainService
				.addGASGoal(goal)
				.then(function (rsp) {
						if (rsp.errors === true) {throw new Error('Error from server');}
						goal.goalId = rsp.GoalId; // Set goalId twice
						goal.details.goalId = rsp.GoalId; // Set goalId twice

						goal.expectedOutcomes.forEach(function (item, i) {
							item.outcomeId = rsp.OutcomeIds[i];
						});
						goal.actions.forEach(function (item, i) {
							item.actionId = rsp.ActionIds[i];
						});

						toastr.success('Goal saved');
						hasClosed = true;
						closeModal(goal.details.status);
					})
					.catch(function (err) {
						toastr.error('Error saving goal. Please try again. ' + err);
					});
			});

			// Saving and Validation
			vm.savingAction = false;
			checkActions();

			$timeout(function () {
				vm.newGoal.details.reviewStatusId = 0;
				var saveDraft =
					status === false &&
					vm.newGoal.details != null &&
					vm.newGoal.details.goal;
				var formValid =
					form.$valid &&
					vm.actions.valid &&
					vm.newGoal.expectedOutcomes.length > 0 &&
					vm.newGoal.actions.length > 0;
				var formInvalid =
					form.$invalid ||
					(vm.actions.invalid && vm.actions.draft === 0) ||
					vm.newGoal.expectedOutcomes.length === 0;
				var goalDetailsRequired =
					!vm.newGoal.details || !vm.newGoal.details.goal;

				vm.newGoal.details.isComplete = formValid;
				if (saveDraft) {closeModal(true);}
				// Save as draft
				else if (formValid) {
					if (vm.newGoal.details.reviewStatusId === 2) {
						$ngBootbox.customDialog(getReviewEditModal());
					} else if (vm.newGoal.details.reviewStatusId === 3) {
						if (confirm(vm.lookup.ERROR_MESSAGES.APPROVED_REVIEW_CHANGE)) {
							vm.newGoal.details.reviewStatusId = 1; // Reset for re-review
							closeModal(true); // Save Valid Form
						}
					} else {
						vm.newGoal.details.isComplete = true;
						closeModal(true); // Save Valid Form
					}
				} else if (formInvalid) {
					// Invalid Form
					if (goalDetailsRequired) {
						// Goal details are required
						alert(vm.lookup.ERROR_MESSAGES.GOAL_DESCRIPTION);
						$rootScope.modalOpen = true;
					} else {
						// Save incomplete goal as draft
						if (confirm(vm.lookup.ERROR_MESSAGES.INCOMPLETE_GOAL)) {
							closeModal(false);
						} else {
							$rootScope.modalOpen = true;
						}
					}
				}
			});
		}

		function closeModal(status) {
			// Closing modal and saving changes
			$timeout(function () {
				vm.newGoal.details.status = status;
				$uibModalInstance.close(vm.newGoal, vm.form);
				$rootScope.modalOpen = false;
			});
		}

		function getReviewEditModal() {
			var modal = {
				message: vm.lookup.ERROR_MESSAGES.REQUESTED_REVIEW_CHANGES,
				buttons: {
					danger: {
						label: 'Still Working',
						className: 'btn-warning',
						callback: function () {
							vm.newGoal.details.reviewStatusId = 2;
							closeModal(true);
						},
					},
					success: {
						label: 'Ready for Review',
						className: 'btn-success',
						callback: function () {
							vm.newGoal.details.reviewStatusId = 1;
							closeModal(true);
						},
					},
				},
			};

			return modal;
		}

		function dismissModal() {
			// Cancelling modal and all changes
			if (!vm.inReview) {
				if (confirm(vm.lookup.ERROR_MESSAGES.LOST_CHANGES)) {
					$uibModalInstance.dismiss('cancel');
					$rootScope.modalOpen = false;
				} else {
					$rootScope.modalOpen = true;
				}
			} else {$uibModalInstance.dismiss('cancel');}
		}

		function setupGoals() {
			if (editableGoal) {
				mainService
					.getGAS(
						editableGoal.details.formId,
						editableGoal.details.schoolYearId,
						editableGoal.details.goalId
					)
					.then(function (rsp) {
						vm.newGoal = angular.copy(rsp);
						vm.newGoal.reviewStatusId = vm.newGoal.details.reviewStatusId || 0;

						vm.newGoal.actions.forEach(function (item, i) {
							item.isSubmitted = false;
							calculateFunds(i);
						});

						checkFeedback();
						disableYears();
						checkNew();
						var goalIndex = _.findIndex(vm.eTemplate.goalsActions.goals, {
							goalId: vm.newGoal.goalId,
						});
						vm.eTemplate.goalsActions.goals[goalIndex] = vm.newGoal;
						vm.loading = false;
						$timeout(setupDragAndDrop);
						$timeout(function(){
							$rootScope.$broadcast('validate');
						});
					})
					.catch(function () {
						toastr.error('Error opening goal, refresh and try again');
						$uibModalInstance.dismiss('cancel');
						vm.loading = false;
					});
			} else {
				vm.newGoal = {
					expectedOutcomes: [{}],
					actions: [],
					details: {
						reviewStatusId:
							vm.eTemplate.goalsActions.reviewStatusId != 0 ? 1 : 0,
					},
				};

				checkFeedback();
				disableYears();
				checkNew();
				vm.loading = false;
			}
		}

		function showOutcomeSectionValidators() {
			if(vm.newGoal.expectedOutcomes.length != 0){
				var outcomePropNames =  Object.keys(getEmptyOutcome());
				vm.newGoal.expectedOutcomes.forEach(function(outcome,i){
					outcome.valid = true;
					var j = 0;
					while (j < outcomePropNames.length) {
						if($rootScope.form[outcomePropNames[j] + i] && $rootScope.form[outcomePropNames[j] + i].$invalid === true){
							outcome.valid = false;
							j = outcomePropNames.length + 1;
						}
						j++;
					}	
				});
			}	
		}

		function showActionSectionValidators() {
			if(vm.newGoal.actions.length != 0){
				var actionPropNames =  Object.keys(getEmptyAction());
				vm.newGoal.actions.forEach(function(action,i){
					action.valid = true;
					var j = 0;
					while (j < actionPropNames.length) {
						if($rootScope.form[actionPropNames[j] + i] && $rootScope.form[actionPropNames[j] + i].$invalid === true){
							action.valid = false;
							j = actionPropNames.length + 1;
						}
						j++;
					}	
				});
			}	
		}

		function getEmptyOutcome(){
			return {
				metricsIndicators: null,
				baseline: null,
				expectedOutcomeOne: null,
				expectedOutcomeTwo: null,
				expectedOutcomeThree: null,
				desiredOutcome: null,	
			};
		}

		function getEmptyAction(){
			return {
				title: null,
				description: null,
				contributing: null,
				studentGroups: null,
				scope: null,
				unduplicatedStudentGroups: null,
				location: null,
				timeSpan: null,
				lcffFunds: null,
				otherStateFunds: null,
				localFunds: null,
				federalFunds: null,
				totalFunds: null,
				totalPersonnel: null,
				totalNonPersonnel: null,
				personnelExpense: null,
				plannedPercentageImprovedServices: null,
			};
		}
	}
})();
