(function () {
	'use strict';

	angular
		.module('app.lcap')
		.run(appRun);

	appRun.$inject = ['routerHelper', '$rootScope'];

	function appRun(routerHelper, $rootScope) {
		routerHelper.configureStates(getStates());

		$rootScope.$on('$stateChangeSuccess', function () {
			document.body.scrollTop = document.documentElement.scrollTop = 0;
		});
	}

	function getStates() {
		return [{
			state: 'LCAP',
			config: {
				url: '/LCAP/{formId:int}/{schoolYearId:int}', // maybe use state param for review or just check by usertype
				params: { review: null },
				templateUrl: 'app/LCAP/lcap.index.html',
				title: 'LCAP eTemplate',
				controller: 'LCAPCtrl',
				allowAnonymous: false,
				resolve: {
					/* @ngInject */
					user: function (dashboardService) {
						return dashboardService.getUserFromClaims()
							.then(function (rsp) {
								return rsp;
							});
					},
					/* @ngInject */
					eTemplate: function (mainService, $stateParams, $state) {
						return mainService.getTemplate($stateParams.formId, $stateParams.schoolYearId)
							.then(function (rsp) {
								if (rsp === 'Access denied') {
									$state.go('unauthorized');
								} else {
									return {
										lcapUser: rsp.user,
										planSummary: rsp.planSummary,
										budgetOverview: rsp.budgetOverview,
										stakeholderEngagement: rsp.stakeHolder,
										unduplicatedPupils: rsp.unduplicatedPupil,
										annualUpdate: {
											goals: rsp.annualUpdate,
										},
										goalsActionsServices: {
											goals: rsp.goals,
										},
										feedback: rsp.feedback,
									};
								}
								return;
							});
					},
					/* @ngInject */
					lcapYears: function (dashboardService, user) {
						return dashboardService.getSchoolYears(user.lcapCycleId)
							.then(function (rsp) {
								return rsp;
							});
					},
				},
			},
		},
		{
			state: 'LCAP.lcapFormInfo',
			config: {
				url: '/lcapFormInfo',
				templateUrl: 'app/LCAP/lcapFormInfo/lcapFormInfo.index.html',
				title: 'LCAP eTemplate Info',
				controller: 'LcapFormInfoCtrl',
				allowAnonymous: false,
				resolve: {
					/* @ngInject */
					dashboardYear: function (mainService) {
						return mainService.getDashboardYear()
							.then(function (rsp) {
								if (rsp === 3) { return 2017; }
								else if (rsp === 4) { return 2018; }
								else { return rsp; }
							});
					},
				},
			},
		},
		{
			state: 'LCAP.planSummary',
			config: {
				url: '/planSummary',
				templateUrl: 'app/LCAP/planSummary/planSummary.index.html',
				title: 'LCAP eTemplate Plan Summary',
				controller: 'PlanSummaryCtrl',
				allowAnonymous: false,
				resolve: {},
			},
		},
		{
			state: 'LCAP.budgetOverview',
			config: {
				url: '/budgetOverview',
				templateUrl: 'app/LCAP/budgetOverview/budgetOverview.index.html',
				title: 'LCAP eTemplate Budget Overview for Parents',
				controller: 'BudgetOverviewCtrl',
				allowAnonymous: false,
				resolve: {},
			},
		},
		{
			state: 'LCAP.annualUpdate',
			config: {
				url: '/annualUpdate',
				templateUrl: 'app/LCAP/annualUpdate/annualUpdate.index.html',
				title: 'LCAP eTemplate Annual Update',
				controller: 'AnnualUpdatePageCtrl', // Remove page from controller name and rename AnnualUpdatesModal controller to avoid conflictd
				allowAnonymous: false,
				resolve: {},
			},
		},
		{
			state: 'LCAP.stakeholderEngagement',
			config: {
				url: '/stakeholderEngagement',
				templateUrl: 'app/LCAP/stakeholderEngagement/stakeholderEngagement.index.html',
				title: 'LCAP eTemplate Stakeholder Engagement',
				controller: 'StakeholderEngagementCtrl',
				allowAnonymous: false,
				resolve: {},
			},
		},
		{
			state: 'LCAP.goalsActionsServices',
			config: {
				url: '/goalsActionsServices',
				templateUrl: 'app/LCAP/goalsActionsServices/goalsActionsServices.index.html',
				title: 'LCAP eTemplate Goals, Actions & Services',
				controller: 'GoalsActionsServicesPageCtrl', // Remove page from controller name and rename GASModal controller to avoid conflictd
				allowAnonymous: false,
				resolve: {},
			},
		},
		{
			state: 'LCAP.unduplicatedPupils',
			config: {
				url: '/unduplicatedPupils',
				templateUrl: 'app/LCAP/unduplicatedPupils/unduplicatedPupils.index.html',
				title: 'LCAP eTemplate Unduplicated Pupils',
				controller: 'UnduplicatedPupilsCtrl', // Remove page from controller name and rename GASModal controller to avoid conflictd
				allowAnonymous: false,
				resolve: {},
			},
		},
		{
			state: 'LCAP.submit',
			config: {
				url: '/submit',
				templateUrl: 'app/LCAP/submit/submit.template.html',
				title: 'LCAP eTemplate Submission',
				controller: 'SubmitLCAPCtrl',
				allowAnonymous: false,
				resolve: {},
			},
		},
		{
			state: 'unauthorized',
			config: {
				url: '/unauthorized',
				templateUrl: 'app/LCAP/unauthorized/unauthorized.html',
				title: 'Unauthorized',
				controller: 'UnauthorizedCtrl',
				allowAnonymous: true,
			},
		},
		];
	}
}());
