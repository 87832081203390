(function () {
  'use strict';

  angular.module('app.lcap').component('printIncreasedServices', {
    templateUrl:
      'app/LCAP2/increasedServices/print/printIncreasedServices.template.html',
    controller: PrintIncreasedServices,
    bindings: {
      eTemplate: '<',
      years: '<',
    },
  });

  /* @ngInject */
  function PrintIncreasedServices() {
    var $ctrl = this;

    $ctrl.pupil = $ctrl.eTemplate.unduplicatedPupil;
    $ctrl.currentYear = _.findWhere($ctrl.years, { isCurrentYear: true });
  }
})();
