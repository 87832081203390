(function () {
	'use strict';

	angular
		.module('app')
		.controller('IndexCtrl', indexCtrl);

	/* @ngInject */
	function indexCtrl($state, authService, $scope, $rootScope, dashboardService) {
		// Flag to enable or disable system lockdown for maintenance
		$rootScope.lockdown = false;

		var vm = this;
		vm.$onInit = activate;

		function activate() {
			vm.logout = logout;
			vm.modalOpen = false;
			vm.loading = true;
			vm.showNav = false;
			vm.modalOpen = false;

			if ($rootScope.lockdown) {authService.logOut();}
		}

		$rootScope.$on('$stateChangeSuccess', function () {
			vm.printing = $state.current.name === 'print' ?  true : false;

			if ($state.current.name !== 'login' && $state.current.name !== 'reports') {
				authService.resetAutoLogout({ cancelPrevTimeout: true }); // Reset auto logout
			}

			if ($state.current.allowAnonymous !== true || $state.current.name === 'login') {
				getUser();
			}
		});

		$scope.$watch(function () {
			return $rootScope.modalOpen;
		}, function () {
			vm.modalOpen = $rootScope.modalOpen;
		}, true);

		$scope.$watch(function () {
			return $rootScope.loading;
		}, function () {
			vm.loading = $rootScope.loading;
		}, true);

		function logout() {
			authService.logOut();
			vm.showNav = false;
			if ($rootScope.warned) {$rootScope.warned = false;}
			$state.go('login');
		}

		function getUser() {
			dashboardService.getUserFromClaims()
				.then(function (rsp) {
					vm.user = rsp;
					vm.showNav = true;
					if ($state.current.name === 'login') {
						$state.go('dashboard', { view: 'lcaps' });
					}
				})
				.catch(function () {
					vm.showNav = false;
					// $state.go('login');
				});
		}
	}
})();
