(function () {
	'use strict';

	angular
		.module('app.validation')
		.directive('formValidation', validation);

	/* @ngInject */
	function validation(validationFactory, $rootScope, $stateParams, $state) {
		var directive = {
			link: link,
			restrict: 'A',
			require: '^form',
			scope: {
				template: '=?',
			},
		};
		return directive;

		///////////////////

		function link(scope, element, _attrs, form) {
			var skipDisablingRoutes = ['printPreview'];
			if (element.data('skip')) {form.skipStatus = true;} // Used to do calc skips for goals sections
			if ($stateParams.review === true && skipDisablingRoutes.indexOf($state.current.name) !== -1) {validationFactory.disableForm();}

			validationFactory.validateAllFields(form, element, 0); // Validate all fields onload
			if (form.$name != 'feedback') {$rootScope.form = form;} // Set $rootScope for sidebar to use when navigating

			if (scope.template != null) {form.reviewStatusId = scope.template.reviewStatusId;}

			element.on('change keyup', function (e) {
				$rootScope.$broadcast('validate', form);
				form.change = true;
				validationFactory.validateElement(form, e);
				validationFactory.charCount(e);
			});

			element.on('submit', function () {
				// allows passing through without ngBootbox confirm alerts
				var ignoreChange = form.reviewStatusId != 3 && form.reviewStatusId != 2;
				if (ignoreChange) {form.change = false;}
				validationFactory.validateForm(form);
			});
		}
	}
}());
