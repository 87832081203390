(function () {
	'use strict';

	angular
		.module('app.lcap')
		.component('printAnnualUpdateLatest', {
			templateUrl: 'app/LCAP2/annualUpdate/print/printAnnualUpdate.template.html',
			controller: PrintAnnualUpdateCtrl,
			bindings: {
				eTemplate: '<',
			},
		});

	/* @ngInject */
	function PrintAnnualUpdateCtrl() {
		var $ctrl = this;
		$ctrl.annualGoals = $ctrl.eTemplate.annualUpdate;
	}
})();
