(function () {
	'use strict';

	angular
		.module('app')
		.controller('SubmitLCAPCtrl', SubmitLCAPCtrl);

	/* @ngInject */
	function SubmitLCAPCtrl($rootScope, $state, $stateParams, latestLookup, lcapLatestLookup, eTemplate, lcapYears, mainService, user, lookup) {
		var vm = this;

		vm.$onInit = activate;
		vm.eTemplate = eTemplate;
		vm.lcapYears = lcapYears;
		// vm.submit = submit;
		vm.currentYearStatus = vm.eTemplate.lcapUser.currentYearStatus;
		vm.currentYear = vm.eTemplate.lcapUser.currentYear;
		vm.inDraft = $stateParams.review != true;
		vm.inReview = $stateParams.review === true;
		vm.validity = $rootScope.validity;
		vm.user = user;
		vm.useLCAP3 = window.location.href.indexOf('LCAP3') > -1;
		vm.useLatest = window.location.href.indexOf('LCAP4') > -1;
		vm.submitLcap = submitLcap;
		vm.leaName = eTemplate.lcapUser.leaName;
		vm.approved = vm.validity.reviewApproved === vm.totalForms;
		vm.unapproved = vm.validity.reviewUnapproved >= 1 && vm.validity.reviewUnapproved + vm.validity.reviewApproved === vm.totalForms;
		vm.charter = eTemplate.lcapUser.charter == 'Y' ? true : false;


		function activate() {
			vm.totalForms = $stateParams.schoolYearId == 5 ? 9 : $stateParams.schoolYearId <= 2 ? (vm.useLatest) ? 7 : 6 : 7;

			vm.lookup = vm.useLatest ? lcapLatestLookup : vm.useLCAP3 ? latestLookup : lookup;

			if (vm.currentYearStatus.statusId === 2) {vm.lcapStatus = 'Review';}
			else if (vm.currentYearStatus.statusId === 3) {vm.lcapStatus = 'Finalization';}

		}

		function moveForward(int) {
			if (vm.eTemplate.lcapUser.yearOneStatus === int) {vm.eTemplate.lcapUser.yearOneStatus = int + 1;}
			if (vm.eTemplate.lcapUser.yearTwoStatus === int) {vm.eTemplate.lcapUser.yearTwoStatus = int + 1;}
			if (vm.eTemplate.lcapUser.yearThreeStatus === int) {vm.eTemplate.lcapUser.yearThreeStatus = int + 1;}
		}

		function moveBackward() {
			if (vm.eTemplate.lcapUser.yearOneStatus === 3) {vm.eTemplate.lcapUser.yearOneStatus = 2;}
			if (vm.eTemplate.lcapUser.yearTwoStatus === 3) {vm.eTemplate.lcapUser.yearTwoStatus = 2;}
			if (vm.eTemplate.lcapUser.yearThreeStatus === 3) {vm.eTemplate.lcapUser.yearThreeStatus = 2;}
		}

		function submitLcap(status) {


			if (status === 'review' && vm.validity.validForms === vm.totalForms) {
				if (confirm(vm.lookup.SUBMIT_LCAP_MESSAGES.toReview)) {
					moveForward(2);
					finalSubmit();
				}
			} else if (status === 'changes') {
				if (confirm(vm.lookup.SUBMIT_LCAP_MESSAGES.changesNeeded)) {
					moveBackward();
					finalSubmit();
				}
			} else if (status === 'approve') {
				if (confirm(vm.lookup.SUBMIT_LCAP_MESSAGES.approved)) {
					moveForward(3);
					finalSubmit();
				}
			}
		}

		function finalSubmit() {
			$rootScope.loading = true;
			mainService.submitLCAP(vm.eTemplate.lcapUser)
				.then(function () {
					$rootScope.loading = false;
					alert(vm.lookup.SUBMIT_LCAP_MESSAGES.success.replace('%leaName%', vm.leaName));
					$state.go('dashboard');
				});
		}
	}
})();
