(function() {
    'use strict';

    angular
        .module('dashboard')
        .controller('applicantFormController', applicantFormController);
        
    /* @ngInject */
    function applicantFormController(simpleStorageService, dashboardService, $uibModalInstance, $scope, $timeout, validationFactory) {
        var vm = this;
        vm.$onInit = activate;
        vm.addApplicant = addApplicant;
        vm.dismissModal = dismissModal;
        vm.applicant = {};
        vm.onSelect = onSelect;
        ///////////////////////

        function activate() {
            $timeout(function() {
                vm.form = $scope.applicantForm;
                vm.form.customValidator = addApplicant; 
                vm.user = simpleStorageService.getItem('user');
                if (vm.user.userType === 'Coordinator' && vm.user.doc === '00') {
                    dashboardService.getCountyDistricts(vm.user.cdsCode)
                        .then(function(rsp) {
                            vm.leas = rsp;
                        });
                } else {
                    dashboardService.getCOEs()
                        .then(function(rsp) {
                            vm.leas = rsp;
                        });
                }
            });
        }

        function addApplicant() {
            if ((vm.user.charter === 'Y' && vm.applicant.userType === 'Reviewer') || vm.applicant.userType === 'Submitter') {
                vm.applicant.school = vm.user.school;
                vm.applicant.cdsCode = vm.user.cdsCode;
            } else if (vm.school) {
                vm.applicant.school = vm.school.district;
                vm.applicant.cdsCode = vm.school.CDSCode;
            }
            
            if (vm.applicant.email) {vm.applicant.email = vm.applicant.email.toLowerCase();}

            if (vm.form.$valid) {
                dashboardService.addApplicant(vm.applicant, vm.user.userId, vm.user.userType)
                    .then(function() {
                        $uibModalInstance.close();
                    });
            } else if (!vm.applicant.school) {
                onSelect(null, null, null, null, false); // make false
            }
        }

        function onSelect(item, model, label, event, validity) {
            var $el = $('#lea');
            validationFactory.setGlyph($el, validity);
        }

        function dismissModal() {
            $uibModalInstance.dismiss();
        }
    }
})();