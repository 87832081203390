(function () {
	'use strict';

	angular
		.module('app.lcap')
		.component('printAnnualUpdateSupplement4', {
			templateUrl: 'app/LCAP4/annualUpdateSupplement/print/printAnnualUpdateSupplement.index.html',
			controller: PrintAnnualUpdateSupplement4Ctrl,
			bindings: {
				eTemplate: '<',
			},
		});

	/* @ngInject */
	function PrintAnnualUpdateSupplement4Ctrl() {
		var $ctrl = this;

		$ctrl.$onInit = activate;

		/////////////////////

		function activate() {
			$ctrl.annualUpdateSupplement = $ctrl.eTemplate.annualUpdateSupplement;
		}
	}
})();
