(function () {
	'use strict';

	angular
		.module('app')
		.controller('videoModalCtrl', videoModalCtrl);

	/* @ngInject */
	function videoModalCtrl($uibModalInstance, $sce, videoId, title, spanish, lookup) {
		var vm = this;

		vm.$onInit = activate;
		vm.lookup = lookup;
		vm.closeModal = closeModal;
		vm.title = title;
		vm.spanish = spanish;
		vm.viewEnglish = viewEnglish;
		vm.viewSpanish = viewSpanish;

		//////////////////////////////

		function activate() {
			viewEnglish();
		}

		function closeModal() {
			$uibModalInstance.dismiss('cancel');
		}

		function viewSpanish() {
			vm.video = _.findWhere(lookup.VIDEOS, { id: spanish });
			vm.src = $sce.trustAsResourceUrl(vm.video.src);

			viewingEnOrEs();
		}

		function viewEnglish() {
			vm.video = _.findWhere(lookup.VIDEOS, { id: videoId });
			vm.src = $sce.trustAsResourceUrl(vm.video.src);

			if (vm.spanish) {viewingEnOrEs();}
		}

		function viewingEnOrEs() {
			if (vm.video.id === spanish) {vm.showEnglish = true;}
			else {vm.showEnglish = false;}
		}
	}
})();
