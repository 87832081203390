(function () {
	'use strict';

	angular.module('app.lcap').component('feedbackLatest5', {
		templateUrl: 'app/LCAP5/shared/feedback/feedback.template.html',
		controller: LatestFeedback5Component,
		bindings: {
			template: '=',
			goalId: '<?',
		},
	});

	/* @ngInject */
	function LatestFeedback5Component(
		$rootScope,
		mainService,
		$stateParams,
		$state,
		lcapLatestLookup,
		simpleStorageService
	) {
		var $ctrl = this;

		$ctrl.$onInit = activate;

		/////////////////////////////

		var currentState = $state.current.name.split('.').slice(1)[0];
		var section = _.findWhere(lcapLatestLookup.LCAP_SIDEBAR, { formName: currentState });

		function activate() {
			$ctrl.addFeedback = addFeedback;
			$ctrl.user = simpleStorageService.getItem('user');
			$ctrl.edit = edit;
			$ctrl.cancelEdit = cancelEdit;
			$ctrl.feedback = {};
			$ctrl.deleteFeedback = deleteFeedback;

			getFeedback();
		}

		function addFeedback(feedback) {
			feedback.formId = $stateParams.formId;
			feedback.sectionId = section.sectionId;
			feedback.schoolYearId = $stateParams.schoolYearId;
			feedback.userId = $ctrl.user.userId;

			if ($ctrl.goalId) {feedback.goalId = $ctrl.goalId;}

			mainService.addFeedback(feedback).then(function () {
				$ctrl.editing = false;
				getFeedback();
				$rootScope.$broadcast('saveComment');
			});
		}

		function edit(feedback) {
			$ctrl.editing = true; // change submit button to edit button
			$ctrl.feedback.comment = feedback.comment;
			$ctrl.feedback.commentId = feedback.commentId;
		}

		function cancelEdit() {
			$ctrl.editing = false;
			$ctrl.feedback.comment = '';
			$ctrl.feedback.commentId = 0;
		}

		function deleteFeedback(feedback) {
			if (confirm('Are you sure you want to delete this comment?')) {
				mainService.deleteFeedback(feedback.commentId).then(function () {
					getFeedback();
				});
			}
		}

		function getFeedback() {

			if (section) {
				mainService
					.getFeedback(
						$stateParams.formId,
						section.sectionId,
						$stateParams.schoolYearId
					)
					.then(function (rsp) {
						if ($ctrl.goalId)
							{$ctrl.feedbackArray = _.where(rsp, { goalId: $ctrl.goalId });}
						else {$ctrl.feedbackArray = rsp;}

						$ctrl.feedback.comment = '';
					});
			}
		}
	}
})();
