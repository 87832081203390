(function () {
  'use strict';

  angular.module('app.lcap').run(appRun);

  appRun.$inject = ['routerHelper', '$rootScope'];

  function appRun(routerHelper, $rootScope) {
    routerHelper.configureStates(getStates());

    $rootScope.$on('$stateChangeSuccess', function () {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    });
  }

  function getStates() {
    return [
      {
        state: 'LCAP2',
        config: {
          url: '/LCAP2/{formId:int}/{schoolYearId:int}', // maybe use state param for review or just check by usertype
          params: { review: null },
          templateUrl: 'app/LCAP2/lcap.index.html',
          title: 'LCAP eTemplate',
          controller: 'LCAP2Ctrl',
          allowAnonymous: false,
          resolve: {
            /* @ngInject */
            user: function (dashboardService) {
              return dashboardService.getUserFromClaims().then(function (rsp) {
                return rsp;
              });
            },
            /* @ngInject */
            eTemplate: function (mainService, $stateParams, $state) {
              return mainService
                .getTemplate($stateParams.formId, $stateParams.schoolYearId)
                .then(function (rsp) {
                  if (rsp === 'Access denied') {
                    $state.go('unauthorized');
                  } else {
                    return {
                      lcapUser: rsp.user,
                      planSummary: rsp.planSummary,
                      budgetOverview: rsp.budgetOverview,
                      stakeholderEngagement: rsp.stakeHolder,
                      unduplicatedPupils: rsp.unduplicatedPupil,
                      annualUpdate: {
                        goals: rsp.annualUpdate,
                      },
                      goalsActions: {
                        goals: rsp.goals,
                      },
                      feedback: rsp.feedback,
                    };
                  }
                  return;
                });
            },
            /* @ngInject */
            lcapYears: function (dashboardService, user) {
              return dashboardService
                .getSchoolYears(user.lcapCycleId)
                .then(function (rsp) {
                  return rsp;
                });
            },
          },
        },
      },
      {
        state: 'LCAP2.annualUpdate',
        config: {
          url: '/annualUpdate',
          templateUrl: 'app/LCAP2/annualUpdate/annualUpdate.index.html',
          title: 'LCAP eTemplate Annual Update',
          controller: 'AnnualUpdatePage2Ctrl', // Remove page from controller name and rename AnnualUpdatesModal controller to avoid conflictd
          allowAnonymous: false,
          resolve: {},
        },
      },
      {
        state: 'LCAP2.expenditures',
        config: {
          url: '/expenditures',
          templateUrl: 'app/LCAP2/expenditures/expenditures.html',
          title: 'LCAP eTemplate Expenditure Tables',
          controller: 'ExpenditureTableCtrl',
          allowAnonymous: false,
          resolve: {},
        },
      },
      {
        state: 'LCAP2.budgetOverview',
        config: {
          url: '/budgetOverview',
          templateUrl: 'app/LCAP2/budgetOverview/budgetOverview.index.html',
          title: 'LCAP eTemplate Budget Overview for Parents',
          controller: 'BudgetOverview2Ctrl',
          allowAnonymous: false,
          resolve: {},
        },
      },
      {
        state: 'LCAP2.goalsActions',
        config: {
          url: '/goalsActions',
          templateUrl: 'app/LCAP2/goalsActions/goalsActions.index.html',
          title: 'LCAP eTemplate Goals, Actions & Services',
          controller: 'GoalsActionsPageCtrl', // Remove page from controller name and rename GASModal controller to avoid conflictd
          allowAnonymous: false,
          resolve: {},
        },
      },
      {
        state: 'LCAP2.lcapFormInfo',
        config: {
          url: '/lcapFormInfo',
          templateUrl: 'app/LCAP2/lcapFormInfo/lcapFormInfo.index.html',
          title: 'LCAP eTemplate Info',
          controller: 'LcapFormInfo2Ctrl',
          allowAnonymous: false,
          resolve: {
            /* @ngInject */
            dashboardYear: function (mainService) {
              return mainService.getDashboardYear().then(function (rsp) {
                if (rsp === 5) {
                  return 2020;
                } else if (rsp === 6) {
                  return 2021;
                } else {
                  return rsp;
                }
              });
            },
          },
        },
      },
      {
        state: 'LCAP2.planSummary',
        config: {
          url: '/planSummary',
          templateUrl: 'app/LCAP2/planSummary/planSummary.index.html',
          title: 'LCAP eTemplate Plan Summary',
          controller: 'PlanSummary2Ctrl',
          allowAnonymous: false,
          resolve: {},
        },
      },
      {
        state: 'LCAP2.printPreview',
        config: {
          url: '/printPreview',
          templateUrl: 'app/LCAP2/printPreview/printPreview.html',
          title: 'LCAP eTemplate Print Preview',
          controller: 'PrintPreviewCtrl',
          allowAnonymous: false,
          resolve: {
            /* @ngInject */
            preview: function (previewerService, $stateParams) {
              return previewerService.getPreviewerId($stateParams.formId).then(function (rsp) {
                return rsp || '';
              });
            },
          },
        },
      },
      {
        state: 'LCAP2.submit',
        config: {
          url: '/submit-lcap',
          templateUrl: 'app/LCAP/submit/submit.template.html',
          title: 'LCAP eTemplate Submission',
          controller: 'SubmitLCAPCtrl',
          allowAnonymous: false,
          resolve: {},
        },
      },
      {
        state: 'LCAP2.stakeholderEngagement',
        config: {
          url: '/stakeholderEngagement',
          templateUrl:
            'app/LCAP2/stakeholderEngagement/stakeholderEngagement.index.html',
          title: 'LCAP eTemplate Stakeholder Engagement',
          controller: 'StakeholderEngagement2Ctrl',
          allowAnonymous: false,
          resolve: {},
        },
      },
      {
        state: 'LCAP2.increasedServices',
        config: {
          url: '/increasedServices',
          templateUrl:
            'app/LCAP2/increasedServices/increasedServices.index.html',
          title:
            'Increased or Improved Services for Foster Youth, English Learners, and Low-income Students',
          controller: 'IncreasedServicesCtrl', // Remove page from controller name and rename GASModal controller to avoid conflictd
          allowAnonymous: false,
          resolve: {},
        },
      },
    ];
  }
})();
