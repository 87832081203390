(function() {
  'use strict';

  angular.module('app').controller('LcapFormInfo5Ctrl', LcapFormInfo5Ctrl);

  /* @ngInject */
  function LcapFormInfo5Ctrl(
    $rootScope,
    $timeout,
    eTemplate,
    dashboardYear,
    $stateParams,
    user
  ) {
    var vm = this;

    vm.$onInit = activate;
    vm.eTemplate = eTemplate;
    vm.dashboardYear = dashboardYear;
    vm.inReview = $stateParams.review;
    vm.user = user;

    ////////////////////////////////

    function activate() {
      vm.dashboardUrl =
        'https://caschooldashboard.org/reports/' +
        eTemplate.lcapUser.cdsCode +
        '/' +
        vm.dashboardYear;
      $timeout(checkFeedback);
    }

    function checkFeedback() {
      vm.showFeedback =
        (vm.inReview &&
          vm.eTemplate.lcapUser.currentYearStatus.statusId === 3) ||
        vm.eTemplate.lcapUser.reviewStatusId != 0;
      $rootScope.$broadcast('showFeedback', vm.showFeedback);
    }
  }
})();
