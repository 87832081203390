(function () {
	'use strict';

	angular
		.module('app')
		.run(appRun);

	/* @ngInject */
	function appRun(routerHelper, $rootScope) {
		routerHelper.configureStates(getStates(), '/login');

		$rootScope.$on('$stateChangeSuccess', function () {
			document.body.scrollTop = document.documentElement.scrollTop = 0;
		});
	}

	function getStates() {
		return [
			{
				state: 'login',
				config: {
					url: '/login',
					templateUrl: 'app/login/login.html',
					title: 'Login Page',
					controller: 'LoginCtrl',
					allowAnonymous: true,
				},
			},
			{
				state: 'generatePdf',
				config: {
					url: '/generatePdf/{formId:int}/{schoolYearId:int}?section',
					templateUrl: 'app/generatePdf/generatePdf.template.html',
					title: 'Generate LCAP PDF',
					controller: 'GeneratePdfCtrl',
					allowAnonymous: false,
					resolve: {
						/* @ngInject */
						user: function (dashboardService) {
							return dashboardService.getUserFromClaims();
						},
					},
				},
			},
			{
				state: 'report/2021-22',
				config: {
					url: '/reports/2021-22/{guid}',
					templateUrl: 'app/reports/2021-22/reports.html',
					title: 'Reports 2021-22',
					controller: 'Reports202122Ctrl',
					allowAnonymous: true,
					resolve: {
						/* @ngInject */
						previewDetails: function ($stateParams, previewerService) {
							return previewerService.getPreviewDetails($stateParams.guid);
						},
						/* @ngInject */
						dataEntryTable: function ($stateParams, previewerService) {
							return previewerService.getDataEntryTable($stateParams.guid);
						},
						/* @ngInject */
						totalExpenditures: function ($stateParams, previewerService) {
							return previewerService.getTotalExpenditures($stateParams.guid);
						},
						/* @ngInject */
						contributingExpenditures: function ($stateParams, previewerService) {
							return previewerService.getContributingExpenditures($stateParams.guid);
						},
						/* @ngInject */
						annualUpdateExpenditures: function ($stateParams, previewerService) {
							return previewerService.getAnnualUpdateExpenditures($stateParams.guid);
						},
						/* @ngInject */
						annualUpdateContributingActionsExpenditures: function ($stateParams, previewerService) {
							return previewerService.getAnnualUpdateContributingActionsExpenditures($stateParams.guid);
						},
						/* @ngInject */
						lcffCarryoverExpenditures: function ($stateParams, previewerService) {
							return previewerService.getLCFFCarryoverExpenditures($stateParams.guid);
						},
						/* @ngInject */
						printDetails: function (previewDetails, dataEntryTable, totalExpenditures, contributingExpenditures,
							annualUpdateExpenditures, annualUpdateContributingActionsExpenditures, lcffCarryoverExpenditures) {
							previewDetails.dataEntryDetails = dataEntryTable;
							previewDetails.totalExpenditureDetails = totalExpenditures;
							previewDetails.contributingExpenditures = contributingExpenditures;
							previewDetails.annualUpdateExpenditures = annualUpdateExpenditures;
							previewDetails.annualUpdateContributingActionsExpenditures = annualUpdateContributingActionsExpenditures;
							previewDetails.lcffCarryoverExpenditures = lcffCarryoverExpenditures;
							return previewDetails;
						},
						/* @ngInject */
						lcapYears: function (dashboardService) {
							return dashboardService.getSchoolYears(0);
						},
						/* @ngInject */
						userType: function (dashboardService) {
							return dashboardService.getUserFromClaims()
								.then(function (user) {
									return user.userType;
								})
								.catch(function () {
									return 'public';
								});
						},
					},
				},
			},
			{
				state: 'report-budget/2021-22',
				config: {
					url: '/reports/budget/2021-22/{guid}',
					templateUrl: 'app/reports-budget/2021-22/reports-budget.html',
					title: 'Report 2021-22',
					controller: 'ReportBudget202122Ctrl',
					allowAnonymous: true,
					resolve: {
						/* @ngInject */
						previewDetails: function ($stateParams, previewerService) {
							return previewerService.getPreviewDetailsBudget($stateParams.guid);
						},
						/* @ngInject */
						printDetails: function (previewDetails) {
							return previewDetails;
						},
						/* @ngInject */
						lcapYears: function (dashboardService) {
							return dashboardService.getSchoolYears(2);
						},
					},
				},
			},
			{
				state: 'report/2022-23',
				config: {
					url: '/reports/2022-23/{guid}',
					templateUrl: 'app/reports/2022-23/reports.html',
					title: 'Reports 2022-23',
					controller: 'Reports202223Ctrl',
					allowAnonymous: true,
					resolve: {
						/* @ngInject */
						previewDetails: function ($stateParams, previewerService) {
							return previewerService.getPreviewDetails($stateParams.guid);
						},
						/* @ngInject */
						dataEntryTable: function ($stateParams, previewerService) {
							return previewerService.getDataEntryTable($stateParams.guid);
						},
						/* @ngInject */
						totalExpenditures: function ($stateParams, previewerService) {
							return previewerService.getTotalExpenditures($stateParams.guid);
						},
						/* @ngInject */
						contributingExpenditures: function ($stateParams, previewerService) {
							return previewerService.getContributingExpenditures($stateParams.guid);
						},
						/* @ngInject */
						annualUpdateExpenditures: function ($stateParams, previewerService) {
							return previewerService.getAnnualUpdateExpenditures($stateParams.guid);
						},
						/* @ngInject */
						annualUpdateContributingActionsExpenditures: function ($stateParams, previewerService) {
							return previewerService.getAnnualUpdateContributingActionsExpenditures($stateParams.guid);
						},
						/* @ngInject */
						lcffCarryoverExpenditures: function ($stateParams, previewerService) {
							return previewerService.getLCFFCarryoverExpenditures($stateParams.guid);
						},
						/* @ngInject */
						printDetails: function (previewDetails, dataEntryTable, totalExpenditures, contributingExpenditures,
							annualUpdateExpenditures, annualUpdateContributingActionsExpenditures, lcffCarryoverExpenditures) {
							previewDetails.dataEntryDetails = dataEntryTable;
							previewDetails.totalExpenditureDetails = totalExpenditures;
							previewDetails.contributingExpenditures = contributingExpenditures;
							previewDetails.annualUpdateExpenditures = annualUpdateExpenditures;
							previewDetails.annualUpdateContributingActionsExpenditures = annualUpdateContributingActionsExpenditures;
							previewDetails.lcffCarryoverExpenditures = lcffCarryoverExpenditures;
							return previewDetails;
						},
						/* @ngInject */
						lcapYears: function (dashboardService) {
							return dashboardService.getSchoolYears(0);
						},
						/* @ngInject */
						userType: function (dashboardService) {
							return dashboardService.getUserFromClaims()
								.then(function (user) {
									return user.userType;
								})
								.catch(function () {
									return 'public';
								});
						},
					},
				},
			},
			{
				state: 'report-budget/2022-23',
				config: {
					url: '/reports/budget/2022-23/{guid}',
					templateUrl: 'app/reports-budget/2022-23/reports-budget.html',
					title: 'Report 2022-23',
					controller: 'ReportBudget202223Ctrl',
					allowAnonymous: true,
					resolve: {
						/* @ngInject */
						previewDetails: function ($stateParams, previewerService) {
							return previewerService.getPreviewDetailsBudget($stateParams.guid);
						},
						/* @ngInject */
						printDetails: function (previewDetails) {
							return previewDetails;
						},
						/* @ngInject */
						lcapYears: function (dashboardService) {
							return dashboardService.getSchoolYears(2);
						},
					},
				},
			},
			{
				state: 'report/2023-24',
				config: {
					url: '/reports/2023-24/{guid}',
					templateUrl: 'app/reports/2023-24/reports.html',
					title: 'Reports 2023-24',
					controller: 'Reports202324Ctrl',
					allowAnonymous: true,
					resolve: {
						/* @ngInject */
						previewDetails: function ($stateParams, previewerService) {
							return previewerService.getPreviewDetails($stateParams.guid);
						},
						/* @ngInject */
						dataEntryTable: function ($stateParams, previewerService) {
							return previewerService.getDataEntryTable($stateParams.guid);
						},
						/* @ngInject */
						totalExpenditures: function ($stateParams, previewerService) {
							return previewerService.getTotalExpenditures($stateParams.guid);
						},
						/* @ngInject */
						contributingExpenditures: function ($stateParams, previewerService) {
							return previewerService.getContributingExpenditures($stateParams.guid);
						},
						/* @ngInject */
						annualUpdateExpenditures: function ($stateParams, previewerService) {
							return previewerService.getAnnualUpdateExpenditures($stateParams.guid);
						},
						/* @ngInject */
						annualUpdateContributingActionsExpenditures: function ($stateParams, previewerService) {
							return previewerService.getAnnualUpdateContributingActionsExpenditures($stateParams.guid);
						},
						/* @ngInject */
						lcffCarryoverExpenditures: function ($stateParams, previewerService) {
							return previewerService.getLCFFCarryoverExpenditures($stateParams.guid);
						},
						/* @ngInject */
						printDetails: function (previewDetails, dataEntryTable, totalExpenditures, contributingExpenditures,
							annualUpdateExpenditures, annualUpdateContributingActionsExpenditures, lcffCarryoverExpenditures) {
							previewDetails.dataEntryDetails = dataEntryTable;
							previewDetails.totalExpenditureDetails = totalExpenditures;
							previewDetails.contributingExpenditures = contributingExpenditures;
							previewDetails.annualUpdateExpenditures = annualUpdateExpenditures;
							previewDetails.annualUpdateContributingActionsExpenditures = annualUpdateContributingActionsExpenditures;
							previewDetails.lcffCarryoverExpenditures = lcffCarryoverExpenditures;
							return previewDetails;
						},
						/* @ngInject */
						lcapYears: function (dashboardService) {
							return dashboardService.getSchoolYears(2);
						},
						/* @ngInject */
						userType: function (dashboardService) {
							return dashboardService.getUserFromClaims()
								.then(function (user) {
									return user.userType;
								})
								.catch(function () {
									return 'public';
								});
						},
					},
				},
			},
			{
				state: 'report-budget/2023-24',
				config: {
					url: '/reports/budget/2023-24/{guid}',
					templateUrl: 'app/reports-budget/2023-24/reports-budget.html',
					title: 'Report 2023-24',
					controller: 'ReportBudget202324Ctrl',
					allowAnonymous: true,
					resolve: {
						/* @ngInject */
						previewDetails: function ($stateParams, previewerService) {
							return previewerService.getPreviewDetailsBudget($stateParams.guid);
						},
						/* @ngInject */
						printDetails: function (previewDetails) {
							return previewDetails;
						},
						/* @ngInject */
						lcapYears: function (dashboardService) {
							return dashboardService.getSchoolYears(0);
						},
					},
				},
			},
			{
				state: 'report-supplement',
				config: {
					url: '/reports/supplement/{guid}',
					templateUrl: 'app/reports-supplement/reports-supplement.html',
					title: 'Supplement to Annual Update',
					controller: 'ReportsSupplementCtrl',
					allowAnonymous: true,
					resolve: {
						/* @ngInject */
						printDetails: function ($stateParams, previewerService) {
							return previewerService.getPreviewDetails($stateParams.guid);
						},
						/* @ngInject */
						lcapYears: function (dashboardService) {
							return dashboardService.getSchoolYears(0);
						},
						/* @ngInject */
						userType: function (dashboardService) {
							return dashboardService.getUserFromClaims()
								.then(function (user) {
									return user.userType;
								})
								.catch(function () {
									return 'public';
								});
						},
					},
				},
			},
			{
				state: 'print',
				config: {
					url: '/print/{formId:int}/{schoolYearId:int}/{userType:string}/{userId:int}?section',
					templateUrl: 'app/generatePdf/print/print.template.html',
					title: 'Printing PDF',
					controller: 'PrintCtrl',
					allowAnonymous: true,
					resolve: {
						/* @ngInject */
						eTemplate: function (mainService, $stateParams) {
							return mainService.getTemplate($stateParams.formId, $stateParams.schoolYearId, $stateParams.userType, $stateParams.userId)
								.then(function (rsp) {
									return {
										lcapUser: rsp.user,
										budgetOverview: rsp.budgetOverview,
										planSummary: rsp.planSummary,
										stakeholderEngagement: rsp.stakeHolder,
										unduplicatedPupils: rsp.unduplicatedPupil,
										annualUpdate: rsp.annualUpdate,
										annualUpdateGoals: rsp.annualUpdateGoals,
										goalsActionsServices: {
											goals: rsp.goals,
										},
										feedback: rsp.feedback,
									};
								});
						},
						/* @ngInject */
						lcapYears: function (dashboardService, eTemplate) {
							return dashboardService.getSchoolYears(eTemplate.lcapUser.lcapCycleId);
						},
					},
				},
			},
		];
	}
}());
