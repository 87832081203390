(function () {
  'use strict';

  angular
    .module('app')
    .controller('OverallAnalysisPage5Ctrl', OverallAnalysisPage5Ctrl);

  /* @ngInject */
  function OverallAnalysisPage5Ctrl(
    $rootScope,
    $scope,
    $stateParams,
    $timeout,
    eTemplate,
    lcapYears
  ) {
    var vm = this;

    vm.$onInit = activate;
    vm.eTemplate = eTemplate;
    vm.validity = $rootScope.validity;
    vm.inReview = $stateParams.review;
    vm.lcapYears = lcapYears;

    ////////////////////////////////

    function activate() {
      $timeout(function () {
        vm.form = $scope.overallAnalysis;
        vm.form.reviewStatusId = vm.eTemplate.overallAnalysis.reviewStatusId;

        checkFeedback();
      });
    }

    function checkFeedback() {
      vm.showFeedback = (vm.inReview && vm.eTemplate.lcapUser.currentYearStatus.statusId === 3) || vm.eTemplate.overallAnalysis.reviewStatusId != 0;
      $rootScope.$broadcast('showFeedback', vm.showFeedback);
    }
  }
})();
