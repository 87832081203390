(function () {
	'use strict';

	angular
		.module('dashboard')
		.component('editAccount', {
			templateUrl: 'app/dashboard/lcapUsers/editAccount/editAccount.template.html',
			controller: EditAccount,
			bindings: {
				user: '<',
				userModal: '<?',
			},
		});

	/* @ngInject */
	function EditAccount($scope, $state, $timeout, simpleStorageService, dashboardService) {
		var $ctrl = this;

		$ctrl.$onInit = activate;

		function activate() {
			$timeout(function () {
				$ctrl.myAccountInfoForm = $scope.myAccountInfo;
				$ctrl.myAccountInfoForm.customValidator = updateUser;
			});
		}

		function updateUser() {
			dashboardService.updateUser($ctrl.user)
				.then(function () {
					toastr.success('Your profile has been updated.');
					if ($ctrl.userModal) {$scope.$parent.vm.closeModal();} // Editing different user, close modal
					else {simpleStorageService.setItem('user', $ctrl.user);} // editing your own account, update local storage, no modal to close

					$state.go('.', {}, { reload: true }); // reload view for updates
				});
		}
	}
})();
