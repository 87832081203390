(function() {
    'use strict';

    angular
        .module('app.lcap')
        .component('printPlanSummary', {
            templateUrl: 'app/LCAP/planSummary/print/printPlanSummary.template.html',
            controller: PrintPlanSummary,
            bindings: {
                eTemplate: '<',
                years: '<',
            },
        });

    /* @ngInject */
    function PrintPlanSummary() {
        var $ctrl = this;
        $ctrl.plan = $ctrl.eTemplate.planSummary;
        $ctrl.schoolYearId = $ctrl.plan.schoolYearId;
    }
})();