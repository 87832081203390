(function () {
	'use strict';

	angular
		.module('app.lcap')
		.component('instructionsButton', {
			templateUrl: 'app/LCAP/shared/instructions/button/instructionsButton.template.html',
			controller: instructionsButtonComponent,
			transclude: true,
			bindings: {
				link: '<',
				text: '<',
			},
		});

	/* @ngInject */
	function instructionsButtonComponent() {
		// $ctrl.openInstructions = openInstructions;

		/////////////////////////////

		// function openInstructions(section) {
		//     var modalInstance = $uibModal.open({
		//         controller: 'InstructionsCtrl',
		//         controllerAs: 'vm',
		//         size: 'lg',
		//         templateUrl: 'app/LCAP/shared/instructions/instructionsModal/' + section + '.html',
		//         windowTopClass: 'instructions-modal'
		//     });
		// }
	}
})();
