(function () {
	'use strict';

	angular
		.module('app.lcap')
		.component('reviewButtonsLatest4', {
			templateUrl: 'app/LCAP4/shared/reviewBtns/reviewBtns.template.html',
			controller: ReviewButtons4,
			bindings: {
				form: '<',
				eTemplate: '=',
				goal: '<?',
			},
		});

	/* @ngInject */
	function ReviewButtons4($rootScope, $timeout, $state, $stateParams, validationFactory, mainService, lcapLatestLookup, $scope, simpleStorageService) {
		var $ctrl = this;
		
		$ctrl.$onInit = activate;

		////////////////////////

		function activate() {
			$ctrl.formsList = angular.copy(lcapLatestLookup.FORMS_LIST);
			_.forEach($ctrl.formsList, function(form) {
				if(form && form.skipMenuItem){
					$ctrl.formsList.splice(_.indexOf($ctrl.formsList, form), 1);
				}
			});
			$ctrl.user = simpleStorageService.getItem('user');
			$ctrl.submitReviewStatus = submitReviewStatus;
			$ctrl.showButtons = $stateParams.review && $ctrl.user.userType === 'Reviewer' && $ctrl.eTemplate.lcapUser.currentYearStatus.statusId === 3;
			// $ctrl.showButtons = true;
			$ctrl.totalForms = $stateParams.schoolYearId <= 2 || $stateParams.schoolYearId == 5 ? 9 : 7;

			$ctrl.form.$name = $ctrl.form.$name
			.replace('goalsActionsServices', 'goalsActions')
			.replace('goalsActionsGoal', 'goalsActions')
			.replace('unduplicatedPupils', 'increasedServices');

			$ctrl.formLookup = _.findWhere($ctrl.formsList, { formName: $ctrl.form.$name });
			$ctrl.formLookupIndex = $ctrl.formsList.indexOf($ctrl.formLookup);

			getFormObj();
		}

		function getFormObj() {
			if ($ctrl.goal === undefined) {$ctrl.formObj = $ctrl.eTemplate[$ctrl.formLookup.formObj.split('.').slice(2)[0]];}
			else {$ctrl.formObj = $ctrl.goal;}
		}

		function setReviewStatusId(status) {
			if ($ctrl.goal === undefined) {$ctrl.formObj.reviewStatusId = status;}
			else {$ctrl.formObj.details.reviewStatusId = status;}
		}

		function submitReviewStatus(status) {
			var statusMessage = _.findWhere(lcapLatestLookup.LCAP_REVIEW_STATUS, { statusId: status });

			if (confirm('Are you sure you\'d like to submit this section with a status of ' + statusMessage.status.toLowerCase() + '?')) {
				setReviewStatusId(status);

				if ($ctrl.formLookup !== undefined && $ctrl.formLookup.api !== false) {
					mainService[$ctrl.formLookup.api]($ctrl.formObj, false, $ctrl.user.userId, $ctrl.user.title)
						.then(function () {
							finishUp({ next: true });
						});
				} else {
					$scope.$parent.vm.closeModal($ctrl.formObj.details.status);
					finishUp({ next: false });
				}
			}
		}

		function finishUp(options) {
			validationFactory.calculateBadges($ctrl.eTemplate);

			if (options.next === true) {
				$timeout(function () {
					var route = $ctrl.formsList[$ctrl.formLookupIndex + 1].formName;
					var reviewedSubmit = route === 'submit' && $rootScope.validity.reviewApproved + $rootScope.validity.reviewUnapproved === $ctrl.totalForms && $stateParams.review === true;

					// only move if next form is not submit or if all forms are valid and next step is submit
					if (route != 'submit' || reviewedSubmit === true)
						{$state.go('LCAP4.' + route);}
				});
			}
		}
	}
})();
