/* Help configure the state-base ui.router */
(function () {
	'use strict';

	angular
		.module('app')
		.provider('routerHelper', routerHelperProvider);

	/* @ngInject */
	function routerHelperProvider($locationProvider, $stateProvider, $urlRouterProvider) {
		/* jshint validthis:true */
		var config = {
			docTitle: 'California Department of Education |',
			resolveAlways: {},
		};

		$locationProvider.html5Mode(true);

		this.configure = function (cfg) {
			angular.extend(config, cfg);
		};

		this.$get = RouterHelper;

		/* @ngInject */
		function RouterHelper($location, $rootScope, $state, authService) {
			var handlingStateChangeError = false;
			var hasOtherwise = false;
			var stateCounts = {
				errors: 0,
				changes: 0,
			};

			var service = {
				configureStates: configureStates,
				getStates: getStates,
				stateCounts: stateCounts,
				setTitle: setTitle,
			};

			init();

			return service;

			function configureStates(states, otherwisePath) {
				states.forEach(function (state) {
					state.config.controllerAs = state.config.controllerAs || 'vm';
					state.config.resolve =
						angular.extend(state.config.resolve || {}, config.resolveAlways);
					state.config = angular.extend({ allowAnonymous: false }, state.config);

					$stateProvider.state(state.state, state.config);
				});
				if (otherwisePath && !hasOtherwise) {
					hasOtherwise = true;
					$urlRouterProvider.otherwise(otherwisePath);
				}
			}


			function handleStatechange() {
				$rootScope.$on('$stateChangeStart',
					function (event, toState) {
						$rootScope.loading = true;

						if (toState.name !== 'reports' && !authService.authentication() && !toState.allowAnonymous) {
							event.preventDefault();
							$state.go('login', {
								returnTo: toState.url,
							});
						}

					});
			}

			function handleRoutingErrors() {
				// Route cancellation:
				// On routing error, go to the dashboard.
				// Provide an exit clause if it tries to do it twice.
				$rootScope.$on('$stateChangeError',
					function (event, toState) {
						$rootScope.loading = false;

						if (handlingStateChangeError || toState.name == 'reports') {
							return;
						}
						stateCounts.errors++;
						handlingStateChangeError = true;
						$location.path('/');
						event.preventDefault();
					}
				);
			}

			function init() {
				handleRoutingErrors();
				handleStatechange();
				updateDocTitle();
			}

			function getStates() {
				return $state.get();
			}

			function updateDocTitle() {
				$rootScope.$on('$stateChangeSuccess',
					function (_event, toState, _toParams, fromState, fromParams) {
						stateCounts.changes++;
						handlingStateChangeError = false;
						setTitle(toState.title);

						$rootScope.loading = false;
						$rootScope.previousState = fromState.name;
						$rootScope.previousStateParams = fromParams;
						$rootScope.previousTitle = fromState.title;
						$rootScope.currentState = toState;
					}
				);
			}

			function setTitle(title) {
				$rootScope.title = config.docTitle + ' ' + (title || '');
				$rootScope.headerTitle = (title || '');
			}

		}
	}
}());
