(function () {
	'use strict';

	angular
		.module('dashboard')
		.controller('CoordinatorFormCtrl', coordinatorFormCtrl);

	/* @ngInject */
	function coordinatorFormCtrl($scope, $timeout, dashboardService, $state, schools, validationFactory,user) {
		var vm = this;
		vm.$onInit = activate;
		vm.addApplicant = addApplicant;
		vm.schools = schools;
		vm.applicant = {};
		vm.onSelect = onSelect;

		function onSelect(item, model, label, event, validity) {
			var $el = $('#leaSchool');
			validationFactory.setGlyph($el, validity);
		}

		function activate() {
			$timeout(function () {
				vm.form = $scope.applicantForm;
				vm.form.customValidator = addApplicant;
			});
		}

		function addApplicant() {
			if (vm.form.$valid) {
				if (typeof vm.school != 'object') {
					toastr.error('Please select an item from the LEA dropdown list.');
					onSelect(null, null, null, null, false); // manually make LEA false
				} else {
					vm.applicant.userType = 'Coordinator';
					vm.applicant.school = vm.school.district;
					vm.applicant.cdsCode = vm.school.CDSCode;
					vm.applicant.charter = vm.school.Charter;

					dashboardService.addApplicant(vm.applicant, user.userId, user.userType)
						.then(function () {
							toastr.success('Coordinator has been added!');
							$state.go('dashboard');
						}).catch(function (e) {
							if (e.data.type === 'deleted') {
								if (confirm(e.data.message + ' Would you like to reactivate it?')) {
									var t = setTimeout(toastr.info.bind(toastr, 'Reactivating account...'), 200);
									dashboardService.reactivateApplicant(e.data.value, user.userId, user.userType).then(function () {
										clearTimeout(t);
										toastr.success('Reactivated.');
										$state.go('dashboard');
									});
								}
							} else {
								toastr.error(e.data.message);
							}
						});
				}
			} else if (!vm.applicant.school) {
				onSelect(null, null, null, null, false); // manually make LEA false
			}
		}
	}
})();
