(function() {
    'use strict';

    angular
        .module('app.lcap')
        .component('printUnduplicatedPupils', {
            templateUrl: 'app/LCAP/unduplicatedPupils/print/printUnduplicatedPupils.template.html',
            controller: PrintUnduplicatedPupils,
            bindings: {
                eTemplate: '<',
                years: '<',
            },
        });

    /* @ngInject */
    function PrintUnduplicatedPupils() {
        var $ctrl = this;
        
        $ctrl.pupil = $ctrl.eTemplate.unduplicatedPupils;
        $ctrl.currentYear = _.findWhere($ctrl.years, {isCurrentYear: true});
    }
})();