(function () {
	'use strict';

	angular.module('dashboard').component('lcapForms', {
		templateUrl: 'app/dashboard/lcapForms/lcapForms.template.html',
		controller: LCAPFormsCtrl,
		bindings: {
			user: '<',
			forms: '<',
			lcapYears: '<',
			leaAccess: '<',
			dashboardYear: '<',
		},
	});

	/* @ngInject */
	function LCAPFormsCtrl(
		$state,
		$uibModal,
		dashboardService,
		mainService,
		previewerService,
		lookup
	) {
		var $ctrl = this;

		$ctrl.$onInit = activate;
		function activate() {
			$ctrl.newLCAP = newLCAP;
			$ctrl.viewPrintForm = viewPrintForm;
			$ctrl.selectPrintReportYear = selectPrintReportYear;
			$ctrl.currentYear = _.findWhere($ctrl.lcapYears, { isCurrentYear: true });
			$ctrl.showNewLcapBtn = showNewLcapBtn;
			$ctrl.openCaDashboard = openCaDashboard;
			$ctrl.filterSearch = filterSearch;
			$ctrl.sort = sort;
			$ctrl.lookup = lookup;
			$ctrl.adjustStatus = adjustStatus;
			$ctrl.currentLcapCycle = 2;  // Will need to update this cycle to be dynamic.
			
			setCurrentYearStatus();
			setLeaAccess();
			showNewLcapBtn();
			setPagination();
			getLCAPYearSet();
			
			$ctrl.numActiveForms = $ctrl.forms.filter(function (form) {
				return form.formId != 0 && form.currentYearStatusId != 1;
			}).length;
		}

		function adjustStatus(form) {
			$uibModal
				.open({
					animation: true,
					templateUrl:
						'app/dashboard/lcapForms/lcapStatusModal/lcapStatus.template.html',
					controller: 'lcapStatusModalController',
					controllerAs: 'vm',
					size: 'lg',
					backdrop: 'static',
					keyboard: false,
					resolve: {
						form: function () {
							return form;
						},
					},
				})
				.result.then(function (rsp) {
					var formIndex = _.findIndex($ctrl.forms, { formId: rsp.formId });
					$ctrl.forms[formIndex] = rsp;
					setCurrentYearStatus();
				});
		}

		function selectPrintReportYear(type, form) {
			previewerService.getPrintPreviews(form.formId).then(function (rsp) {
				$ctrl.modalInstance = $uibModal.open({
					animation: true,
					templateUrl: 'app/dashboard/lcapForms/lcapPrintModal/lcapPrint.template.html',
					controller: 'lcapPrintModalController',
					controllerAs: 'vm',
					size: 'lg',
					backdrop: 'static',
					keyboard: false,
					resolve: {
						user: function () { return $ctrl.user; },
						lcapYears: function () {
							return dashboardService.getSchoolYears($ctrl.user.lcapCycleId)
								.then(function (rsp) {
									return rsp;
								});
						},
						currentYear: function () { return $ctrl.currentYear; },
						form: function () { return form; },
						type: function () { return type; },
						printPreviews: function () { return rsp; },
					},
				}).result.then(function () {
					reloadFormsList();
				});
			});
		}

		function viewPrintForm(formId) {
			previewerService.getPreviewerId(formId).then(function (rsp) {
				$state.go('reports', { guid: rsp.PreviewerId });
			});
		}

		function getLCAPYearSet() {
			$ctrl.forms.forEach(function (item) {
				// Unstarted
				if (
					item.yearOneStatus === 0 &&
					item.yearTwoStatus === 0 &&
					item.yearThreeStatus === 0
				) {
					item.yearSet = (item.lcapCycleId == 1) ? '2017-20' : '2021-24';
				}
				// 3 Year standard LCAP
				else if (
					item.yearOneStatus != 1 &&
					item.yearTwoStatus != 1 &&
					item.yearThreeStatus != 1
				) {
					item.yearSet = (item.lcapCycleId == 1) ? '2017-20' : '2021-24';
				}
				// not a 3 year LCAP
				else {
					var activeYears = [];
					var keys = [
						{ cycleId: 1, key: 'yearOneStatus', startYear: '2017', endYear: '-18' },
						{ cycleId: 1, key: 'yearTwoStatus', startYear: '2018', endYear: '-19' },
						{ cycleId: 1, key: 'yearThreeStatus', startYear: '2019', endYear: '-20' },
						{ cycleId: 2, key: 'yearOneStatus', startYear: '2021', endYear: '-22' },
						{ cycleId: 2, key: 'yearTwoStatus', startYear: '2022', endYear: '-23' },
						{ cycleId: 2, key: 'yearThreeStatus', startYear: '2023', endYear: '-24' },
					];

					keys.forEach(function (key) {
						if (item[key.key] != 1 && item.lcapCycleId == key.cycleId) {activeYears.push(key);}
					});

					item.yearSet =
						activeYears.length >= 2
							? (item.yearSet =
								activeYears[0].startYear + activeYears[1].endYear)
							: (item.yearSet =
								activeYears[0].startYear + activeYears[0].endYear);
				}
			});
		}

		function sort(prop) {
			if ($ctrl.sortedBy != prop && prop != null) {
				$ctrl.formsCopy = _.sortBy($ctrl.formsCopy, prop);
				$ctrl.forms = $ctrl.formsCopy.slice(0, $ctrl.limit);
				$ctrl.sortedBy = prop;
				$ctrl.reverseSort = false;
				pageChanged();
			} else if ($ctrl.sortedBy === prop && prop != null) {
				$ctrl.formsCopy.reverse();
				$ctrl.forms = $ctrl.formsCopy.slice(0, $ctrl.limit);
				$ctrl.reverseSort = !$ctrl.reverseSort;
				pageChanged();
			}

			if ($ctrl.filter) {filterSearch();}
		}

		function filterSearch() {
			var search = $ctrl.filter.toLowerCase();
			$ctrl.forms = $ctrl.formsCopy.filter(function (obj) {
				if (obj.leaName)
					{return search ? ~obj.leaName.toLowerCase().indexOf(search) : true;}
			});

			if ($ctrl.forms.length > $ctrl.limit) {pageChanged();}
		}

		function setPagination() {
			$ctrl.currentPage = 1;
			$ctrl.limit = 30;
			$ctrl.formsCopy = angular.copy($ctrl.forms);
			$ctrl.forms = $ctrl.formsCopy.slice(0, $ctrl.limit);
			$ctrl.total = $ctrl.formsCopy.length;
			$ctrl.pageChanged = pageChanged;
		}

		function pageChanged() {
			var start = ($ctrl.currentPage - 1) * $ctrl.limit;
			var end =
				start + $ctrl.limit > $ctrl.total ? $ctrl.total : start + $ctrl.limit;
			$ctrl.forms = $ctrl.formsCopy.slice(start, end);
		}

		function setLeaAccess() {
			// Get list of LEAs a user has access to

			// Loop through forms and see if lea has existing lcaps in progress preventing duplicate draft stages.
			_.each($ctrl.leaAccess, function (item) {
				var forms = $ctrl.forms.filter(function (form) {
					return (
						form.cdsCode === item.cdsCode &&
						form.lcapCycleId === item.cycleId &&
						form.currentYearStatusId !== 1 &&
						form.yearThreeStatus !== 4
					);
				});

				var currentCycleForm = $ctrl.forms.some(function (form) {
					return form.lcapCycleId == $ctrl.currentLcapCycle &&
						form.cdsCode == item.cdsCode;
				});

				item.started = currentCycleForm || forms.length >= 1;
			});
		}

		function reloadFormsList() {
			if ($ctrl.user.userType != 'Admin') {
				dashboardService
					.getAssignedLCAPs($ctrl.user.userId)
					.then(function (rsp) {
						$ctrl.forms = rsp;
						activate();
						$ctrl.sort();
					});
			} else if ($ctrl.user.userType === 'Admin') {
				dashboardService.getAllForms().then(function (rsp) {
					$ctrl.forms = rsp;
					activate();
					$ctrl.sort();
				});
			}
		}

		function newLCAP(form) {
			if ($ctrl.user.userType === 'Coordinator' && !form) {
				mainService
					.addForm({ cdsCode: $ctrl.user.cdsCode, leaName: $ctrl.user.school}, false, $ctrl.user.userId, $ctrl.user.userType)
					.then(function () {
						reloadFormsList();
					});
			} else {
				$ctrl.modalInstance = $uibModal.open({
					animation: true,
					templateUrl: 'app/dashboard/lcapForms/lcapYearModal/lcapYear.template.html',
					controller: 'lcapYearModalController',
					controllerAs: 'vm',
					size: 'lg',
					backdrop: 'static',
					keyboard: false,
					resolve: {
						user: function () { return $ctrl.user; },
						lcapYears: function () { return $ctrl.lcapYears; },
						lcap: function () { return form; },
						currentYear: function () { return $ctrl.currentYear; },
						leaAccess: function () { return $ctrl.leaAccess; },
					},
				}).result.then(function (rsp) {
					form = rsp;
					reloadFormsList();
				});
			}
		}

		function setCurrentYearStatus() {
			// Sets a new field on the form for easy access to current year status
			$ctrl.forms.forEach(function (item) {
				var currentYearStatus;
				item.currentYear = $ctrl.currentYear;

				if (item.lcapCycleId == 2) {
					if (item.currentYear.yearNumber === 1) {
						currentYearStatus = item.yearOneStatus; // if its year 1
					} else if (item.currentYear.yearNumber === 2) {
						currentYearStatus = item.yearTwoStatus; // if its year 2
					} else if (item.currentYear.yearNumber === 3) {
						currentYearStatus = item.yearThreeStatus; // if its year 3
					}
				} else {
					if (item.schoolYearId === 1) {
						currentYearStatus = item.yearOneStatus; // if its year 1
					} else if (item.schoolYearId === 2) {
						currentYearStatus = item.yearTwoStatus; // if its year 2
					} else if (item.schoolYearId === 3) {
						currentYearStatus = item.yearThreeStatus; // if its year 3
					}
				}

				item.currentYearStatusId = currentYearStatus;
				item.currentYearStatus = _.findWhere(lookup.LCAP_STATUS, {
					statusId: currentYearStatus,
				}); // Set current Status
			});
		}

		function showNewLcapBtn() {
			// Control when to show the "Create New LCAP" button
			// Forms that have been initialized
			var startedForms = $ctrl.forms.filter(function (item) {
				return (
					item.lcapCycleId === $ctrl.currentYear.lcapCycleId &&
					item.currentYearStatusId !== 1
				);
			});
			var unstartedForms = [];

			_.each($ctrl.leaAccess, function (lea) {

				var matchingLEA = _.findWhere(startedForms, { cdsCode: lea.cdsCode });

				if (!matchingLEA) {unstartedForms.push(lea);}
			});

			if ($ctrl.user.userType === 'Reviewer') {$ctrl.showNewBtn = false;}
			// Hide if Reviewer
			else if ($ctrl.forms.length === 0 || startedForms.length === 0)
				{$ctrl.showNewBtn = true;}
			// If there are 0 existing forms or 0 started forms
			else if ($ctrl.user.userType === 'Submitter' && unstartedForms.length > 0)
				{$ctrl.showNewBtn = true;}
			else {$ctrl.showNewBtn = false;}

			if ($ctrl.forms.length === 0 && $ctrl.user.userType != 'Coordinator')
				{$ctrl.zeroFormsMessage = true;}
			else if ($ctrl.user.userType === 'Reviewer' && startedForms.length === 0)
				{$ctrl.zeroFormsMessage = true;}
			else {$ctrl.zeroFormsMessage = false;}
		}

		function openCaDashboard(form) {
			var dashboardUrl =
				'https://caschooldashboard.org/reports/' +
				form.cdsCode +
				'/' +
				$ctrl.dashboardYear;

			window.open(dashboardUrl, '_blank');
		}
	}
})();
