(function () {
	'use strict';

	angular
		.module('app')
		.controller('ExpenditureTable3Ctrl', ExpenditureTable3Ctrl);

	/* @ngInject */
	function ExpenditureTable3Ctrl($http, $stateParams, appSettings) {
		var vm = this;
		vm.exportContributingExpenditures = exportContributingExpenditures;
		vm.exportDataTable = exportDataTable;
		vm.exportTotalExpenditures = exportTotalExpenditures;

		function exportContributingExpenditures() {
			$http({
				url:
					appSettings.serverPath +
					'api/Report/GetContributingExpendituresTableToCsv/' +
					$stateParams.formId,
				method: 'GET',
				responseType: 'blob',
				headers: {
					'Content-type': 'application/json',
					Accept: 'text/csv',
				},
			}).then(function (rsp) {
				saveAs(rsp.data, rsp.headers('content-disposition').replace(/=|;/g, '').split('filename')[1].trim());
			});
		}

		function exportDataTable() {
			$http({
				url:
					appSettings.serverPath +
					'api/Report/GetDataEntryTableToCsv/' +
					$stateParams.formId,
				method: 'GET',
				responseType: 'blob',
				headers: {
					'Content-type': 'application/json',
					Accept: 'text/csv',
				},
			}).then(function (rsp) {
				saveAs(rsp.data, rsp.headers('content-disposition').replace(/=|;/g, '').split('filename')[1].trim());
			});
		}
		function exportTotalExpenditures() {
			$http({
				url:
					appSettings.serverPath +
					'api/Report/GetTotalExpendituresToCsv/' +
					$stateParams.formId,
				method: 'GET',
				responseType: 'blob',
				headers: {
					'Content-type': 'application/json',
					Accept: 'text/csv',
				},
			}).then(function (rsp) {
				saveAs(rsp.data, rsp.headers('content-disposition').replace(/=|;/g, '').split('filename')[1].trim());
			});
		}
	}
})();
