(function () {
	'use strict';

	angular
		.module('dashboard')
		.component('changePassword', {
			templateUrl: 'app/dashboard/lcapUsers/changePassword/changePassword.template.html',
			controller: ChangePassword,
			bindings: {
				user: '<',
			},
		});

	/* @ngInject */
	function ChangePassword($scope, $timeout, validationFactory, dashboardService) {
		var $ctrl = this;

		$ctrl.$onInit = activate;

		function activate() {
			$ctrl.password = {
				new: '',
				confirm: '',
			};
			$ctrl.showError = false;
			$ctrl.checkMatchup = checkMatchup;

			$timeout(function () {
				$ctrl.form = $scope.changePassword;
				$ctrl.form.customValidator = validate;
			});
		}

		function validate() {
			if ($ctrl.password.new === $ctrl.password.confirm && $ctrl.password.new.length > 0) {
				$ctrl.user.password = $ctrl.password.confirm;

				dashboardService.updatePassword($ctrl.user)
					.then(function () {
						toastr.success('Your password has been updated.');
					}).catch(function (e) {
						toastr.error(e.data);
					});
			} else {$ctrl.showError = true;}
		}

		function checkMatchup() {
			var equal = $ctrl.password.new === $ctrl.password.confirm;
			var confirmLength = $ctrl.password.confirm.length > 0;

			if (equal === true && confirmLength) {
				validationFactory.setGlyph($('#confirmPassword'), true); // valid input styles
				$ctrl.showError = false;
			} else if (equal === false && confirmLength) {
				validationFactory.setGlyph($('#confirmPassword'), false); // invalid input styles
				$ctrl.showError = true;
			} else {
				validationFactory.setGlyph($('#confirmPassword')); // remove input styles
				$ctrl.showError = false;
			}
		}
	}
})();
