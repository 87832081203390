(function () {
	'use strict';

	angular
		.module('app')
		.controller('GeneratePdfCtrl', GeneratePdfCtrl);

	/* @ngInject */
	function GeneratePdfCtrl($timeout, $stateParams, $scope, $rootScope, $window, user, mainService) {
		var vm = this;

		vm.$onInit = activate;
		vm.processing = true;
		vm.generatePdf = generatePdf;
		vm.openPdf = openPdf;
		vm.url = 'print/{formId}/{yearId}/{userType}/{userId}?section';
		vm.printUrls = [];

		$scope.$watch(function () {
			return $rootScope.fileUrl;
		}, function () {
			vm.fileUrl = $rootScope.fileUrl;
		}, true);

		/////////////////////

		function activate() {
			// set force prod to true to test production print urls
			$timeout(setPrintUrl({ forceProd: false }));
		}

		function setPrintUrl(options) {
			var url = window.location.href;
			var local = 'http://localhost:3000/';
			var dev = 'https://etemplate.sjcoe.org/';
			var prod = 'https://lcaptemplate.org/';
			var baseUrl = prod; // default

			if (url.indexOf(local) != -1 && !options.forceProd) {baseUrl = local;}
			else if (url.indexOf(dev) != 1 && !options.forceProd) {baseUrl = dev;}

			vm.url = baseUrl + vm.url.replace('{formId}', $stateParams.formId)
				.replace('{yearId}', $stateParams.schoolYearId)
				.replace('{userType}', user.userType)
				.replace('{userId}', user.userId);

			setPrintSections();
		}

		function setPrintSections() {
			if ($stateParams.section === undefined) { // Print Everything
				var urlOptions = ['portrait', 'landscape'];

				urlOptions.forEach(function (item) {
					var url = vm.url.replace('?section', '?section=' + item);
					vm.printUrls.push(url);
				});

				$timeout(generatePdf(vm.printUrls));
			} else { // Print Individual Section
				vm.printUrls.push(vm.url.replace('?section', '?section=' + $stateParams.section));
				$timeout(generatePdf(vm.printUrls));
			}
		}

		function generatePdf(urls) {
			mainService.generatePdf(urls)
				.then(function (rsp) {
					vm.processing = false;
					vm.file = rsp;
					openPdf();
				});
		}

		function openPdf() {
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(vm.file, 'LCAP.pdf');
			}
			else {
				if (!vm.fileUrl) {vm.fileUrl = URL.createObjectURL(vm.file);}

				$window.open(vm.fileUrl, '_blank');
			}
		}
	}
})();
