(function() {
    'use strict';

    angular
        .module('app.lcap')
        .component('printButton', {
            templateUrl: 'app/LCAP/shared/printSectionBtn/printSectionBtn.template.html',
            controller: printButtonComponent,
            bindings: {
                section: '<',
                eTemplate: '<',
            },
        });

    /* @ngInject */
    function printButtonComponent() {
        var $ctrl = this;
        $ctrl.formId = $ctrl.eTemplate.lcapUser.formId;
        $ctrl.schoolYearId = $ctrl.eTemplate.lcapUser.schoolYearId;
    }
})();