(function () {
	'use strict';

	angular
		.module('app')
		.controller('ExpenditureTable4Ctrl', ExpenditureTable4Ctrl);

	/* @ngInject */
	function ExpenditureTable4Ctrl($http, $stateParams, appSettings, preview) {
		var vm = this;
		vm.inReview = $stateParams.review;
		vm.exportContributingExpenditures = exportContributingExpenditures;
		vm.exportDataTable = exportDataTable;
		vm.exportTotalExpenditures = exportTotalExpenditures;
		vm.exportAnnualUpdateTable = exportAnnualUpdateTable;
		vm.exportAnnualUpdateContributingActionsTable = exportAnnualUpdateContributingActionsTable;
		vm.exportLCFFCarryoverTable = exportLCFFCarryoverTable;



		function exportContributingExpenditures() {
			$http({
				url:
					appSettings.serverPath +
					'api/Report/GetContributingExpendituresTableToCsv/' +
					preview.PreviewerId,
				method: 'GET',
				responseType: 'blob',
				headers: {
					'Content-type': 'application/json',
					Accept: 'text/csv',
				},
			}).then(function (rsp) {
				saveAs(rsp.data, rsp.headers('content-disposition').replace(/=|;/g, '').split('filename')[1].trim());
			});
		}

		function exportDataTable() {
			$http({
				url:
					appSettings.serverPath +
					'api/Report/GetDataEntryTableToCsv/' +
					preview.PreviewerId,
				method: 'GET',
				responseType: 'blob',
				headers: {
					'Content-type': 'application/json',
					Accept: 'text/csv',
				},
			}).then(function (rsp) {
				saveAs(rsp.data, rsp.headers('content-disposition').replace(/=|;/g, '').split('filename')[1].trim());
			});
		}

		function exportTotalExpenditures() {
			$http({
				url:
					appSettings.serverPath +
					'api/Report/GetTotalExpendituresToCsv/' +
					preview.PreviewerId,
				method: 'GET',
				responseType: 'blob',
				headers: {
					'Content-type': 'application/json',
					Accept: 'text/csv',
				},
			}).then(function (rsp) {
				saveAs(rsp.data, rsp.headers('content-disposition').replace(/=|;/g, '').split('filename')[1].trim());
			});
		}

		function exportAnnualUpdateTable() {
			$http({
				url:
					appSettings.serverPath +
					'api/Report/GetAnnualUpdateTableToCsv/' +
					preview.PreviewerId,
				method: 'GET',
				responseType: 'blob',
				headers: {
					'Content-type': 'application/json',
					Accept: 'text/csv',
				},
			}).then(function (rsp) {
				saveAs(rsp.data, rsp.headers('content-disposition').replace(/=|;/g, '').split('filename')[1].trim());
			});
		}

		function exportAnnualUpdateContributingActionsTable() {
			$http({
				url:
					appSettings.serverPath +
					'api/Report/GetAnnualUpdateContributingActionsTableToCsv/' +
					preview.PreviewerId,
				method: 'GET',
				responseType: 'blob',
				headers: {
					'Content-type': 'application/json',
					Accept: 'text/csv',
				},
			}).then(function (rsp) {
				saveAs(rsp.data, rsp.headers('content-disposition').replace(/=|;/g, '').split('filename')[1].trim());
			});
		}

		function exportLCFFCarryoverTable() {
			$http({
				url:
					appSettings.serverPath +
					'api/Report/GetLCFFCarryoverTableToCsv/' +
					preview.PreviewerId,
				method: 'GET',
				responseType: 'blob',
				headers: {
					'Content-type': 'application/json',
					Accept: 'text/csv',
				},
			}).then(function (rsp) {
				saveAs(rsp.data, rsp.headers('content-disposition').replace(/=|;/g, '').split('filename')[1].trim());
			});
		}
	}
})();
