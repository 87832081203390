(function () {
	'use strict';

	angular
		.module('app')
		.controller('AnnualUpdatePage3Ctrl', AnnualUpdatePage3Ctrl);

	/* @ngInject */
	function AnnualUpdatePage3Ctrl(
		$rootScope,
		$scope,
		$stateParams,
		$timeout,
		eTemplate,
		mainService,
		$uibModal,
		validationFactory,
		lcapYears,
		authService,
		user,
		dashboardService
	) {
		var vm = this;

		vm.$onInit = activate;
		vm.eTemplate = eTemplate;
		vm.deleteAUGoal = deleteAUGoal;
		vm.openAnnualUpdateModal = openAnnualUpdateModal;
		vm.validity = $rootScope.validity;
		vm.inReview = $stateParams.review;
		vm.lcapYears = lcapYears;

		$scope.$watch(
			function () {
				// watch validity for goal values
				return $rootScope.validity;
			},
			function () {
				vm.validity = $rootScope.validity;
			},
			true
		);


		function activate() {
			$timeout(function () {
				vm.form = $scope.annualUpdate;
				vm.form.customValidator = validate;
				vm.form.continueValidation = true; // return to continue default factory validation

				setPreviousYear();
				validationFactory.calculateBadges(vm.eTemplate); // Calc on activate
				vm.wasRolledOver =
					vm.eTemplate.lcapUser.schoolYearId === 3 &&
						vm.eTemplate.lcapUser.yearTwoStatus === 4
						? true
						: false;
			});
		}

		function setPreviousYear() {
			if (vm.eTemplate.lcapUser.yearId === 1) {
				// Get previous cycle if its the first year of this new cycle
				dashboardService
					.getSchoolYears(vm.eTemplate.lcapUser.lcapCycleId - 1)
					.then(function (rsp) {
						vm.previousYear = _.findWhere(rsp, { yearNumber: 3 });
					});
			} else {
				var hasPreviousYear = _.contains(vm.lcapYears, {
					yearNumber: vm.eTemplate.lcapUser.yearId - 1,
				});

				if (hasPreviousYear) {
					vm.previousYear = _.findWhere(vm.lcapYears, {
						yearNumber: vm.eTemplate.lcapUser.yearId - 1,
					}); // If new goal, set to previous year Reviewed
				} else {
					vm.previousYear = _.findWhere(vm.lcapYears, {
						schoolYearId: vm.eTemplate.lcapUser.yearId,
					}); // If new goal, set to previous year Reviewed
					if (!vm.previousYear) {
						vm.previousYear = '2020-21';
					}
				}
			}
		}
		function openAnnualUpdateModal(form, goalToEdit, index) {
			$uibModal
				.open({
					controller: 'AnnualUpdate3Ctrl',
					controllerAs: 'vm',
					size: 'lg',
					templateUrl:
						'app/LCAP3/annualUpdate/annualUpdateModal/annualUpdateModal.html',
					backdrop: 'static',
					keyboard: false,
					resolve: {
						editableGoal: function () {
							return goalToEdit;
						},
						goalIndex: function () {
							return index;
						},
						eTemplate: function () {
							return eTemplate;
						},
						user: function () {
							return user;
						},
					},
				})
				.result.then(
					function (goal) {
						// if this is the first goal
						if (!vm.eTemplate.annualUpdate.goals)
							{vm.eTemplate.annualUpdate.goals = [];}

						// Get existing goal index then update existing goal
						var goalIndex = _.findIndex(vm.eTemplate.annualUpdate.goals, {
							goalId: goal.goalId,
						}); // exising goal index

						if (goalIndex > -1) {
							// Updating existing goal
							vm.eTemplate.annualUpdate.goals[goalIndex] = goal;
						} else {
							// Brand New Goal
							goal.details.yearReviewed = vm.previousYear.year;
							goal.formId = $stateParams.formId; // Set formId
							goal.details.formId = $stateParams.formId; // both formId's need to be set
							vm.eTemplate.annualUpdate.goals.push(goal);
						}

						goal.schoolYearId = $stateParams.schoolYearId; // needs to be dynamic

						mainService
							.addAnnualUpdateGoals(goal)
							.then(function (rsp) {
								goal.goalId = rsp.goalId;
								goal.details.goalId = rsp.goalId;
								goal.details.id = rsp.id;

								goal.actionsServices.forEach(function (item, i) {
									// pull and set actionIds
									item.actionId = rsp.serviceIds[i];
								});

								goal.outcomes.forEach(function (item, i) {
									// pull and set actionIds
									item.id = rsp.outcomeIds[i];
								});

								toastr.success('Annual Update successfully saved!');
							})
							.catch(function () {
								toastr.error('Error saving annual update, please try again.');
								openAnnualUpdateModal(vm.form, goal, goalIndex);
								$rootScope.modalOpen = true;
							});

						validationFactory.calculateBadges(vm.eTemplate); // Calc Badges
						authService.resetAutoLogout({ cancelPrevTimeout: true }); // Reset auto logout
						$rootScope.form = form; // Set rootscope form on close
					},
					function () {
						validationFactory.calculateBadges(vm.eTemplate); // Calc Badges
						authService.resetAutoLogout({ cancelPrevTimeout: true }); // Reset auto logout
						$rootScope.form = form; // Set rootscope form on dismiss
					}
				);
		}

		function deleteAUGoal(index, form, goal) {
			if (confirm(
				'Are you sure you want to delete Goal #' +
				(index + 1) +
				' from the Annual Update\'s list of goals?'
			)) {
				mainService
					.deleteAUGoal(goal.goalId)
					.then(function () {
						vm.eTemplate.annualUpdate.goals.splice(index, 1);
						validationFactory.calculateBadges(vm.eTemplate);
					});
			}
		}

		function validate() {
			var calcs = vm.validity.goals.annualUpdate;

			if (calcs.goalsDraft >= 1 || calcs.goalsComplete === 0)
				{vm.form.$setValidity('required', false);}
			// Invalid
			else {vm.form.$setValidity('required', true);} // Valid
		}
	}
})();
